import {
  atom,
  selector
} from 'recoil';

import * as vodaLend from '../api/vodaLend';

const fetchUserStatus = async () => await vodaLend.isUserVerified();

const userVerificationDefault = selector({
  key: 'userVerificationDefault',
  get: async () => await fetchUserStatus()
});

const userVerificationState = atom({
  key: 'userVerificationState',
  default: userVerificationDefault
});

export const isUserVerifiedQuery = selector({
  key: 'isUserVerifiedQuery',
  get: async ({ get }) => {
    const data = await get(userVerificationState);
    return data;
  },
  set: ({ set }, inputData) => {
    set(userVerificationState, inputData);
  }
});
