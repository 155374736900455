export const RepayMonths = [
  {
    'label': '3 months',
    'value': '3'
  },
  {
    'label': '6 months',
    'value': '6'
  },
  {
    'label': '9 months',
    'value': '9'
  },
  {
    'label': '12 months',
    'value': '12'
  }
];