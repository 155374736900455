import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  AddIcon,
  ArrowDown,
  CloseIcon,
  DeleteIcon
} from '../../assets';
import { AccordionStyle, DirectorDetails, FormFieldWrapper } from '../../constants';
import { useIsMobile, useShowToast } from '../../recoil';
import { useTealium } from '../../tealium/useTealium';
import { Accordion } from '../Accordion/Accordion';
import { PersonalDetailsComponent } from '../PersonalDetailsComponent/PersonalDetailsComponent';
import { ConfirmModal } from '../PopupModal/PopupModal';
import styles from './DirectorDetailsComponent.module.css';

export const DirectorDetailsComponent = ({
  heading,
  subHeading,
  journeyType
}) => {

  const { values, setFieldValue } = useFormikContext();

  const [activeAccordion, setActiveAccordion] = useState('');
  const [isOpenDirectorDelete, setOpenDirectorDelete] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState();
  const isMobile = useRecoilValue(useIsMobile);
  const { track, events } = useTealium();

  const isSoleTrader = values.businessType === 'Sole Trader';

  const addNewDirectorDiv = (directorDetails, setFieldValue) => {
    track(events.businessDirectors.addDirector());
    setFieldValue('directors', [
      ...directorDetails,
      { ...DirectorDetails }
    ]);
  };

  const removeDirectorDiv = (id, directorDetails, setFieldValue) => {
    if (id > -1 && directorDetails?.length > 0) {
      const newArray = directorDetails.filter((_d, index) => index !== id);
      setFieldValue('directors', newArray);
    }
  };

  const openDeletePopup = (index, values, fieldValue) => {
    setDeleteInfo({ index, values, fieldValue });
    setOpenDirectorDelete(true);
  };

  const showToast = useShowToast();

  const deleteDirector = () => {
    removeDirectorDiv(deleteInfo?.index, deleteInfo?.values, deleteInfo?.fieldValue);
    setOpenDirectorDelete(false);
    showToast('Director deleted');
  };

  return (
    <div className={styles.container}>

      <div className={isMobile ? styles.mobileDirectorForm : styles.directorForm}>
        <div className={isMobile ? styles.mobileTitleContainer : styles.titleContainer}>
          {heading && (
            <div className={isMobile ? styles.mobileTitleHeading : styles.titleHeading}>
              {heading}
            </div>
          )}
          {subHeading && (
            <div className={isMobile ? styles.mobileTitleSubHeading : styles.titleSubHeading}>
              {subHeading}
            </div>
          )}
        </div>

        <div style={FormFieldWrapper}>
          <div className={styles.childAccordionContainer}>
            {values.directors.map((item, index) => (
              <Accordion
                key={index}
                isOpen={activeAccordion === `directorDetails.${index}`}
                headerChildren={
                  <>
                    <div className={styles.accordionHeaderLeft}>
                      <div style={AccordionStyle.accordionTitle}>
                        {`${item.title}` ? `${index + 1}. ${item.title?.label} ${item?.firstName} ${item?.surname}` : `${index + 1}. Director Details`}
                      </div>
                    </div>

                    <div className={styles.accordionImgContainer}>
                      {!isSoleTrader &&
                        <img
                          style={AccordionStyle.deleteImg}
                          src={DeleteIcon} alt='Delete Icon'
                          onClick={() => openDeletePopup(index, values.directors, setFieldValue)}
                          onKeyDown={() => openDeletePopup(index, values.directors, setFieldValue)}
                          role='presentation'
                        />
                      }
                      <img
                        style={{ ...AccordionStyle.ArrowImg, rotate: activeAccordion === `directorDetails.${index}` ? '180deg' : '' }}
                        src={ArrowDown}
                        alt='DropdownOpen'
                        role='presentation'
                        onClick={() => setActiveAccordion(activeAccordion === `directorDetails.${index}` ? '' : `directorDetails.${index}`)}
                        onKeyDown={() => setActiveAccordion(activeAccordion === `directorDetails.${index}` ? '' : `directorDetails.${index}`)}
                      />
                    </div>
                  </>
                }
                containerStyle={{ height: 'auto' }}
              >
                <div style={AccordionStyle.accordionChildren}>
                  <PersonalDetailsComponent
                    namePrefix={`directors.${index}`}
                    showDob={false}
                    showGender={false}
                    showIdentity={false}
                    phoneEmailEditable={true}
                    disableAllFields={isSoleTrader ? true : false}
                  />
                </div>
              </Accordion>
            ))}
          </div>

          {!isSoleTrader && (
            <div className={styles.addMoreDirectorDiv}>
              <div
                className={styles.addMoreDirectorInnerDiv}
                onClick={() => addNewDirectorDiv(values.directors, setFieldValue)}
                onKeyDown={() => addNewDirectorDiv(values.directors, setFieldValue)}
                role='presentation'
              >
                <img src={AddIcon} alt="Add Icon" />
                <span style={{ marginLeft: '11px' }}>Add a director </span>
              </div>
            </div>
          )}
        </div>
      </div>

      {isOpenDirectorDelete && (
        <ConfirmModal
          closeCallback={() => setOpenDirectorDelete(false)}
          icon={CloseIcon}
          title={'Are you sure?'}
          subTitle={`You are about to remove a director. \n You need to click ${journeyType === 'onBoarding' ? 'Next' : 'Save'} to save your changes`}
          acceptLabel={'Delete director'}
          cancelLabel={'Cancel'}
          onAcceptClick={deleteDirector}
        />

      )}

    </div>
  );
};

DirectorDetailsComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  journeyType: PropTypes.string
};
