import React, {
  useEffect
} from 'react';
import {
  Outlet,
  useNavigate
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Header,
  NavBar
} from '../../components';
import { Fonts } from '../../constants';
import {
  applicationStatuses,
  useIsMobile
} from '../../recoil';
import { StaticRoutes } from '../../Router';

const styles = {
  profileMgnt: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: Fonts.Roboto
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  containerLeft: {
    width: '100%',
    maxWidth: 343,
    marginTop: 38,
    height: 'inherit'
  },
  containerRight: {
    flexGrow: 1
  }
};

export const FundingQuote = () => {

  const navigate = useNavigate();
  const isMobile = useRecoilValue(useIsMobile);
  const appStates = useRecoilValue(applicationStatuses);

  useEffect(() => {
    if (!appStates?.isQuoteAvailable) {
      navigate(StaticRoutes.applications);
      return;
    }
  }, [appStates]);

  return (
    <div style={styles.profileMgnt}>
      <Header onBackClick={() => false} />
      <div style={styles.contentSection}>
        {isMobile || <div style={styles.containerLeft}>
          <NavBar />
        </div>}
        <div style={styles.containerRight}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
