import React from 'react';
import { useLoader } from './useLoader';
import { Loader } from './Loader';

export const LoaderController = () => {
  const [, , loader] = useLoader();
  return loader.isOpen && (
    <Loader height={'30%'} />
  );
};
