import React, {
  useEffect,
  useMemo
} from 'react';
import { CircularProgressBar } from '../../components';
import { formatCurrency } from '../../helpers';
import { btaAccountSummary } from '../../recoil';
import { useRecoilValue } from 'recoil';
import { StaticRoutes } from '../../Router';
import styles from './btaAccountSummary.module.css';
import { useNavigate } from 'react-router-dom';


const PERCENT_MULT = 100;

export const BTAAccountSummary = () => {

  const summ = useRecoilValue(btaAccountSummary);

  const navigate = useNavigate();

  useEffect(() => {
    if (!summ) {
      navigate(StaticRoutes.applications);
    }
  }, [summ]);

  const detailFields = useMemo(() => summ && ({
    advanceId: {
      title: 'Advance ID',
      value: `${summ.advanceId}`
    },
    advanceAmount: {
      title: 'Advance Amount',
      value: formatCurrency(summ.advanceAmount, true)
    },
    arrearsAmount: {
      title: 'Arrears',
      value: formatCurrency(summ.arrearsAmount, true),
      style: styles.arrearsAmt
    },
    remainingCapital: {
      title: 'Remaining Capital',
      value: formatCurrency(summ.remainingCapital, true)
    },
    remainingBalance: {
      title: 'Remaining Balance',
      value: formatCurrency(summ.remainingBalance, true)
    }
  }), [summ]);

  // TODO: Add this object to detailFields in future when we get the related field from LLL (eugene)
  // earlySettleAmount: {
  //   title: 'Early Settlement Balance',
  //   value: formatCurrency(summ.earlySettleAmount)
  // }


  return (
    <div className={styles.summaryContainer}>
      {summ &&
        <>
          <div className={styles.summaryProgressBar}>
            <CircularProgressBar
              percentage={PERCENT_MULT * summ.totalRepaid / (summ.totalOutstanding + summ.totalRepaid)}
              heading={formatCurrency(summ.totalRepaid)}
              subHeading={'Total Repayments'}
              isClockWise={false}
              isPercLineRed={true}
            />
            <div className={styles.summaryProgressBarInfo}>
              <div className={styles.progressIndicator}>
                <div className={styles.blueEllipse} />
                <div className={styles.progressLabel}>
                  Total Repaid
                  <div className={styles.progressLabelAmt}>{formatCurrency(summ.totalRepaid, false)}</div>
                </div>
              </div>
              <div className={styles.progressIndicator}>
                <div className={styles.redEllipse} />
                <div className={styles.progressLabel}>
                  Total Outstanding
                  <div className={styles.progressLabelAmt}>{formatCurrency(summ.totalOutstanding, false)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.summarySubContainerInfo}>
            {Object.entries(detailFields).map(([key, field]) => (
              <div key={`detailCont${key}`} className={styles.description}>
                <div key={`detailTitle${key}`} className={styles.descriptionLabel}>{`${field.title}:`}</div>
                <div key={`detailValue${key}`} className={field.style || styles.descriptionValue}>{field.value}</div>
              </div>
            ))}
          </div>
        </>
      }
    </div>
  );
};