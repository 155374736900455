import React from 'react';
import { Logout } from '../../assets';
import {
  Colors,
  Fonts
} from '../../constants';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import { StaticRoutes } from '../../Router';

const styles = {
  logoutContainer: {
    width: 190,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  logoutInnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: 8,
    boxShadow: '0px 3px 50px rgba(0, 0, 0, 0.15)',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.GreyScale1
  },
  logoutSection: {
    width: '100%',
    height: 56,
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  imgLogout: {
    width: 20,
    height: 20,
    marginLeft: 20
  },
  logoutText: {
    marginLeft: 8,
    fontFamily: Fonts.Roboto,
    fontSize: 16,
    color: Colors.GreyScale9
  },
  polygon6: {
    marginRight: 20,
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: `20px solid ${Colors.GreyScale1}`,
    zIndex: 10
  }
};

export const UserOptionsList = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path) =>
    navigate(path, {
      state: {
        returnPath: location.pathname
      }
    });

  return (
    <div style={styles.logoutContainer}>
      <div style={styles.polygon6} />
      <div style={styles.logoutInnerContainer}>
        <div
          style={styles.logoutSection}
          onClick={() => navigateTo(StaticRoutes.logout)}
          onKeyDown={() => navigateTo(StaticRoutes.logout)}
          role='presentation'
        >
          <img src={Logout} alt='logout button' style={styles.imgLogout} />
          <div style={styles.logoutText}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};