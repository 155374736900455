import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { useRecoilValue } from 'recoil';
import { Colors, Fonts } from '../../constants';
import { useIsMobile } from '../../recoil';
import { CloseIcon } from '../../assets';

const styles = {
  modalBg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    zIndex: '100',
    position: 'fixed',
    left: 0,
    top: 0
  },
  cancelModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: '300px',
    background: 'white',
    zIndex: '10',
    borderRadius: '16px',
    boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.04)',
    justifyContent: 'center',
    padding: '30px'
  },
  cancelModalMobile: {
    width: '300px'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeIcon: {
    width: '30px',
    cursor: 'pointer'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0 15px 0'
  },
  title: {
    fontFamily: Fonts.RobotoMedium,
    fontSize: '30px',
    lineHeight: '24px',
    fontStyle: 'normal',
    margin: '0 0 20px 0'
  },
  subTitle: {
    fontFamily: Fonts.Roboto,
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    textAlign: 'center',
    whiteSpace: 'pre-line'

  },
  redHrSmall: {
    width: 30,
    border: `2px solid ${Colors.Primary4}`,
    borderRadius: 20,
    marginBottom: 15
  },
  button: {
    margin: 10,
    fontFamily: Fonts.Roboto,
    fontSize: 16,
    width: '40%'
  },
  buttonMobile: {
    fontSize: 15,
    width: '70%'
  },
  acceptButton: {
    fontFamily: Fonts.RobotoMedium,
    color: Colors.GreyScale13,
    border: `2px solid ${Colors.GreyScale13}`,
    background: 'transparent'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%'
  }

};

export const PopupModal = ({ closeCallback, children }) => (
  <div style={styles.modalBg} onClick={() => closeCallback?.()} onKeyDown={() => closeCallback?.(false)} role='presentation'>
    {children}
  </div>
);

PopupModal.propTypes = {
  closeCallback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const ConfirmModal = ({ closeCallback, title, subTitle, onAcceptClick, acceptLabel, cancelLabel }) => {
  const isMobile = useRecoilValue(useIsMobile);

  return (
    <PopupModal closeCallback={closeCallback}>
      <div style={{ ...styles.cancelModal, ...(isMobile ? styles.cancelModalMobile : {}) }}>
        <div style={styles.iconContainer}>
          <img
            style={styles.closeIcon}
            src={CloseIcon} alt='Delete Icon'
            onClick={() => closeCallback?.()}
            onKeyDown={() => closeCallback?.()}
            role='presentation'
          />
        </div>
        <div style={styles.titleContainer}>
          <div style={styles.title}>{title}</div>
          {subTitle && (<div style={styles.subTitle}>{subTitle}</div>)}
        </div>
        <hr style={styles.redHrSmall} />
        <div style={styles.buttonContainer}>

          { onAcceptClick && (<Button
            style={{ ...styles.button, ...(isMobile && styles.buttonMobile || {}), ...styles.acceptButton }}
            as={'button'}
            onClick={onAcceptClick}
            onKeyDown={onAcceptClick}
          >
            {acceptLabel || 'OK'}
          </Button>)}

          { closeCallback && (<Button
            style={{ ...styles.button, ...(isMobile && styles.buttonMobile || {}) }}
            as={'button'}
            onClick={closeCallback}
            onKeyDown={closeCallback}
          >
            {cancelLabel || 'Cancel'}
          </Button>)}

        </div>
      </div>
    </PopupModal>
  );
};

ConfirmModal.propTypes = {
  closeCallback: PropTypes.func,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  onAcceptClick: PropTypes.func,
  acceptLabel: PropTypes.string,
  cancelLabel: PropTypes.string
};
