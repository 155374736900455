export const myProfile = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: my profile',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  editProfileUiInteraction: (category) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: my profile',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: `vodapay: business advance: opf: edit ${category}`
  }),

  saveProfileUiInteraction: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: my profile',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: save'
  }),

  cancelProfileUiInteraction: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: my profile',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: continue without editing'
  })
};
