export const AccountType = [
  {
    label: 'Cheque',
    value: 'Cheque'
  },
  {
    label: 'Credit Card',
    value: 'Credit Card'
  },
  {
    label: 'Savings',
    value: 'Savings'
  },
  {
    label: 'Smartcard',
    value: 'Smartcard'
  },
  {
    label: 'Transmission',
    value: 'Transmission'
  }
];