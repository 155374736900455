import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styles from './baseModal.module.css';

export const BaseModal = ({children, className}) => createPortal(
  <div className={ styles.overlay }>
    <div className={`${styles.dialog} ${className}`}>
      { children }
    </div>
  </div>,
  document.body,
);

BaseModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};