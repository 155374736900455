import {
  DefaultValue,
  atom,
  selector,
  selectorFamily,
  useRecoilCallback
} from 'recoil';
import { v4 as uuidv4 } from 'uuid';

export const toasts = atom({
  key: 'toasts',
  default: {}
});

export const toastsMap = selector({
  key: 'toastMap',
  get: ({ get }) => get(toasts)
});

export const toastIds = selector({
  key: 'toastIds',
  get: ({ get }) => Object.keys(get(toasts))
});

export const toastProperties = selector({
  key: 'toastProperties',
  get: ({ get }) => {
    const t = get(toastsMap);
    return Object.keys(t).reduce((accum, toastId) => t[toastId].text ? {
      ...accum,
      [toastId]:
      {
        text: t[toastId].text,
        iconType: t[toastId].iconType,
        link: t[toastId].link,
        hasCloseButton: t[toastId].hasCloseButton
      }
    } : accum, {});
  }
});

export const toastItem = selectorFamily({
  key: 'toastItem',
  get: (id) => ({ get }) => get(toasts)?.[id] || '',
  set: (id) => ({ get, set }, inputValue) => {

    const curMap = get(toasts);

    if (!inputValue || inputValue instanceof DefaultValue) {
      set(toasts, Object.fromEntries(Object.entries(curMap).filter(([k]) => k !== id)));
    } else {
      set(toasts, {
        ...get(toasts),
        [id]: inputValue
      });
    }
  }
});

export const useShowToast = () => useRecoilCallback(({ set }) => (text,iconType, timeout, link,hasCloseButton) => {
  const toastId = uuidv4();
  const req = {
    text: text || '',
    timeout: timeout || '',
    iconType: iconType || 'success',
    link: link || '',
    hasCloseButton: hasCloseButton || false
  };
  set(toastItem(toastId), req);
  return toastId;
}, []);