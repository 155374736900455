import { ArrowDown, ArrowUp, FaqBtaIcon } from '../../../../../assets';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './faqItem.module.css';

export const FaqItem = ({ question, answer, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
    onClick();
  };

  return (
    <div className={styles.item} onClick={handleOnClick}>
      <div className={styles.header}>
        <img src={FaqBtaIcon} alt="Icon" />
        <div className={styles.title}>{question}</div>
        <img
          className={styles.arrow}
          src={isOpen ? ArrowUp : ArrowDown}
          alt="arrow"
          role="presentation"
        />
      </div>

      {isOpen && (
        // eslint-disable-next-line react/no-danger-with-children
        <div className={styles.content}>{answer}</div>
      )}
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};
