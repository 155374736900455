export const fundingDetails = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: funding details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: funding details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: funding details'
  }),

  formEnd: (amount, period, reason) => ({
    event_name: 'ui_interaction, form_end',
    page_name: 'vodapay: business advance: opf: funding details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: funding details',
    link_id: 'vodapay: business advance: opf: next',
    loan_amount: amount,
    loan_period: period,
    loan_reason: reason
  })
};

export const fundingQuote = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: funding quote',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  expectedRepaymentSchedule: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: funding quote',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: expected repayment schedule'
  }),

  acceptQuote: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: funding quote',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: accept quote'
  }),

  journeyEnd: () => ({
    event_name: 'page_view, business_advance_accept_quote, journey_end',
    page_name: 'vodapay: business advance: opf: funding quote thank you',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    journey_name: 'business advance: opf: bta funding quote'
  }),

  termsAndCondition: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: funding quote',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: term and conditions'
  })
};
