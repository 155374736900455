import React, { useEffect, useState } from 'react';
import styles from './withdrawal.module.css';
import { Accordion, Footer, TableComponent } from '../../../components';
import { Section } from '../../../components/section/section';
import { FundingDetailsTable } from './components/fundingDetailsTable/fundingDetailsTable';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { useModal } from '../../../components/modalController/useModal';
import { Anchor } from '../../../components/anchor/anchor';
import { AccordionStyle, CommonDetails } from '../../../constants';
import { ConfirmWithdrawalModal } from './components/confirmWithdrawalModal/confirmWithdrawalModal';
import { useNavigate } from 'react-router-dom';
import { StaticRoutes } from '../../../Router';
import { SuccessWithdrawalModal } from './components/successWithdrawalModal/successWithdrawalModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationsList, creditFacilityDetailsQuery, getWithdrawalQuoteQuery, withdrawalAmountState } from '../../../recoil';
import { withVerification } from '../../../helpers/withVerification';
import { postCapitalFacilityWithdrawalQuote } from '../../../api/vodaLend';
import { ArrowDown } from '../../../assets';
import { formatCurrency } from '../../../helpers';
import { useLoader } from '../../../components/Loader/useLoader';
import { useTealium } from '../../../tealium/useTealium';


const RepaymentHeader = {
  displayDate: 'Date',
  capital: 'Capital',
  cost: 'Cost',
  repayment: 'Repayment Amount'
};

const MIN_WITHDRAWAL_AMT = 1000;

export const Withdrawal = withVerification('/accounts/capital-facility/withdrawal', () => {

  const [cfDetails, setCfDetails] = useRecoilState(creditFacilityDetailsQuery);
  const [openModal, closeModal, modal] = useModal();
  const [openLoader, closeLoader] = useLoader();
  const [openSection, setOpenSection] = useState('fundingDetails');
  const navigate = useNavigate();
  const amtToWithdraw = useRecoilValue(withdrawalAmountState);
  const amtToWithdrawResp = useRecoilValue(getWithdrawalQuoteQuery(amtToWithdraw));
  const [appsList, setAppList] = useRecoilState(applicationsList);
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.creditFacilityWithdrawl.pageView());
  }, []);

  useEffect(() => {
    if (!amtToWithdraw || isNaN(amtToWithdraw) || amtToWithdraw < MIN_WITHDRAWAL_AMT) {
      navigate('/accounts/capital-facility');
      return;
    }
  }, [amtToWithdraw]);

  useEffect(() => {
    if (!modal?.isOpen && amtToWithdrawResp === 'withdrawalInProgress') {
      navigate('/accounts/capital-facility');
    }
  }, [modal]);

  useEffect(() => {
    if (amtToWithdrawResp === 'withdrawalInProgress') {
      openModal({
        title: 'Error',
        type: 'error',
        children: <div>withdrawal in progress, please try after current withdrawal is complete</div>,
        actions: [
          <Button
            style={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
            key="confirmWithdrawal"
            onClick={handleClose}
          >
            Confirm
          </Button>
        ],
        onCloseModal: handleClose
      });
    }
  }, [amtToWithdrawResp]);

  const handleAcceptWithdrawalClick = () => {
    track(events.creditFacilityWithdrawl.continue());
    closeModal();
    openModal({
      title: 'Please confirm withdrawal',
      children: <ConfirmWithdrawalModal amtToWithdraw={amtToWithdraw} />,
      actions: [
        <Button
          style={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
          key="confirmWithdrawal"
          onClick={handleConfirmWithdrawalClick}
        >
          Confirm
        </Button>,
      ]
    });
  };

  const handleClose = () => {
    closeModal();
    navigate('/accounts/capital-facility');
  };

  const handleConfirmWithdrawalClick = async () => {

    try {
      closeModal();
      openLoader();

      const resp = await postCapitalFacilityWithdrawalQuote(amtToWithdraw);

      setCfDetails({ ...cfDetails, withdrawalEligibility: false });

      const updatedApplicationStatus = [...appsList, resp];

      setAppList({ applications: updatedApplicationStatus });

      openModal({
        title: '',
        children: <SuccessWithdrawalModal />,
        actions: [
          <Button
            style={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
            key="okay"
            onClick={handleConfirmWithdrawalRequestClick}>Okay</Button>,
        ],
        type: 'success',
        onCloseModal: handleConfirmWithdrawalRequestClick
      });
    } catch (error) {
      closeModal();
      openModal({
        title: 'Error',
        children: <div>There was an error submitting your withdrawal.</div>,
        type: 'error',
        actions: [
          <Button key="withdrawalFailedClose" onClick={handleClose}>Close</Button>,
        ],
        onCloseModal: handleClose
      });
    } finally {
      closeLoader();
    }
  };

  const handleConfirmWithdrawalRequestClick = () => {
    closeModal();
    navigate(StaticRoutes.applications);
  };

  const handleTermsClick = () => {
    track(events.creditFacilityWithdrawl.termsAndConditions());
  };

  /* TODO: Fix accordions on this screen and tabular data table not adhering to full width. */

  return (
    <div className={styles.container}>
      <Section>
        <div className={styles.title}>Capital facility withdrawal review</div>

        <p><b>How it works</b></p>

        <ol className={styles.list}>
          <li>Please review your Capital facility withdrawal below.</li>
          <li>If you are satisfied, please accept the withdrawal.</li>
          <li>If you would like to change the withdrawal amount, please click the change amount button below.</li>
          <li>We will then email you a -copy of the Advance Agreement which includes a surety form and needs to be
            digitally signed by yourself.
          </li>
          <li>Funds will be transferred to your bank account as soon as the signed documents have been received.</li>
        </ol>

        <Accordion
          isOpen={openSection === 'fundingDetails'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={AccordionStyle.accordionTitle}>
                  {'Funding details'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'fundingDetails' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt='DropdownOpen'
                  role='presentation'
                  onClick={() => setOpenSection(openSection === 'fundingDetails' ? '' : 'fundingDetails')}
                  onKeyDown={() => setOpenSection(openSection === 'fundingDetails' ? '' : 'fundingDetails')}
                />
              </div>
            </>
          }
        >
          {amtToWithdrawResp !== 'withdrawalInProgress' && (
            <div className={styles.accordion}>
              <FundingDetailsTable
                withdrawalDetails={amtToWithdrawResp && amtToWithdrawResp !== 'withdrawalInProgress' ? amtToWithdrawResp : {}}
                onClick={() => track(events.creditFacilityWithdrawl.changeAmount())}
              />
            </div>
          )}
        </Accordion>

        <Accordion
          isOpen={openSection === 'repaymentSchedule'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={AccordionStyle.accordionTitle}>
                  {'Expected repayment schedule'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'repaymentSchedule' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt='DropdownOpen'
                  role='presentation'
                  onClick={() => setOpenSection(openSection === 'repaymentSchedule' ? '' : 'repaymentSchedule')}
                  onKeyDown={() => setOpenSection(openSection === 'repaymentSchedule' ? '' : 'repaymentSchedule')}
                />
              </div>
            </>
          }
        >
          <div className={styles.accordion}>
            <ul>
              <li>The repayment schedule for your most recent drawdown will be cancelled and replaced by the repayment schedule below.</li>
              <li>Repayment of your funding is made {amtToWithdrawResp?.paymentFrequency} via a debit order over {amtToWithdrawResp?.termLength} months ({amtToWithdrawResp?.repaymentSchedule?.length} payments in total).</li>
              <li>If you choose to settle your Advance early, you will save on all future costs - use the slider below to get an indication of potential savings.</li>
              <li>Please note that we do not change any processing, initiating or monthly service fees. We only change the cost as per below, simple!</li>
              <li>A debit order on your previous advance has already been submitted for processing by your bank or has already been debited from your account. Therefore, the capital portion of this debit order has already been factored into your current available balance.</li>
            </ul>
            {Array.isArray(amtToWithdrawResp?.repaymentSchedule)
              && amtToWithdrawResp.repaymentSchedule.length > 0
              && (
                <TableComponent
                  data={
                    (amtToWithdrawResp.repaymentSchedule || []).map(d => ({
                      ...d,
                      capital: formatCurrency(d.capital),
                      cost: formatCurrency(d.cost),
                      repayment: formatCurrency(d.repayment)
                    }))
                  }
                  heading={RepaymentHeader}
                />
              )}

          </div>
        </Accordion>

        <p className={styles.center}>By accepting you agree to the Capital Facility & Vodacom <Anchor className={styles.url} href={CommonDetails.vodalendTermsConditions} title="Capital Facility and Vodacom Terms and Conditions" onClick={handleTermsClick}>terms and conditions</Anchor>.</p>
      </Section>

      <Footer
        rightTitle={'Accept withdrawal'}
        rightCallback={handleAcceptWithdrawalClick}
      />
    </div>
  );
});
