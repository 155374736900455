import * as yup from 'yup';
import {
  accountNumberRegex,
  AccountType,
  BankNames
} from '../../constants';

export const bankDetailsValidationSchema = yup.object({
  bankName: yup.mixed().test('bankName', 'Please select an option from drop down list',
    (item) => !!BankNames.find(a => a.value === item?.value)),
  branchName: yup.string().trim().min(1, 'Branch name should be of minimum 1 characters length').required('Branch name is required'),
  branchNumber: yup.string().trim().min(1, 'Branch number should be of minimum 1 characters length').required('Branch number is required'),
  bankAccountType: yup.mixed().test('bankAccountType', 'Please select an option from drop down list',
    (item) => !!AccountType.find(a => a.value === item?.value)),
  accountHolder: yup.string().trim().min(1, 'Account holder should be of minimum 1 characters length').required('Account holder full name is required'),
  accountNumber: yup.string().matches(accountNumberRegex, 'Account number is not valid').required('Account number is required')
});