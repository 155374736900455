export const BankNames = [
  {
    label: 'ABSA Bank',
    value: 'ABSA Bank'
  },
  {
    label: 'Access Bank',
    value: 'Access Bank'
  },
  {
    label: 'African Bank',
    value: 'African Bank'
  },
  {
    label: 'Albaraka Bank',
    value: 'Albaraka Bank'
  },
  {
    label: 'Bank of Athens',
    value: 'Bank of Athens'
  },
  {
    label: 'Bank Zero',
    value: 'Bank Zero'
  },
  {
    label: 'Bidvest Bank',
    value: 'Bidvest Bank'
  },
  {
    label: 'BNP Paribas',
    value: 'BNP Paribas'
  },
  {
    label: 'Capitec Bank',
    value: 'Capitec Bank'
  },
  {
    label: 'Connect',
    value: 'Connect'
  },
  {
    label: 'Citi Bank',
    value: 'Citi Bank'
  },
  {
    label: 'Fin Bond Mutual Bank',
    value: 'Fin Bond Mutual Bank'
  },
  {
    label: 'FNB RMB',
    value: 'FNB RMB'
  },
  {
    label: 'Grindrod Bank',
    value: 'Grindrod Bank'
  },
  {
    label: 'Habib Overseas Bank',
    value: 'Habib Overseas Bank'
  },
  {
    label: 'HBZ Bank',
    value: 'HBZ Bank'
  },
  {
    label: 'HSBC Bank',
    value: 'HSBC Bank'
  },
  {
    label: 'Investec Bank',
    value: 'Investec Bank'
  },
  {
    label: 'Ithala',
    value: 'Ithala'
  },
  {
    label: 'JP Morgan Chase',
    value: 'JP Morgan Chase'
  },
  {
    label: 'Mercantile Bank',
    value: 'Mercantile Bank'
  },
  {
    label: 'Nedbank Limited',
    value: 'Nedbank Limited'
  },
  {
    label: 'Olympus Mobile',
    value: 'Olympus Mobile'
  },
  {
    label: 'Peoples Bank Ltd Inc NBS',
    value: 'Peoples Bank Ltd Inc NBS'
  },
  {
    label: 'Post Bank',
    value: 'Post Bank'
  },
  {
    label: 'RMB',
    value: 'RMB'
  },
  {
    label: 'Sage One',
    value: 'Sage One'
  },
  {
    label: 'SA Reserve Bank',
    value: 'SA Reserve Bank'
  },
  {
    label: 'SASFIN Bank',
    value: 'SASFIN Bank'
  },
  {
    label: 'Standard Bank',
    value: 'Standard Bank'
  },
  {
    label: 'Standard Chartered Bank',
    value: 'Standard Chartered Bank'
  },
  {
    label: 'State Bank of India',
    value: 'State Bank of India'
  },
  {
    label: 'UBank Limited',
    value: 'UBank Limited'
  },
  {
    label: 'Uni Bank',
    value: 'Uni Bank'
  },
  {
    label: 'VBSMutual Bank',
    value: 'VBSMutual Bank'
  },
  {
    label: 'Try Fetch',
    value: 'Try Fetch'
  },
  {
    label: 'Tyme Bank',
    value: 'Tyme Bank'
  },
  {
    label: 'Xero',
    value: 'Xero'
  },
  {
    label: 'Yodlee',
    value: 'Yodlee'
  },
  {
    label: 'Discovery Bank',
    value: 'Discovery Bank'
  }
].sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));