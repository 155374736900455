import React from 'react';
import {
  AccountType,
  BankNames,
  FormField,
  FormFieldWrapper
} from '../../constants';
import { FormikInput, FormikSelect } from '@vfs-digital-channels/ns-react-components';

export const BankDetailsComponent = () => (
  <div style={FormFieldWrapper}>
    <div style={FormField}>
      <FormikSelect
        label='Bank'
        name='bankName'
        options={BankNames}
      />
    </div>
    <div style={FormField}>
      <FormikInput label='Branch name' name='branchName' />
    </div>
    <div style={FormField}>
      <FormikInput label='Branch code' name='branchNumber' />
    </div>
    <div style={FormField}>
      <FormikSelect
        label='Account type'
        name='bankAccountType'
        options={AccountType}
      />
    </div>
    <div style={FormField}>
      <FormikInput label='Account holder full name' name='accountHolder' />
    </div>
    <div style={FormField}>
      <FormikInput label='Account number' name='accountNumber' />
    </div>
  </div>
);