import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StaticRoutes } from '../Router';
import { isUserVerified } from '../api/vodaLend';
import { useLoader } from '../components/Loader/useLoader';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { useModal } from '../components/modalController/useModal';
import { Loader } from '../components';
import { useRecoilValue } from 'recoil';
import { isUserVerifiedQuery } from '../recoil';

/**
 * Checks if user is verified and redirects to verification page if not
 * @param to - route to redirect to after verification
 * @param Component - component to render
 * @returns {function(*): *}
 */
export const withVerification = (to, Component) => {

  // eslint-disable-next-line react/display-name
  return (props) => {

    const navigate = useNavigate();
    const isUserVerified = useRecoilValue(isUserVerifiedQuery);

    useEffect(() => {

      if (!isUserVerified) {
        switch (to) {
          case '/quote':
            navigate(StaticRoutes.quoteVerify, { state: { to: to } });
            break;
          case '/accounts/capital-facility/withdrawal':
            navigate(StaticRoutes.rcfVerify, { state: { to: to } });
            break;
          default:
            navigate(StaticRoutes.verify, { state: { to: to } });
        }
      }

    }, [isUserVerified]);

    return (
      <>
        {!isUserVerified && (
          <div style={{ position: 'absolute', left: 0, top: 0 }}>
            <Loader height={'30%'} />
          </div>
        )}
        {isUserVerified && (
          <Component {...props} />
        )}
      </>
    );
  };
};
