import React, {
  useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  Colors,
  Fonts
} from '../../constants';
import {
  useSetRecoilState
} from 'recoil';
import {
  isTouchDevice,
  documentData
} from '../../recoil';
import {
  CloudUpload,
  FilesFolders
} from '../../assets';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    borderRadius: 20,
    border: `2px dashed ${Colors.GreyScale10}`
  },
  fileInput: {
    position: 'absolute',
    height: 0,
    width: 0,
    display: 'none'
  },
  iconsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  upload: {
    flexShrink: 1,
    flexGrow: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10
  },
  uploadIcon: {
    minHeight: 30,
    height: '50%',
    aspectRatio: 1,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  uploadText: {
    fontSize: 16,
    fontFamily: Fonts.Roboto,
    height: 20,
    marginLeft: 5,
    textAlign: 'center'
  },
  spacer: {
    flexShrink: 0,
    flexGrow: 1,
    flexBasis: '0px',
    minWidth: '0px'
  }
};

export const FileUpload = ({ fileId, onUpload }) => {

  const loadDocumentData = useSetRecoilState(documentData(fileId));
  const inputFileRef = useRef(null);

  const preventDefault = (e) => {
    e.preventDefault();
    onUpload();
    e.stopPropagation();
  };

  const onIconsClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <div style={styles.container}>
      <input
        type='file'
        style={styles.fileInput}
        id={`file-upload-${fileId}`}
        name={`file-upload-${fileId}`}
        ref={inputFileRef}
        multiple
        onChange={(e) => {
          preventDefault(e);
          const files = e.target.files;
          const fileArray = Array.from(files);
          loadDocumentData(fileArray);
          e.target.value = '';
        }}
      />
      <div
        style={styles.iconsContainer}
        onClick={onIconsClick}
        onKeyDown={onIconsClick}
        role='presentation'
        multiple
        onDrop={(e) => {
          preventDefault(e);
          const files = e.dataTransfer.files;
          const fileArray = Array.from(files);
          loadDocumentData(fileArray);
          e.dataTransfer.value = '';
        }}
        onDragOver={preventDefault}
        onDragLeave={preventDefault}
      >
        <div style={styles.spacer}>&nbsp;</div>
        <div style={styles.spacer}>&nbsp;</div>
        <div style={styles.upload}>
          <div style={{
            ...styles.uploadIcon,
            backgroundImage: `url(${CloudUpload})`
          }} />
          <div style={styles.uploadText}>
            Upload
          </div>
        </div>
        {!isTouchDevice &&
          <>
            <div style={styles.spacer}>&nbsp;</div>
            <div style={styles.upload}>
              <div style={{
                ...styles.uploadIcon,
                backgroundImage: `url(${FilesFolders})`
              }} />
              <div style={styles.uploadText}>
                Drag and drop
              </div>
            </div>
          </>
        }
        <div style={styles.spacer}>&nbsp;</div>
        <div style={styles.spacer}>&nbsp;</div>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  fileId: PropTypes.string.isRequired,
  onUpload: PropTypes.func
};
