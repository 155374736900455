export const bankingDetails = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: banking details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: banking details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: banking details'
  }),

  formEnd: (bankName) => ({
    event_name: 'ui_interaction, form_end',
    page_name: 'vodapay: business advance: opf: banking details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    lending_bank_name: bankName,
    form_name: 'business advance: opf: banking details',
    link_id: 'vodapay: business advance: opf: next'
  })
};
