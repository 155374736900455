import { CommonDetails } from './CommonDetails';

export const FaqBta = [
  {
    'title':'What is Business Term Advance?',
    'content':'A Business Term Advance (BTA) is a unique funding product offered by VodaLend to customers who meet the qualifying criteria.'
  },
  {
    'title':'What do I have to do to apply again for Business Term Advance?',
    'content':'You have to fully pay your BTA off while also ensuring you keep meeting the minimum qualifying criteria (i.e., CIPC or VAT registered business, in business for more than 12 months and have an annual turnover of R500k or more).'
  },
  {
    'title':'Could I please get a copy of my contract?',
    'content':`Yes, you can request your statement via a call ${CommonDetails.contactUsMsisdn} or email ${CommonDetails.contactUsEmail} and our Customer Care agents will assist you.`
  },
  {
    'title':'Can I get a statement for BTA?',
    'content':`Yes, you can request your statement via a call ${CommonDetails.contactUsMsisdn} or email ${CommonDetails.contactUsEmail} and our Customer Care agents will assist you.`
  }
];