export const Provinces = [
  {
    'label': 'Eastern Cape',
    'value': 'Eastern Cape'
  },
  {
    'label': 'Free State',
    'value': 'Free State'
  },
  {
    'label': 'Gauteng',
    'value': 'Gauteng'
  },
  {
    'label': 'KwaZulu-Natal',
    'value': 'KwaZulu-Natal'
  },
  {
    'label': 'Limpopo',
    'value': 'Limpopo'
  },
  {
    'label': 'Mpumalanga',
    'value': 'Mpumalanga'
  },
  {
    'label': 'Northern Cape',
    'value': 'Northern Cape'
  },
  {
    'label': 'North West',
    'value': 'North West'
  },
  {
    'label': 'Western Cape',
    'value': 'Western Cape'
  }
];