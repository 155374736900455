import PropTypes from 'prop-types';
import React from 'react';
import { useTealium } from '../../../../tealium/useTealium';
import { FaqItem } from './faqItem/faqItem';
import styles from './faqList.module.css';

export function FaqList({ questions }) {
  const { track, events } = useTealium();

  return (
    <div className={styles.faqList}>
      <div className={styles.title}>FAQ&apos;s</div>
      <div className={styles.list}>
        {questions.map((item, index) => (
          <FaqItem
            className={styles.item}
            key={index}
            question={item.question}
            answer={item.answer}
            onClick={() => track(events.creditFacility.faqSelected(item.question))}
          />
        ))}
      </div>
    </div>
  );
}

FaqList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ),
};
