import * as yup from 'yup';
import {
  isValidCheckSum,
  isValidSaIdDob,
  isValidSaIdGender,
  nonRsaPassportRegex,
  rsaIdRegex
} from '../../constants';
import {
  CountryListInput,
  CountryListInputNoZA
} from '../../helpers';

export const validationSchema = yup.object({
  idType: yup.string().required('Please select an ID type'),
  idNumber: yup.string().when('idType', {
    is: 'id',
    then: () => yup.string().matches(rsaIdRegex, 'SA ID Number is not valid')
      .test('idNumber', 'SA ID Number is not valid', (item) => isValidCheckSum(item))
      .test('dateOfBirth', 'SA ID number does not match DOB', (_, context) => {
        const { idNumber, dateOfBirth } = context?.parent || {};
        return !!isValidSaIdDob(idNumber, dateOfBirth);
      }).test('gender', 'SA ID number does not match Gender', (_, context) => {
        const { idNumber, gender } = context?.parent || {};
        if (!gender) {
          return true;
        }
        return !!isValidSaIdGender(idNumber, gender.value);
      }).required('SA ID Number is a required field'),
    otherwise: () => yup.string().nullable().optional()
  }),
  passportNumber: yup.string().when('idType',
    {
      is: 'pp',
      then: () => yup.string().matches(nonRsaPassportRegex, 'Passport Number is not valid').required('Passport number is a required field'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  passportCountry: yup.mixed().when('idType', {
    is: 'pp',
    then: () => yup.mixed().test('passportCountry', 'Passport number is not valid for Non South African country', (item, context) => {

      const { passportNumber, idType } = context?.parent || {};

      const passportCountryValue = item?.value;
      if (idType !== 'id' && passportCountryValue !== 'ZA') {

        return !!CountryListInputNoZA.find((d) => d.value === passportCountryValue) && nonRsaPassportRegex.test(passportNumber);
      }
      return !!CountryListInput.find((d) => d.value === passportCountryValue);
    }).required('Country of issue is a required field'),
    otherwise: () => yup.mixed()
  })
});
