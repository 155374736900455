import { DefaultValue, atom, selector } from 'recoil';
import storage from '../helpers/storage';

import * as vodaLend from '../api/vodaLend';

const STORE_USER_DETAILS = 'loginState:userDetails';
const MAX_TOKEN_TTL_MS = 86400000;

const getUserStore = async () => {
  let response;
  try {
    response = await storage.getItem(STORE_USER_DETAILS);
  } catch {
    response = null;
  }

  return response || {};
};

const setUserStore = async (value) => {
  let response;
  try {
    response = await storage.setItem(STORE_USER_DETAILS, value);
  } catch {
    response = false;
  }
  return response && value || {};
};

const userDetailsState = atom({
  key: 'userDetailsState',
  default: getUserStore()
});

export const userDetails = selector({
  key: 'userDetails',
  get: async ({ get }) => await get(userDetailsState),
  set: ({ set }, newValue) => { set(userDetailsState, setUserStore(newValue)); }
});

export const authDetailState = atom({
  key: 'authDetailState',
  default: vodaLend.getAuthorization()
});

export const authDetail = selector({
  key: 'authDetail',
  get: async ({ get }) => await get(authDetailState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(authDetailState);
      return;
    }
    const auth = newValue ? {
      ...newValue,
      ttl: newValue.ttl > MAX_TOKEN_TTL_MS ? MAX_TOKEN_TTL_MS : newValue.ttl
    } : null;
    set(authDetailState, vodaLend.setAuthorization(auth));
  }
});

export const userFirstName = selector({
  key: 'userFirstName',
  get: ({ get }) => get(authDetail)?.firstName || ''
});

export const userEmail = selector({
  key: 'userEmail',
  get: ({ get }) => get(authDetail)?.email || ''
});

export const continueClickedState = atom({
  key: 'continueClickedRegisterState',
  default: false
});
