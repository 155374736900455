import {
  atom,
  selector,
  DefaultValue
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => await vodaLend.getFinancialDetails();

const updateState = async (inputData) => await vodaLend.setFinancialDetails(inputData);

const financialDetailsDefault = selector({
  key: 'financialDetailsDefault',
  get: async () => await fetchState()
});

export const financialDetailsState = atom({
  key: 'financialDetailsState',
  default: financialDetailsDefault
});

export const financialDetails = selector({
  key: 'financialDetails',
  get: async ({ get }) => await get(financialDetailsState),
  set: ({ get, set, reset }, inputData) => {
    if (inputData instanceof DefaultValue) {
      reset(financialDetailsState);
      return;
    }
    const cur = get(financialDetailsState) || {};

    if (inputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...inputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(financialDetailsState, data);
      return;
    }

    set(financialDetailsState, updateState({
      ...cur,
      ...inputData
    }));
  }
});