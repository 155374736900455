import {
  atom,
  selector
} from 'recoil';

import * as vodaLend from '../api/vodaLend';

const fetchQuestions = async () => await vodaLend.getSecurityQuestions();

const securityQuestionsDefault = selector({
  key: 'securityQuestionsDefault',
  get: async () => await fetchQuestions()
});

const securityQuestionsState = atom({
  key: 'securityQuestionsState',
  default: securityQuestionsDefault
});

export const securityQuestions = selector({
  key: 'securityQuestions',
  get: async ({ get }) => {
    const data = await get(securityQuestionsState);
    return data || [];
  }
});
