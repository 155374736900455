import React, {
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Header } from '../Header/Header';
import {
  Colors,
  Fonts
} from '../../constants';
import { WhiteTick } from '../../assets';
import {
  useIsMobile,
  useWindowWidth
} from '../../recoil';

const HALF_STEP = 0.5;
const PER_CENT = 100;
const WINDOW_WIDTH_TABLET = 769;
const STEP_SIZE_NARROW_DIFF = 5;

const styles = {
  containerCommon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexGrow: 1,
    padding: 17,
    position: 'relative'
  },
  containerDesktop: {
    flexDirection: 'row'
  },
  containerMobile: {
    flexDirection: 'column'
  },
  stepDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  stepDivTitle: {
    fontFamily: Fonts.Roboto,
    fontSize: 14,
    color: Colors.GreyScale6,
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 8
  },
  stepDivTitleActive: {
    color: Colors.Primary4
  },
  stepCircleLarge: {
    borderRadius: '50%',
    border: '5px solid black',
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  stepCircleSmall: {
    borderRadius: '50%',
    border: '5px solid black',
    width: 10,
    height: 10,
    margin: 7.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.GreyScale6
  },
  stepCircleFill: {
    backgroundColor: 'red'
  },
  completedTick: {
    width: '50%'
  },
  stepperMobileTitle: {
    color: Colors.Primary4,
    fontWeight: 500,
    fontSize: 16
  },
  stepperMobilePageNum: {
    color: Colors.GreyScale10,
    fontWeight: 400,
    fontSize: 12
  },
  stepperDesktopProgressOuter: {
    height: 2,
    top: 33,
    position: 'absolute',
    zIndex: -1,
    backgroundColor: Colors.GreyScale9
  },
  stepperMobileProgressOuter: {
    width: '100%',
    height: 1,
    bottom: 0,
    left: 0,
    position: 'absolute'
  },
  stepperProgressInner: {
    height: '100%',
    backgroundColor: Colors.Primary4,
    left: 0,
    top: 0,
    position: 'absolute'
  }
};

const StepCircleComplete = () => {
  return (
    <div style={{ ...styles.stepCircleLarge, ...styles.stepCircleFill }}>
      <img src={WhiteTick} alt='' style={styles.completedTick} />
    </div>
  );
};

const StepCircleActive = ({ stepNum }) => {
  return (
    <div style={styles.stepCircleLarge}>
      <div style={{ color: Colors.Primary4, fontSize: '14px', fontWeight: '700' }}>
        {stepNum}
      </div>
    </div>
  );
};

StepCircleActive.propTypes = {
  stepNum: PropTypes.number.isRequired
};

const StepCircleFuture = () => {
  return (
    <div style={styles.stepCircleSmall} />
  );
};

const Step = ({ isActive, isComplete, title, stepNum, width }) => {
  return (
    <div style={{ ...styles.stepDiv, width }}>
      {
        isActive ? <StepCircleActive stepNum={stepNum} /> : isComplete ? <StepCircleComplete /> : <StepCircleFuture />
      }
      <div style={{ ...styles.stepDivTitle, ...(isActive ? styles.stepDivTitleActive : {}) }}>
        {title}
      </div>
    </div>
  );
};

Step.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  stepNum: PropTypes.number.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    subSteps: PropTypes.number.isRequired
  })).isRequired,
  onBackClick: PropTypes.func,
  activeSubStep: PropTypes.number.isRequired
};

const StepperContainerDesktop = ({ currentStep, steps }) => {

  const numSteps = Object.keys(steps).length || 1;
  const stepFraction = PER_CENT / numSteps;
  const progressPerc = useMemo(() => currentStep * stepFraction, [currentStep, stepFraction]);
  const windowWidth = useRecoilValue(useWindowWidth);
  const stepWidth = useMemo(() => windowWidth < WINDOW_WIDTH_TABLET ? stepFraction - STEP_SIZE_NARROW_DIFF : stepFraction, [windowWidth, stepFraction]);

  return (
    <>
      <div style={{...styles.containerCommon, ...styles.containerDesktop}}>
        <div style={{...styles.stepperDesktopProgressOuter, left: `${stepFraction * HALF_STEP}%`, width: `${PER_CENT - stepFraction - 1}%`}}>
          <div style={{...styles.stepperProgressInner, width: `${progressPerc}%`}} />
        </div>
        {
          Object.values(steps).map((value, idx) => (
            <Step
              key={`step${idx}`}
              title={value.label}
              isActive={idx + 1 === currentStep}
              isComplete={idx + 1 < currentStep}
              stepNum={idx + 1}
              width={`${stepWidth}%`}
            />
          ))
        }
      </div>
    </>
  );
};

StepperContainerDesktop.propTypes = propTypes;

const StepperContainerMobile = ({ currentStep, steps }) => {

  const numSteps = Object.keys(steps).length || 1;
  const progressPerc = useMemo(() => ((currentStep - 1) + HALF_STEP) * PER_CENT / numSteps, [currentStep, numSteps]);

  return (
    <>
      <div style={{ ...styles.containerCommon, ...styles.containerMobile }}>
        <div style={styles.stepperMobilePageNum}>
          Step {currentStep} of {numSteps}
        </div>
        <div style={styles.stepperMobileTitle}>
          {steps[currentStep - 1].label}
        </div>
      </div>
      <div style={styles.stepperMobileProgressOuter}>
        <div style={{ ...styles.stepperProgressInner, width: `${progressPerc}%` }} />
      </div>
    </>
  );
};

StepperContainerMobile.propTypes = propTypes;

export const ApplyHeader = (props) => {

  const isMobile = useRecoilValue(useIsMobile);
  const isNotClickable = !!(!!(props.currentStep === 1 && props.activeSubStep === 0) || !!(props.currentStep === 2 && props.activeSubStep === 0));

  return (
    <Header onBackClick={isNotClickable ? null : props.onBackClick}>
      {isMobile ? <StepperContainerMobile {...props} /> : <StepperContainerDesktop {...props} />}
    </Header>
  );
};

ApplyHeader.propTypes = propTypes;