import { pageName } from '../constants';

export const help = {
  click: (urlPath) => ({
    event_name: 'ui_interaction',
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'help'
  }),

  emailClick: (urlPath) => ({
    event_name: 'ui_interaction',
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'email help'
  }),

  phoneClick: (urlPath) => ({
    event_name: 'ui_interaction',
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'phone help'
  })
};
