import React from 'react';
import PropTypes from 'prop-types';
import { SuccessIcon, InfoIcon, CloseIcon } from '../../assets';
import {
  Colors,
  Fonts
} from '../../constants';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';

const styles = {
  toast: {
    display: 'flex',
    height: 60,
    backgroundColor: Colors.GreyScale3,
    borderRadius: '4px 8px 8px 4px',
    gap: 15,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    borderColor: Colors.AccentBlue4,
    borderLeft: `solid 5px ${Colors.AccentBlue4}`,
    padding: '8px 20px'
  },
  Icon: {
    width: '25px',
    height: '25px'
  },
  toastText: {
    fontFamily: Fonts.Roboto,
    fontSize: 16,
    lineHeight: 1.4
  },
  toastLink: {
    fontColor : Colors.AccentBlue3,
    fontFamily: Fonts.Roboto,
    fontSize: 16,
    lineHeight: 1.4
  }
};

export const ToastItem = ({
  text,
  iconType,
  link,
  clearToast,
  hasCloseButton
}) => {

  const isMobile = useRecoilValue(useIsMobile);

  const handleCloseToast = () => {
    clearToast();
  };

  function MapIcon(iconType) {
    switch (iconType) {
      case 'success':
        return SuccessIcon;
      case 'info':
        return InfoIcon;
      default:
        return SuccessIcon;
    }
  }

  return (
    <div style={styles.toast}>
      <img style={styles.Icon} src={MapIcon(iconType)} alt='Toast Icon' />
      <div style={isMobile ? { ...styles.toastText, fontSize: 14} : styles.toastText}>
        {text}
      </div>
      <div style={isMobile ? { ...styles.toastLink, fontSize: 14} : styles.toastLink}>
        {link &&  <Link to={link} onClick={handleCloseToast}>View Now</Link>}
      </div>
      {hasCloseButton && <img
        style={{...styles.Icon, cursor: 'pointer'}}
        src={CloseIcon}
        onClick={handleCloseToast}
        alt='Toast Icon'
      />
      }
    </div>
  );
};

ToastItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  clearToast: PropTypes.func,
  iconType: PropTypes.string
};