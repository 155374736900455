export const Position = [
  {
    'label': 'Directors and Major Shareholder (>25%)',
    'value': 'director_shareholder'
  },
  {
    'label': 'Director',
    'value': 'director'
  },
  {
    'label': 'Major Shareholder (>25%)',
    'value': 'shareholder'
  },
  {
    'label': 'Owner',
    'value': 'owner'
  },
  {
    'label': 'None of the Above',
    'value': 'other'
  }
];