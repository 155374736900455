import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Colors,
  Fonts
} from '../../constants';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';

const styles = {
  accordionContainer: {
    border: `1px solid ${Colors.GreyScale5}`,
    fontFamily: Fonts.Roboto,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginBottom: 20
  },
  accordionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 27,
    marginRight: 27
  },
  accordionContent: {
    fontFamily: Fonts.Roboto,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  accordionHeaderWrapper: {
    width: '100%',
    display: 'flex'
  }
};

export const Accordion = ({
  children,
  headerChildren,
  isOpen,
  containerStyle = {},
  headerStyle = {}
}) => {

  const isMobile = useRecoilValue(useIsMobile);
  const accordionStyle = useMemo(() => isMobile ? { ...styles.accordionHeader, marginRight: 20, marginLeft: 20 } : styles.accordionHeader, [isMobile]);

  return (
    <div style={{ ...styles.accordionContainer, ...containerStyle }}>
      <div style={styles.accordionHeaderWrapper}>
        <div style={{ ...accordionStyle, ...headerStyle }}>
          {headerChildren}
        </div>
      </div>
      {isOpen &&
        <div style={styles.accordionContent}>
          {children}
        </div>
      }
    </div>
  );
};

Accordion.propTypes = {
  headerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  containerStyle: PropTypes.object,
  headerStyle: PropTypes.object
};