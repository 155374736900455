import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useRecoilValue } from 'recoil';
import {
  Colors,
  CrossSellCarouselData
} from '../../constants';
import { useIsMobile } from '../../recoil';

const CrossSellCarouselWidth = 820;



const ZERO = 0;
const ONE = 1;
const TWO = 2;
const SCROLL_WIDTH_30 = 30;
const SCROLL_WIDTH_80 = 80;
const TOTAL_SCROLL_PERCENTAGE = 100;

export const CrossSellCarousel = ({ children }) => {

  const carouselRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState();
  const [scrolledWidth, setScrolledWidth] = useState();
  const isMobile = useRecoilValue(useIsMobile);

  const styles = {
    carouselContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      overflowX: 'auto',
      fontSize: 16,
      width: isMobile ? '95%' : '100%'
    },
    currentStepPointer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%'
    },
    currentCarousel: {
      height: 10,
      width: 10,
      borderRadius: '50%',
      marginTop: 22,
      background: Colors.GreyScale1,
      marginLeft: 5,
      border: `2px solid ${Colors.GreyScale9}`
    }
  };


  useEffect(() => {

    if (carouselRef.current?.scrollToCompleteProp) {
      carouselRef.current.scrollToCompleteProp = false;
      return;
    }
    if (scrolledWidth < SCROLL_WIDTH_30) {
      setCurrentSlide(ZERO);
    } else if (scrolledWidth > SCROLL_WIDTH_30 && scrolledWidth < SCROLL_WIDTH_80) {
      setCurrentSlide(ONE);
    } else if (scrolledWidth > SCROLL_WIDTH_80) {
      setCurrentSlide(TWO);
    }
  }, [scrolledWidth]);

  const handleScroll = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const visibleArea = container.offsetWidth;
      const totalWidth = container.scrollWidth;
      const scrolledWidth = container.scrollLeft;

      const scrolledPercentage = (scrolledWidth / (totalWidth - visibleArea)) * TOTAL_SCROLL_PERCENTAGE;

      setScrolledWidth(scrolledPercentage);
    }
  };

  useEffect(() => {
    if (carouselRef.current?.clientWidth) {
      setCarouselWidth(+carouselRef.current.clientWidth);
    }
  }, [carouselRef]);

  const getIndicatorColor = (idx) => idx === currentSlide ? {
    ...styles.currentCarousel,
    background: Colors.GreyScale13
  } : styles.currentCarousel;

  const changeSlider = (idx) => {
    if (!carouselRef.current) {
      return;
    }
    setCurrentSlide(idx);
    if (idx === ZERO) {
      carouselRef.current.scrollTo(0, 0);
    } else if (idx === ONE) {
      carouselRef.current.scrollTo((CrossSellCarouselWidth - carouselRef.current?.offsetWidth) / TWO, 0);
    } else if (idx === TWO) {
      carouselRef.current.scrollTo(CrossSellCarouselWidth, 0);
    }
    carouselRef.current.scrollToCompleteProp = true;
  };

  return (
    <>
      <div
        style={styles.carouselContainer}
        ref={carouselRef}
        onScroll={handleScroll}
      >
        {children}
      </div>

      <div style={styles.currentStepPointer}>
        {carouselWidth < CrossSellCarouselWidth && Array.isArray(CrossSellCarouselData) && CrossSellCarouselData.map((_, idx) => (
          <div
            style={getIndicatorColor(idx)}
            key={idx}
            onClick={() => changeSlider(idx)}
            onKeyDown={() => changeSlider(idx)}
            role='button'
            tabIndex={0}
          />
        ))}
      </div>
    </>
  );
};

CrossSellCarousel.displayName = 'CrossSellCarousel';

CrossSellCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
