import {
  axiosInstance
} from './axiosInstance';
import { handleErrorResponse } from '../../helpers/handleErrorResponse';

export const getBusinessDetails = async () => {
  let response;

  try {
    response = await axiosInstance.get('/business-detail');
  } catch (err) {
    response = handleErrorResponse(err);
  }

  return response;
};

export const setBusinessDetails = async (data) => {
  let response;

  try {
    response = await axiosInstance.patch('/business-detail', data);
  } catch (err) {
    response = handleErrorResponse(err, data);
  }

  return response;
};
