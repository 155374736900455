import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import {
  default as React,
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import {
  BankDetailsComponent,
  ErrorModalSection,
  FormSubmitHelper,
  SupportPopup,
  bankDetailsValidationSchema
} from '../../../components';
import {
  AccountType,
  BankNames,
  submissionStates
} from '../../../constants';
import { mapValues } from '../../../helpers';
import {
  financialDetails
} from '../../../recoil';

import { useModal } from '../../../components/modalController/useModal';
import { Typography } from '../../../components/typography/typography';
import { findFirstValidationError } from '../../../helpers/formik';
import { useTealium } from '../../../tealium/useTealium';
import styles from './FinancialDetails.module.css';


export const FinancialDetails = () => {

  const [openModal, closeModal] = useModal();
  const { submitState, setSubmitState } = useOutletContext();
  const details = useRecoilValue(financialDetails);
  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.bankingDetails.formStart());
  };

  useEffect(() => {
    track(events.bankingDetails.pageView());
  }, []);

  const formDetails = useMemo(() => ({
    ...details,
    bankName: BankNames.find((b) => b.value === details.bankName) || '',
    bankAccountType: AccountType.find((a) => a.value === details.bankAccountType) || ''
  }), [details]);

  const submitPromise = useRecoilCallback(({ snapshot, set }) => async (data) => {
    track(events.bankingDetails.formEnd(data.bankName));
    set(financialDetails, mapValues(data));
    return await snapshot.getPromise(financialDetails);
  }, []);

  const closeErrorModal = () => {
    submitPromise(formDetails);
    setSubmitState(submissionStates.default);
    closeModal();
  };

  useEffect(() => {
    if (details.error) {

      openModal({
        title: 'Error',
        children: <ErrorModalSection
          traceId={details?.traceId}
          error={details?.error}
          onCloseErrorModal={closeErrorModal}
        />,
        type: 'error',
        onCloseModal: closeErrorModal
      });
      return;
    }
    if (submitState === submissionStates.submitting && !details.error) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography level={3} family={'medium'} align={'center'} className={styles.titleHeading} spacing={5}>
          Banking details
        </Typography>
        <Typography size={'b1'} align={'center'}>
          The banking details below will be used to deduct your monthly loan instalments
        </Typography>
      </div>
      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          validationSchema={bankDetailsValidationSchema}
          validateOnMount={true}
          onSubmit={submitPromise}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form className={styles.financialDetail} onFocus={handleFormFocus}>
                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />
                <BankDetailsComponent />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
