import { Colors } from './CommonStyling';

export const CommonDetails = {
  contactUsMsisdn: '087 250 2644',
  contactUsEmail: 'fundingsupport@vodacom.co.za ',
  vodalendTermsConditions: 'https://vodalend.vodacombusiness.co.za/TermsAndConditions',
  vodalendPrivacyPolicy: 'https://vodalend.vodacombusiness.co.za/Home/DownloadPrivacyPolicy'
};

export const appStatuses = {
  Closed: 'Closed',
  Declined: 'Declined',
  Submitted: 'Submitted',
  InAssessment: 'InAssessment',
  QuoteAvailable: 'QuoteAvailable',
  QuoteAccepted: 'QuoteAccepted',
  AwaitingLegals: 'AwaitingLegals',
  LegalsSubmitted: 'LegalsSubmitted',
  AwaitingDisbursement: 'AwaitingDisbursement',
  Disbursed: 'Disbursed',
  Settled: 'Settled',
  CreditFacilityCreated: 'CreditFacilityCreated'
};

export const appStatusesText = {
  Closed: 'Closed',
  Declined: 'Declined',
  Submitted: 'Submitted',
  InAssessment: 'In Assessment',
  QuoteAvailable: 'Quote Available',
  QuoteAccepted: 'Quote Accepted',
  AwaitingLegals: 'Legal Contract Review',
  LegalsSubmitted: 'Legal Contract Submitted',
  AwaitingDisbursement: 'Awaiting Disbursement',
  Disbursed: 'Disbursed',
  Settled: 'Settled',
  CreditFacilityCreated: 'Credit Facility Approved'
};

export const appStatusSubtext = {
  Closed: 'Your application has been closed.',
  Declined: 'Your application was declined.',
  Submitted: 'Your application has been submitted, and shall be assessed shortly by our team within 48 hours.',
  InAssessment: 'Our team is currently reviewing your application and you should receive feedback within 48 hours.',
  QuoteAvailable: 'A quotation is available for your application.',
  QuoteAccepted: 'Your quotation has been submitted, and our team shall provide further feedback within 48 hours.',
  AwaitingLegals: 'Your application requires legal contract review.',
  LegalsSubmitted: 'Your legal contract has been submitted, and our team shall provide further feedback within 48 hours.',
  AwaitingDisbursement: 'Our team shall provide disbursement of your application shortly within 48 hours.',
  Disbursed: 'Your application funding has been disbursed to your account.',
  Settled: 'Your application funding has been settled.',
  CreditFacilityCreated: 'Your credit facility has been created.'
};

export const applicationStatusColors = {
  'Submitted': '#CA7A00',
  'InAssessment': '#FF7F00',
  'QuoteAvailable': Colors.AccentBlue4,
  'QuoteAccepted': Colors.AccentBlue4,
  'Closed': Colors.GreyScale13,
  'Declined': '#C50011',
  'AwaitingLegals': Colors.AccentBlue4,
  'LegalsSubmitted': Colors.AccentBlue4,
  'AwaitingDisbursement': Colors.AccentPurple5,
  'CreditFacilityCreated': '#108846',
  'Settled': Colors.GreyScale13,
  'Disbursed': '#108846'
};

export const applicationProduct = {
  RCF: 'CreditFacility',
  BTA: 'Term'
};

export const OPF_APPLICATION_PROGRESS_STATES = [
  appStatuses.AwaitingDisbursement,
  appStatuses.AwaitingLegals,
  appStatuses.CreditFacilityCreated,
  appStatuses.InAssessment,
  appStatuses.LegalsSubmitted,
  appStatuses.QuoteAvailable,
  appStatuses.QuoteAccepted,
  appStatuses.Submitted
];
