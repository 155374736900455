import {
  Button,
  FormikSelect,
  theme
} from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, {
  useEffect
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { StaticRoutes } from '../../Router';
import { isCorrectAnswers } from '../../api/vodaLend';
import { getErrorStatus } from '../../api/vodaLend/axiosInstance';
import {
  ErrorModalSection,
  Footer
} from '../../components';
import { useLoader } from '../../components/Loader/useLoader';
import { useModal } from '../../components/modalController/useModal';
import {
  FormContainer,
  FormField
} from '../../constants';
import { DefaultLayout } from '../../layouts/default';
import {
  applicationStatuses,
  isUserVerifiedQuery,
  securityQuestions
} from '../../recoil';
import { useTealium } from '../../tealium/useTealium';
import styles from './SecurityQuestions.module.css';

const securityValidationSchema = yup.object().shape({
  questions: yup
    .array().of(
      yup.object().shape({
        question: yup
          .mixed()
          .required('Answer is a required field')
          .test((item, ctx) => {

            const isValid = !!(item?.value && item.label);

            if (!isValid) {
              return ctx?.createError?.({ message: 'Please select an option from dropdown values', path: ctx?.path });
            }
            return true;
          })
      })
    ).required('Answer is a required field')
});

export const SecurityQuestions = () => {
  const navigate = useNavigate();
  const [openModal, closeModal] = useModal();
  const [openLoader, closeLoader] = useLoader();
  const assessment = useRecoilValue(securityQuestions);
  const questions = Array.isArray(assessment?.questions) ? assessment.questions : [];
  const appStates = useRecoilValue(applicationStatuses);
  const location = useLocation();
  const setUserVerification = useSetRecoilState(isUserVerifiedQuery);
  const { track, events } = useTealium();
  let formTouched = false;

  useEffect(() => {
    track(events.securityQuestions.journeyStart(location.state?.to));
  }, [location]);

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.securityQuestions.formStart(location.state?.to));
  };

  const goToApplicationsPage = () => {
    closeModal();
    navigate('/applications');
  };

  useEffect(() => {


    if (assessment?.error) {
      track(events.error('event', location.pathname, assessment?.error));
      openModal({
        title: 'Error',
        type: 'error',
        children: <ErrorModalSection
          traceId={assessment.traceId || ''}
          error={assessment?.error?.status === StatusCodes.NOT_FOUND ? { ...assessment.error, message: 'Security Questions attempt exceeded' } : assessment?.error}
          onCloseErrorModal={goToApplicationsPage}
        />,
        onCloseModal: goToApplicationsPage
      });
    }
  }, [assessment]);

  const handleFormSubmit = async (formValues, formikHelpers) => {
    track(events.securityQuestions.continue(location.state?.to));

    if (!Array.isArray(formValues.questions)) {
      return null;
    }

    const answers = formValues.questions.map((q) => {
      const [answerValue, questionId, answerId] = q.question.value.split(':');
      return {
        answerValue,
        questionId,
        answerId,
      };
    });

    let isCorrect;

    try {
      openLoader();

      isCorrect = await isCorrectAnswers({
        id: assessment.id,
        answers
      });

      setUserVerification(isCorrect);

    } catch (error) {
      track(events.error('event', location.pathname, error));
      isCorrect = 'error';
      const status = getErrorStatus(error);
      const traceId = error?.response?.headers?.['x-amzn-trace-id'] || '';

      if (status === StatusCodes.NOT_FOUND) {

        openModal({
          title: 'Error',
          type: 'error',
          children: <ErrorModalSection
            traceId={traceId}
            error={{ ...error, message: 'Security Questions attempt exceeded' }}
            onCloseErrorModal={goToApplicationsPage}
          />,
          onCloseModal: goToApplicationsPage
        });

      } else {

        openModal({
          title: 'Error',
          type: 'error',
          children: <ErrorModalSection
            traceId={traceId}
            error={error}
            onCloseErrorModal={closeModal}
          />
        });
      }

    } finally {
      closeLoader();
      formikHelpers?.resetForm?.();
    }

    if (isCorrect === 'error') {
      return false;
    }

    if (!isCorrect) {
      openModal({
        title: 'Incorrect answers',
        type: 'error',
        children: <p>Your answers are incorrect. Please try again.</p>,
        actions: [<Button key='tryAgain' onClick={closeModal}>Try again</Button>]
      });

      return false;
    }

    if (location.state?.to) {
      navigate(location.state?.to);
      return isCorrect;
    }

    if (appStates.isQuoteAvailable) {
      navigate(StaticRoutes.fundingQuote);
      return isCorrect;
    }
    if (appStates.isCreditFacilityCreated) {
      navigate('/accounts/capital-facility/withdrawal');
      return isCorrect;
    }
    return isCorrect;
  };

  return (
    <DefaultLayout>
      <div className={styles.container}>
        <div className={styles.title}>Security questions</div>

        <ol className={styles.statementList}>
          <li>To view your quote, please answer a few security questions.</li>
          <li>These questions are based on your personal credit history for the last 48 months</li>
          <li>All questions are mandatory</li>
        </ol>

        <ThemeProvider theme={theme.light}>
          <Formik
            initialValues={{ questions: Array.isArray(questions) ? Array.from({ length: questions.length }, () => ({})) : [] }}
            onSubmit={handleFormSubmit}
            validateOnMount={true}
            validationSchema={securityValidationSchema}
          >
            {({ submitForm, errors }) => {

              return (
                <Form style={FormContainer} onFocus={handleFormFocus}>

                  {Array.isArray(questions) && questions.length > 0 && questions.map((question, idx) => (
                    <div key={`security-question-${question.id}`} className={styles.questionSection}>
                      <div style={{ ...FormField, flexDirection: 'column' }}>
                        <div className={styles.questionText}>
                          {`${idx + 1}. ${question.question}`}
                        </div>

                        {Array.isArray(question.answerOptions) && (
                          <FormikSelect
                            label='Select an answer'
                            name={`questions.${idx}.question`}
                            options={question.answerOptions.map((opt) => ({
                              label: opt.answer,
                              value: `${opt.answer}:${question.id}:${opt.id}`
                            }))}
                            scrollToTop={false}
                          />
                        )}
                      </div>

                    </div>
                  ))}

                  <Footer
                    rightTitle='Continue'
                    rightCallback={Object.values(errors).length < 1 ? submitForm : undefined}
                  />
                </Form>
              );
            }}
          </Formik>
        </ThemeProvider>
      </div>
    </DefaultLayout>
  );
};
