import {
  axiosInstance,
  getErrorStatus
} from './axiosInstance';

export const listDocuments = async () => {

  let response;

  try {

    response = await axiosInstance.get('/documents');

  } catch (err) {

    response = {
      data: {
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};

export const fetchDocument = async (docId) => {

  let response;

  try {

    response = await axiosInstance.get(`/documents/${docId}`);

  } catch (err) {

    response = {
      data: {
        docId,
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};

export const uploadDocument = async (file, docType, docName) => {

  let response;

  const body = new FormData();
  body.append(docName, file, docName);

  try {

    response = await axiosInstance.post('/documents', body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        docType,
        docName
      }
    });

  } catch (err) {

    response = {
      data: {
        file,
        docType,
        docName,
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};

export const deleteDocument = async (docId) => {

  let response;

  try {

    response = await axiosInstance.delete(`/documents/${docId}`);

  } catch (err) {

    response = {
      data: {
        docId,
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};