import { StatusCodes } from 'http-status-codes';
import { ValidationErrorMessageMap } from '../constants/validationErrorMessageMap';

const defaultErrorMessage = 'An internal error has occurred occurred';

export const handleErrorResponse = (error, data = {}) => {
  // Default - Something happened in setting up the request that triggered an Error
  let result = {
    message: error.message || defaultErrorMessage,
  };

  if (!error.response) {
    // The request was made but no response was received
    result = {
      message: 'The request was sent but no response was received',
    };
  }

  // The request was made and the server responded with a status code that falls out of the range of 2xx
  if (error.response) {
    if (error.response.status >= StatusCodes.BAD_REQUEST
      && error.response.status <= StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      result = {
        status: error.response.status,
        message: ValidationErrorMessageMap[error.response.data?.message]
          || error.response.data?.message
          || defaultErrorMessage,
      };
    }

    if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      result = {
        status: error.response.status,
        traceId: error.response?.headers?.['x-amzn-trace-id'] || '',
        message: error.response.data?.message || defaultErrorMessage,
      };
    }
  }

  return {
    data: {
      ...data,
      error: result,
    }
  };
};
