import React, {
  useEffect,
  useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { StaticRoutes } from '../../../Router';
import { getApplications } from '../../../api/vodaLend';
import {
  CrossSellCarousel,
  CrossSellOpportunities,
  Footer,
  Header,
  SupportPopup
} from '../../../components';
import {
  Colors,
  CommonHeight,
  CrossSellCarouselData,
  Fonts
} from '../../../constants';
import {
  applications,
  useIsMobile,
  userEmail,
  userFirstName
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: CommonHeight.MinPageHeightWithHeaderWithoutFooter,
    marginBottom: 90,
    overflow: 'auto'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 10,
    width: '100%'
  },
  titleHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium,
    fontStyle: 'normal',
    fontSize: 32,
    lineHeight: '30px',
    color: Colors.GreyScale12,
    width: 'inherit'
  },
  titleHeadingMobile: {
    whiteSpace: 'pre-line',
    fontSize: 22
  },
  titleSubHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.Roboto,
    fontSize: 20,
    lineHeight: '24px',
    color: Colors.GreyScale10,
    width: 'inherit',
    whiteSpace: 'pre-line',
    marginTop: 25,
    flexDirection: 'column'
  },
  mobileTitleSubHeading: {
    fontSize: 16,
    width: 'auto',
    marginRight: 20,
    marginLeft: 20
  },
  titleSubEmailHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.Roboto,
    fontSize: 20,
    lineHeight: '24px',
    color: Colors.GreyScale12,
    width: 'inherit',
    marginTop: 50
  },
  mobileTitleSubEmailHeading: {
    fontSize: 16,
    lineHeight: '24px',
    marginTop: 20,
    whiteSpace: 'pre-line'
  },
  subHeading2: {
    marginTop: 22
  },
  supportingText: {
    marginTop: 20,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium
  },
  supportingTextMobile: {
    fontSize: 16
  },
  redProgressLine: {
    position: 'fixed',
    top: 80,
    height: 1.5,
    background: Colors.Primary4,
    width: '100%'
  },
  dividerLine: {
    maxWidth: 838,
    width: '70%',
    marginTop: 60,
    height: 1,
    background: Colors.GreyScale10
  },
  carouselHeading: {
    marginBottom: 20,
    marginTop: 53,
    fontFamily: Fonts.RobotoMedium,
    fontSize: '1.6em',
    width: '100%',
    alignItems: 'flex-start',
    marginLeft: 10
  }
};

export const SubmitApplication = () => {

  const isMobile = useRecoilValue(useIsMobile);
  const navigate = useNavigate();
  const name = useRecoilValue(userFirstName);
  const email = useRecoilValue(userEmail);
  const setApplications = useSetRecoilState(applications);
  const goToNextPage = () => {
    const allApps = getApplications();
    setApplications(allApps);
    navigate(StaticRoutes.applications);
  };
  const subHeading = useMemo(() => ({ ...(isMobile ? { ...styles.titleSubHeading, ...styles.mobileTitleSubHeading } : styles.titleSubHeading) }), [isMobile]);
  const titleHeading = useMemo(() => ({ ...(isMobile ? { ...styles.titleHeading, ...styles.titleHeadingMobile } : styles.titleHeading) }), [isMobile]);
  const titleSubEmailHeading = useMemo(() => ({ ...(isMobile ? { ...styles.titleSubEmailHeading, ...styles.mobileTitleSubEmailHeading } : styles.titleSubEmailHeading) }), [isMobile]);
  const supportingText = useMemo(() => ({ ...(isMobile ? { ...styles.supportingText, ...styles.supportingTextMobile } : styles.supportingText) }), [isMobile]);
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.registrationSuccess.pageView());
  }, []);

  return (
    <>
      <Header />
      <div style={styles.container}>
        {isMobile && <div style={styles.redProgressLine} />}
        <div style={styles.titleContainer}>
          <div style={titleHeading}>
            {`Thank you for your \n application, ${name || ''}`}
          </div>
          <div style={subHeading}>
            <div>
              {'Our team is currently reviewing your application and you should receive \n an email with feedback within 2 business days.'}
            </div>
            <div style={styles.subHeading2}>
              You&apos;re closer than ever to building your business your way.
            </div>
          </div>
          <div style={titleSubEmailHeading}>
            {`A confirmation email has been sent to:\n ${email || ''}`}
          </div>
          <div style={supportingText}>
            We see your vision, let&apos;s help you get there - Sinawe.
          </div>

          <div style={styles.dividerLine} />

          {Array.isArray(CrossSellCarouselData) && CrossSellCarouselData.length > 0 && (
            <div style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              marginBottom: 30,
              alignItems: 'center'
            }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: 850,
                  width: '100%'
                }}
              >

                <div style={{ ...styles.carouselHeading, textAlign: isMobile ? 'center' : 'start' }}>
                  You might also like
                </div>

                <CrossSellCarousel>
                  {Array.isArray(CrossSellCarouselData) && CrossSellCarouselData.map((d, idx) => (
                    <CrossSellOpportunities
                      btnImage={d.btnImage}
                      heading={d.heading}
                      link={d.link}
                      subHeading={d.subHeading}
                      mainImage={d.mainImage}
                      key={idx}
                      onClick={() => track(events.registrationSuccess.alsoLike(d.heading))}
                    />
                  ))}
                </CrossSellCarousel>
              </div>
            </div>
          )}
        </div>

        <Footer
          rightCallback={goToNextPage}
          rightTitle='View application status'
        />

        <SupportPopup />
      </div>
    </>
  );
};
