export const businessDetails1 = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: business details 1',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: business details 1',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business details 1'
  }),

  formEnd: (industry) => ({
    event_name: 'ui_interaction,  form_end',
    page_name: 'vodapay: business advance: opf: business details 1',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    smme_business_industry: industry,
    form_name: 'business advance: opf: business details 1',
    link_id: 'vodapay: business advance: opf: next'
  })
};

export const businessDetails2 = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: business details 2',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: business details 2',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business details 2'
  }),

  formEnd: () => ({
    event_name: 'ui_interaction,  form_end',
    page_name: 'vodapay: business advance: opf: business details 2',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business details 2',
    link_id: 'vodapay: business advance: opf: next',
    time_in_business: '<insert amount>',
    business_revenue: '<insert annual revenue>',
    source_of_income: '<insert source>'
  })
};

export const businessAddress = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: business address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: business address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business address'
  }),

  formEnd: () => ({
    event_name: 'ui_interaction,  form_end',
    page_name: 'vodapay: business advance: opf: business address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business address',
    link_id: 'vodapay: business advance: opf: next'
  })
};

export const businessDirectors = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: business directors',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: business directors',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business directors'
  }),

  addDirector: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: business directors',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: add director'
  }),

  formEnd: () => ({
    event_name: 'ui_interaction,  form_end',
    page_name: 'vodapay: business advance: opf: business address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: business directors',
    link_id: 'vodapay: business advance: opf: next'
  })
};
