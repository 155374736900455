export const Titles = [
  {
    'label': 'Mr',
    'value': 'Mr'
  },
  {
    'label': 'Ms',
    'value': 'Ms'
  },
  {
    'label': 'Mrs',
    'value': 'Mrs'
  },
  {
    'label': 'Miss',
    'value': 'Miss'
  },
  {
    'label': 'Doctor',
    'value': 'Doc'
  },
  {
    'label': 'Professor',
    'value': 'Prof'
  }
];