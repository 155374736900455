export const urlPaths = {
  logout: '/logout',
  login: '/login',
  registration: '/registration',
  home: '/',
  apply: '/apply',
  applyPersonalDetails: '/apply/personalDetails',
  applyPersonalAddress: '/apply/personalAddress',
  applyBusinessDetails: '/apply/businessDetails',
  applyBusinessAddress: '/apply/businessAddress',
  applyBusinessDirectors: '/apply/businessDirectors',
  applyBusinessFinancial: '/apply/businessFinancial',
  applyDocumentDetails: '/apply/documentDetails',
  applyFinancialDetails: '/apply/financialDetails',
  applyFundingDetails: '/apply/fundingDetails',
  submit: '/submit',
  accounts: '/accounts',
  accountsBta: '/accounts/bta',
  accountsCapitalFacility: '/accounts/capital-facility',
  accountsCapitalFacilityWithdrawal: '/accounts/capital-facility/withdrawal',
  applications: '/applications',
  quote: '/quote',
  quoteVerify: '/quote/verify',
  rcfVerify: '/rcf/verify',
  verify: '/verify',
  profile: '/profile',
  profilePersonalDetails: '/profile/personalDetails',
  profilePersonalAddress: '/profile/personalAddress',
  profileBusinessAddress: '/profile/businessAddress',
  profileBusinessDetails: '/profile/businessDetails',
  profileDirectorDetails: '/profile/directorDetails',
  profileDocumentDetails: '/profile/documentDetails',
  profileBankDetails: '/profile/bankDetails'
};

export const journeyName = {
  [urlPaths.quote]: 'bta funding quote',
  [urlPaths.accountsCapitalFacilityWithdrawal]: 'capital facility',
};

export const pageName = {
  [urlPaths.quote]: 'bta quote',
  [urlPaths.logout]: 'logout',
  [urlPaths.login]: 'login',
  [urlPaths.registration]: 'registration',
  [urlPaths.home]: 'home',
  [urlPaths.apply]: 'apply',
  [urlPaths.applyPersonalDetails]: 'apply personal details',
  [urlPaths.applyPersonalAddress]: 'apply personal address',
  [urlPaths.applyBusinessDetails]: 'apply business details',
  [urlPaths.applyBusinessAddress]: 'apply business address',
  [urlPaths.applyBusinessDirectors]: 'apply business directors',
  [urlPaths.applyBusinessFinancial]: 'apply business financial',
  [urlPaths.applyDocumentDetails]: 'apply document details',
  [urlPaths.applyFinancialDetails]: 'apply financial details',
  [urlPaths.applyFundingDetails]: 'apply funding details',
  [urlPaths.submit]: 'submit',
  [urlPaths.accounts]: 'accounts',
  [urlPaths.accountsBta]: 'accounts bta',
  [urlPaths.accountsCapitalFacility]: 'accounts capital facility',
  [urlPaths.accountsCapitalFacilityWithdrawal]: 'capital facility',
  [urlPaths.applications]: 'applications',
  [urlPaths.quoteVerify]: 'quote verify',
  [urlPaths.rcfVerify]: 'rcf verify',
  [urlPaths.verify]: 'security questions',
  [urlPaths.profile]: 'profile',
  [urlPaths.profilePersonalDetails]: 'profile personal details',
  [urlPaths.profilePersonalAddress]: 'profile personal address',
  [urlPaths.profileBusinessAddress]: 'profile business address',
  [urlPaths.profileBusinessDetails]: 'profile business details',
  [urlPaths.profileDirectorDetails]: 'profile director details',
  [urlPaths.profileDocumentDetails]: 'profile document details',
  [urlPaths.profileBankDetails]: 'profile bank details'
};
