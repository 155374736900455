import React, { Suspense } from 'react';
import './index.css';
import {
  ErrorFallback,
  Error
} from './pages';
import {
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, Toast } from './components';
import { ModalController } from './components/modalController/modalController';
import { RecoilRoot } from 'recoil';
import { routes } from './constants/routes';
import { theme } from '@vfs-digital-channels/ns-react-components';
import { ThemeProvider } from 'styled-components';
import { LoaderController } from './components/Loader/LoaderController';

const mapNodeChildren = (children) => children.reduce((accum, child) => ({
  ...accum,
  [child.path]: child
}), {});

const getPaths = (node, baseName, basePath) => Object.entries(node).reduce((accum, [key, value]) => {
  let ret = accum;
  if (key) {
    ret = {
      ...ret,
      [`${baseName}${key}`]: `${basePath}/${value.path}`.replace(/\/+/, '/')
    };
  }
  if (Array.isArray(value.children)) {
    ret = {
      ...ret,
      ...getPaths(mapNodeChildren(value.children), `${baseName}${key}_`, `${basePath}/${value.path}`)
    };
  }
  return ret;
}, {});

export const StaticRoutes = getPaths(routes, '', '');
export const ApplicationRoutes = getPaths(mapNodeChildren(routes.loanApplication.children), '', routes.loanApplication.path);
export const QuoteRoutes = getPaths(mapNodeChildren(routes.fundingQuote.children), '', routes.fundingQuote.path);
export const AccountsRoutes = getPaths(mapNodeChildren(routes.accounts.children), '', routes.accounts.path);
export const ProfileRoutes = getPaths(mapNodeChildren(routes.profile.children), '', routes.profile.path);

const Root = () => (
  <RecoilRoot>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader height={'30%'} />}>
        <ThemeProvider theme={theme.light}>
          <ModalController />
          <LoaderController />
          <Toast>
            <Outlet />
          </Toast>
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>
  </RecoilRoot>
);

export const Router = createBrowserRouter([
  {
    element: <Root />,
    children: Object.values(routes),
    errorElement: <Error />
  }
]);
