import React from 'react';
import { useModal } from './useModal';
import { Modal } from '../modal/modal';

export const ModalController = () => {
  const [, closeModal, modal] = useModal();
  return modal.isOpen && (
    <Modal onClose={closeModal} {...modal} />
  );
};
