export const applications = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: my applications',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  viewApplicationDetails: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: my applications',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: view application details'
  }),

  alsoLike: (selectedName) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: my applications',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: `vodapay: business advance: opf: also like: ${selectedName}`
  }),

  quotationViewNow: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: my applications',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: quotation view now'
  })
};
