import {
  axiosInstance,
  getErrorStatus
} from './axiosInstance';

export const getFinancialDetails = async () => {

  let response;

  try {
    response = await axiosInstance.get('/financial-detail');

  } catch (err) {

    response = {
      data: {
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};

export const setFinancialDetails = async (data) => {

  let response;

  try {

    response = await axiosInstance.patch('/financial-detail', data);

  } catch (err) {

    response = {
      data: {
        ...data,
        traceId: err?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(err || {}),
          status: getErrorStatus(err)
        }
      }
    };
  }

  return response.data;
};