import React, {
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  FormField,
  FormFieldError
} from '../../constants';
import {
  FormikInput,
  FormikSelect
} from '@vfs-digital-channels/ns-react-components';
import { FormRadioGroup } from '../FormRadioGroup/FormRadioGroup';
import { CountryListInput } from '../../helpers';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';

const styles = {
  countryOfIssueField: {
    width: '100%'
  },
  mobileCountryOfIssueField: {
    width: '100%'
  }
};

export const Identity = ({ namePrefix }) => {

  const { values, errors } = useFormikContext();
  const isMobile = useRecoilValue(useIsMobile);

  const pref = useMemo(() => namePrefix ? `${namePrefix}.` : '', [namePrefix]);
  const idType = useMemo(() => (namePrefix ? namePrefix.split('.').reduce((sub, idx) => sub[idx], values) : values)?.idType, [values, namePrefix]);

  return (
    <>
      {
        idType === 'id'
          ?
          <div style={FormField}>
            <FormikInput label='SA ID number' name={`${pref}idNumber`} />
          </div>
          :
          <div style={FormField}>
            <FormikInput label='Passport number' name={`${pref}passportNumber`} />
          </div>
      }
      <FormRadioGroup name={`${pref}idType`} values={{
        id: 'SA ID Number',
        pp: 'Passport'
      }} />
      {idType !== 'id' &&
        <div style={{
          ...(errors?.passportCountry ? FormFieldError : FormField),
          ...(isMobile ? styles.mobileCountryOfIssueField : styles.countryOfIssueField)
        }}>
          <FormikSelect
            label='Country of issue'
            name={`${pref}passportCountry`}
            options={CountryListInput}
          />
        </div>
      }
    </>
  );
};

Identity.propTypes = {
  namePrefix: PropTypes.string.isRequired
};