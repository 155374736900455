export const uploadDocuments = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: upload documents',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  documentUpload: () => ({
    event_name: 'tool_start',
    page_name: 'vodapay: business advance: opf: upload documents',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    tool_name: 'document upload',
    tool_input: 'bank statement',
    link_id: 'vodapay: business advance: opf: document upload'
  }),

  nextClick: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: upload documents',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: next'
  })
};
