import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowDown, Logo } from '../../assets';
import { useRecoilValue } from 'recoil';
import { Colors, Fonts } from '../../constants';
import { userFirstName } from '../../recoil';
import { UserOptionsList } from '../UserOptionsList/UserOptionsList';

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 93,
    background: Colors.GreyScale1,
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.15)',
    position: 'sticky',
    top: 0,
    zIndex: 99
  },
  leftSection: {
    flexGrow: 0,
    width: 27,
    height: 93,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4%',
    justifyContent: 'flex-start'
  },
  logoImage: {
    width: '100%'
  },
  rightSection: {
    flexGrow: 0,
    display: 'flex',
    marginRight: '5%',
    alignItems: 'center',
    height: 24
  },
  dropDownDiv: {
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  greeting: {
    marginRight: '10px',
    fontSize: '16px',
    fontFamily: Fonts.Roboto
  },
  userOptionsImg: {
    width: '12px',
    height: '6px',
    cursor: 'pointer'
  },
  dropDown: {
    position: 'absolute',
    top: 60,
    right: '4%'
  },
  userOptionsBackground: {
    width: '100%',
    height: '100vh',
    background: 'transparent',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 100
  }
};

const LeftSection = () => (
  <div
    style={styles.leftSection}
    role='button'
    tabIndex='0'
  >
    <img style={styles.logoImage} src={Logo} alt='Vodacom logo' />
  </div>
);

const RightSection = () => {
  const [isUserOptionsOpen, setUserOptionsOpen] = useState(false);
  const name = useRecoilValue(userFirstName);

  return (
    <div style={styles.rightSection}>
      {name &&
        <div style={styles.dropDownDiv}>
          <div style={styles.greeting}>
            Hi {name}
          </div>
          <img
            style={{ ...styles.userOptionsImg, rotate: isUserOptionsOpen ? '180deg' : '' }}
            src={ArrowDown}
            alt='Dropdown Logo'
            onClick={() => setUserOptionsOpen(!isUserOptionsOpen)}
            onKeyDown={() => setUserOptionsOpen(!isUserOptionsOpen)}
            role='presentation'
          />
          {isUserOptionsOpen && (
            <div
              style={styles.userOptionsBackground}
              onClick={() => setUserOptionsOpen(false)}
              onKeyDown={() => setUserOptionsOpen(false)}
              role='presentation'
            >
              <div style={styles.dropDown}>
                <UserOptionsList
                  isUserOptionsOpen={isUserOptionsOpen}
                  setUserOptionsOpen={setUserOptionsOpen}
                />
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export const DesktopHeader = ({ children }) => (
  <div style={styles.headerContainer}>
    <LeftSection />

    {children}

    <RightSection />
  </div>
);

DesktopHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
