import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { useRecoilValue } from 'recoil';
import { Colors, CommonHeight } from '../../constants';
import { useIsMobile } from '../../recoil';

const styleButtonCommon = {
  marginLeft: 20,
  marginRight: 20,
  minWidth: '10%',
  textAlign: 'center'
};

const stylesBase = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: CommonHeight.NavigationFooterHeightDesktop,
    background: Colors.GreyScale11,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    left: 0
  },
  buttonLeft: {
    ...styleButtonCommon,
    background: 'transparent',
    border: `2px solid ${Colors.GreyScale7}`
  },
  buttonRight: {
    ...styleButtonCommon,
    backgroundColor: Colors.Primary4,
    border: `2px solid ${Colors.Primary4}`
  }
};

const stylesDesktop = {
  ...stylesBase,
  buttonRight: {
    ...stylesBase.buttonRight,
    marginLeft: 'auto'
  }
};

const stylesMobile = {
  ...stylesBase,
  container: {
    ...stylesBase.container,
    height: CommonHeight.NavigationFooterHeightMobile
  },
  buttonLeft: {
    ...stylesBase.buttonLeft,
    flex: '1 1 100%'
  },
  buttonRight: {
    ...stylesBase.buttonRight,
    flex: '1 1 100%'
  }
};

const disabledBtnStyle = {
  backgroundColor: 'transparent',
  border: `2px solid ${Colors.GreyScale7}`
};

export const Footer = ({
  leftTitle,
  rightTitle,
  leftCallback,
  rightCallback,
  rightBtnStyle = {}
}) => {

  const isMobile = useRecoilValue(useIsMobile);
  const styles = useMemo(() => isMobile ? stylesMobile : stylesDesktop, [isMobile]);
  const buttonRight = useMemo(() => ({ ...(!rightCallback ? { ...styles.buttonRight, ...disabledBtnStyle, ...rightBtnStyle } : styles.buttonRight) }), [rightCallback, styles]);

  return (
    <div style={styles.container}>
      {leftTitle && <Button
        style={styles.buttonLeft}
        onClick={leftCallback || undefined}
        disabled={!leftCallback}
        variant='secondary'
      >
        {leftTitle}
      </Button>}


      {!!rightTitle && <Button
        style={buttonRight}
        onClick={rightCallback || undefined}
        disabled={!rightCallback}
        variant='primary'
      >
        {rightTitle}
      </Button>}
    </div>
  );
};

Footer.propTypes = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  leftCallback: PropTypes.func,
  rightCallback: PropTypes.func,
  rightBtnStyle: PropTypes.object
};