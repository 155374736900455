import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../../constants';
import { useRecoilValue } from 'recoil';
import { useIsMobile } from '../../recoil';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 50,
    marginBottom: 100,
    justifyContent: 'center'
  },
  flatLine: {
    width: 80,
    height: 2,
    borderRadius: 2,
    margin: 5
  }
};

const flatLineMobile = {
  ...styles.flatLine,
  width: 60
};

const FlatLine = ({ pagerColor }) => {

  const isMobile = useRecoilValue(useIsMobile);
  const lineStyle = useMemo(() => isMobile ? { ...flatLineMobile, background: pagerColor }
    : { ...styles.flatLine, background: pagerColor }, [pagerColor, isMobile]);

  return (
    <div style={lineStyle} />
  );
};

FlatLine.propTypes = {
  pagerColor: PropTypes.string
};

export const FlatLinePager = ({ numSteps, activeStep, activeColor, inactiveColor }) => {
  const active = useMemo(() => activeColor || Colors.Primary4, [activeColor]);
  const inactive = useMemo(() => inactiveColor || Colors.GreyScale8, [inactiveColor]);

  return (
    <div style={styles.container}>
      {
        Array.apply(null, Array(numSteps)).map((_, i) =>
          <FlatLine key={`flatline${i}`} pagerColor={i <= activeStep ? active : inactive} />
        )
      }
    </div>
  );
};

FlatLinePager.propTypes = {
  numSteps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string
};