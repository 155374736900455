import { axiosInstance } from './axiosInstance';
import { StatusCodes } from 'http-status-codes';

export const getOffers = async () => {

  const response = await axiosInstance.get('/offers');

  if (response.status !== StatusCodes.OK || !response.data) {

    throw new Error('GetOffersDetailsError');
  }

  return response.data;
};

export const acceptOffers = async (data) => {

  const response = await axiosInstance.post('/offers', data);

  if (response.status !== StatusCodes.CREATED) {

    throw new Error('FailedToAcceptOffers');
  }

  return response.data;
};