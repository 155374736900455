import {
  axiosInstance,
} from './axiosInstance';
import { handleErrorResponse } from '../../helpers/handleErrorResponse';

export const getPersonalDetails = async () => {
  let response;

  try {
    response = await axiosInstance.get('/personal-detail');
  } catch (err) {
    response = handleErrorResponse(err);
  }

  return response;
};

export const setPersonalDetails = async (data) => {
  let response;

  try {
    response = await axiosInstance.patch('/personal-detail', data);
  } catch (err) {
    response = handleErrorResponse(err, data);
  }

  return response;
};
