export const personalDetails = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: personal details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: personal details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: personal details'
  }),

  formEnd: (citizenshipType) => ({
    event_name: 'ui_interaction, form_end',
    page_name: 'vodapay: business advance: opf: personal details',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    citizenship_type: citizenshipType,
    form_name: 'business advance: opf: personal details',
    link_id: `vodapay: business advance: opf: ${citizenshipType}: next`
  })
};

export const personalAddress = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: personal address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: personal address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: personal address'
  }),

  formEnd: () => ({
    event_name: 'ui_interaction, form_end',
    page_name: 'vodapay: business advance: opf: personal address',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: personal address',
    link_id: 'vodapay: business advance: opf: next'
  })
};
