export const LoanReasonOptions = [
  {
    'label': 'Bridging loan',
    'value': 'bridging'
  },
  {
    'label': 'Equipment purchase',
    'value': 'equipment'
  },
  {
    'label': 'Inventory purchase',
    'value': 'inventory'
  },
  {
    'label': 'Marketing',
    'value': 'marketing'
  },
  {
    'label': 'Pay a bill',
    'value': 'bills'
  },
  {
    'label': 'Pay a salary',
    'value': 'salaries'
  },
  {
    'label': 'Refinance',
    'value': 'refinance'
  },
  {
    'label': 'Other',
    'value': 'Other'
  }
];