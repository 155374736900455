import { selectorFamily } from 'recoil';

/**
 * This selector is used to find the first state with an error.
 * @param states
 * @returns {Promise<*>}
 */
export const findErrorState = selectorFamily({
  key: 'findErrorState',
  get: (states = []) => ({ get }) => {
    return states
      .map(state => get(state))
      .find(state => state?.error);
  }
});
