import React from 'react';
import { useSetRecoilState } from 'recoil';
import { authDetail } from '../../recoil';
import { StaticRoutes } from '../../Router';
import { removeActiveSession } from '../../api/vodaLend/logout';
import { Loader } from '../../components';

export const Logout = () => {
  const resetLogin = useSetRecoilState(authDetail);

  React.useEffect(() => {
    const performLogout = async () => {
      await removeActiveSession();
      resetLogin(null);
      localStorage.removeItem('loanApplicationStepKey');
      window.location.href = StaticRoutes.login;
    }

    performLogout();
  }, [resetLogin]);

  return (
    <Loader height={'30%'} />
  );
};