import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './fundingDetailsTable.module.css';
import { useNavigate } from 'react-router-dom';
import { businessDetailsSubmit } from '../../../../../recoil';
import { useRecoilValue } from 'recoil';
import { formatCurrencyWithSpace } from '../../../../../helpers';

export const FundingDetailsTable = ({
  withdrawalDetails,
  onClick
}) => {

  const businessDetails = useRecoilValue(businessDetailsSubmit);
  const navigate = useNavigate();
  const handleChangeAmountClick = () => {
    onClick();
    navigate('/accounts/capital-facility');
  };

  const data = useMemo(() => {

    if (withdrawalDetails) {

      return [
        ['Drawdown amount', formatCurrencyWithSpace(withdrawalDetails.drawdownAmount)],
        ['Total advanced amount', formatCurrencyWithSpace(withdrawalDetails.loanAmount)],
        ['Number of months', withdrawalDetails.termLength],
        ['Company name', businessDetails.name],
        ['Total repayment', formatCurrencyWithSpace(withdrawalDetails.totalRepaymentAmount)]
      ];
    }
    return null;
  }, [withdrawalDetails, businessDetails]);

  return (
    <>
      <div className={styles.actionBar}>
        <button onClick={handleChangeAmountClick} className={styles.url}>Change amount</button>
      </div>
      <div className={styles.table}>
        {data && data.map((row, i) => (
          <div key={i} className={styles.row}>
            {row.map((col, j) => (
              <div key={j} className={styles.col}>{col}</div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

FundingDetailsTable.propTypes = {
  withdrawalDetails: PropTypes.object,
  onClick: PropTypes.func
};