import React, { useRef , useEffect} from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button, FormikInput } from '@vfs-digital-channels/ns-react-components';
import { useModal } from '../../../../components/modalController/useModal';
import { IncreaseLimitSuccessModal } from '../increaseLimitSuccessModal/increaseLimitSuccessModal';
import { StaticRoutes } from '../../../../Router';
import { increaseCapitalFacilityLimit } from '../../../../api/vodaLend';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../../helpers';
import { Colors, CommonDetails, Fonts } from '../../../../constants';
import { useLoader } from '../../../../components/Loader/useLoader';
import { getErrorStatus } from '../../../../api/vodaLend/axiosInstance';
import { StatusCodes } from 'http-status-codes';
import { creditFacilityDetailsQuery } from '../../../../recoil';
import { useRecoilState } from 'recoil';
import { Anchor } from '../../../../components/anchor/anchor';

const MAX_LIMIT = 5000000;
const MIN_LIMIT = 1000;

export const IncreaseLimitModal = ({ capitalFacilityId, currentLimit, onView = () => { }, onSubmit, onSuccess, onClick }) => {

  useEffect(() => {
    onView();
  }, []);

  const [cfDetails, setCfDetails] = useRecoilState(creditFacilityDetailsQuery);
  const validationSchema = yup.object({
    limit: yup
      .number().integer('Limit increase amount must be a whole numerical value')
      .min(MIN_LIMIT, `Limit should be greater than or equal to ${MIN_LIMIT}`)
      .max(MAX_LIMIT, `Limit should be less than or equal to ${MAX_LIMIT}`)
      .test(
        'is-less-than-limit',
        'Total amount must be lower or equal to R5m',
        function (value) {
          const total = Number(currentLimit) + Number(value);
          return total <= MAX_LIMIT;
        })
      .required('Enter a limit amount')
  });

  const [openModal, closeModal] = useModal();
  const navigate = useNavigate();
  const formRef = useRef();
  const [openLoader, closeLoader] = useLoader();

  const handleClose = () => {
    closeModal();
    navigate(StaticRoutes.applications);
  };

  const handleSubmit = async ({ limit }, { setSubmitting }) => {
    closeModal();
    openLoader();
    try {
      await increaseCapitalFacilityLimit(capitalFacilityId, +limit);
      setSubmitting(false);
      setCfDetails({ ...cfDetails, limitIncreaseEligibility: false });
      closeModal();
      openModal({
        title: '',
        children: <IncreaseLimitSuccessModal  onSuccess={onSuccess} onClick={onClick}/>,
        type: 'success',
        actions: [
          <Button key="increaseLimitSuccessClose" style={styles.submitBtn} onClick={handleClose}>Okay</Button>,
        ],
        onCloseModal: handleClose
      });
    } catch (error) {
      setSubmitting(false);
      const status = getErrorStatus(error);
      closeModal();
      openModal({
        title: 'In progress',
        children: <div>{status === StatusCodes.BAD_REQUEST ? 'Current Limit Increase in Progress' : 'There was an error submitting your application.'}</div>,
        type: '',
        actions: [
          <Button key="increaseLimitSuccessClose" onClick={handleClose}>Okay</Button>,
        ],
        onCloseModal: handleClose
      });
    } finally {
      closeLoader();
    }
  };

  const submitDetails = () => {
    if (formRef.current) {
      onSubmit();
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      initialValues={{ limit: MIN_LIMIT }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values, errors }) => (
        <Form>
          <div style={ styles.description }>Tell us how much you need</div>

          <div style={ styles.formInputs }>
            { isNaN(currentLimit) ? '' : (
              <div>
                <div style={ styles.limitText }>Current limit</div>
                <div style={ styles.limitAmount }>{ formatCurrency((+currentLimit)) }</div>
              </div>
            ) }
            <FormikInput label="Limit increase amount" name="limit" type='number'/>
            { isNaN(currentLimit + values.limit) ? '' : (
              <div>
                <div style={ styles.limitText }>New Limit</div>
                <div style={ styles.limitAmount }>{ formatCurrency((+currentLimit) + (+values.limit)) }</div>
              </div>
            ) }
          </div>

          <div style={ styles.terms }>By clicking &quot;Confirm&quot;, you agree to the Capital Facility & Vodacom <Anchor className={ styles.url } href={ CommonDetails.vodalendTermsConditions } title="Capital Facility and Vodacom Terms and Conditions">terms and conditions</Anchor>.
          </div>

          <div style={ {display: 'flex', justifyContent: 'center'} }>

            <Button
              style={ errors?.limit ? disabledBtn : styles.submitBtn }
              onClick={ submitDetails }
              disabled={ errors?.limit }
            >
              Confirm
            </Button>

          </div>

        </Form>
      ) }
    </Formik>
  );
};

IncreaseLimitModal.propTypes = {
  capitalFacilityId: PropTypes.number.isRequired,
  currentLimit: PropTypes.number.isRequired,
  onView: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onClick: PropTypes.func
};

const styles = {
  submitBtn: {
    borderRadius: 8,
    maxWidth: 230,
    font: Fonts.Roboto,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  limitText: {
    textAlign: 'left',
    paddingLeft: '1rem'
  },
  limitAmount: {
    textAlign: 'left',
    paddingLeft: '1rem',
    fontWeight: 'bold',
    paddingBottom: '0.5rem'
  },
  terms: {
    textAlign: 'center',
    padding: '30px'
  },
  formInputs: {
    margin: '0 auto',
    maxWidth: '32.6rem'
  },
  description: {
    textAlign: 'center',
    padding: '0 0 20px'
  }
};

const disabledBtn = {
  ...styles.submitBtn,
  backgroundColor: 'transparent',
  border: '2px solid #A8ACB8',
  color: Colors.GreyScale13
};
