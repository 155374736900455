import {
  atom,
  selector,
  DefaultValue
} from 'recoil';
import * as vodaLend from '../api/vodaLend';
import { v4 as uuidv4 } from 'uuid';
import { authDetailState, userDetails } from './authDetails';


export const requestLoginOtpState = atom({
  key: 'requestLoginOtpState',
  default: null
});

const requestLoginOtp = async (input) => {
  const response = await vodaLend.requestLoginOtp(input);
  return response?.data || {};
};

const requestLoginOtpResend = async (storedUserDetails) => {
  const response = await vodaLend.requestLoginOtpResend(storedUserDetails);
  return {
    ...response?.data || {},
    isResend: true
  };
};

export const requestOtpLogin = selector({
  key: 'requestOtpLogin',
  set: ({ get, set, reset }, userDetailsInput) => {

    if (userDetailsInput instanceof DefaultValue) {
      reset(authDetailState);
      return;
    }

    const storedUserDetails = get(userDetails);

    if (!userDetailsInput?.contact && !storedUserDetails.ref) {
      set(requestLoginOtpState, null);
      return;
    }

    set(verifyLoginOtpState, null);

    if (userDetailsInput?.contact) {
      const input = {
        ...userDetailsInput,
        ref: uuidv4()
      };
      set(userDetails, input);
      set(requestLoginOtpState, requestLoginOtp(input));
    } else {
      set(requestLoginOtpState, requestLoginOtpResend(storedUserDetails));
    }
  },
  get: async ({ get }) => {
    const requestOtpPromise = get(requestLoginOtpState);
    if (!requestOtpPromise) {
      return null;
    }
    const response = await requestOtpPromise;
    return response || {};
  }
});

export const verifyLoginOtpState = atom({
  key: 'verifyLoginOtpState',
  default: null
});

export const verifyLoginOtp = selector({
  key: 'verifyLoginOtp',
  set: ({ get, set }, code) => {

    if (!code) {
      set(verifyLoginOtpState, null);
      return;
    }

    const details = get(userDetails);

    if (!details?.ref) {
      throw new Error('NoLoginRef');
    }
    set(verifyLoginOtpState, vodaLend.submitLoginOtpResponse(details.ref, code));
  },
  get: async ({ get }) => {
    const verifyOtpPromise = get(verifyLoginOtpState);
    if (!verifyOtpPromise) {
      return null;
    }
    return await verifyOtpPromise;
  }
});
