import { useEffect } from 'react';
import * as events from './events';

const eventQueue = [];
let utag;

export const useTealium = () => {
  const processEventQueue = (utagInstance) => {
    if (!eventQueue.length) {
      return;
    }

    const event = eventQueue.shift();
    const { eventType, dataObject } = event;
    utagInstance?.track(eventType, dataObject);
    processEventQueue(utagInstance);
  };

  useEffect(() => {
    const maxAttempts = 50;
    const timeout = 500;
    let timeoutId;
    let attempts = 0;

    const loadUtag = (attempts) => {
      if (utag?.track) {
        return;
      }

      if (window.utag?.track) {
        utag = window.utag;
        processEventQueue(window.utag);
        return;
      }

      if (attempts < maxAttempts) {
        timeoutId = setTimeout(() => {
          loadUtag(++attempts);
        }, timeout);
      }
    };

    loadUtag(attempts);

    return () => clearTimeout(timeoutId);
  }, []);

  const isPageView = (event) => {
    return event.event_name?.search('page_view') >= 0;
  };

  const track = (event) => {
    const eventType = isPageView(event)
      ? 'view'
      : 'link';

    if (!utag?.track) {
      eventQueue.push({
        eventType,
        dataObject: event
      });

      return;
    }

    utag.track(eventType, event);
  };

  return { track, events };
};
