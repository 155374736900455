import React from 'react';
import PropTypes from 'prop-types';
import {
  Colors,
  Fonts
} from '../../constants';

const styles = {
  card: {
    height: 316,
    maxWidth: 260,
    marginLeft: 6,
    marginRight: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 16,
    justifyContent: 'space-between',
    border: `1px solid ${Colors.GreyScale6}`
  },
  mainImage: {
    maxWidth: '90%',
    maxHeight: 126,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
    borderRadius: 10
  },
  heading: {
    textAlign: 'center',
    fontFamily: Fonts.RobotoBold,
    fontSize: '1.12em',
    width: '95%',
    fontWeight: 400
  },
  subHeading: {
    textAlign: 'center',
    fontFamily: Fonts.Roboto,
    fontSize: '1em',
    width: '95%',
    lineHeight: '150%',
    fontWeight: 400
  },
  findOutBtn: {
    cursor: 'pointer'
  }
};

export const CrossSellOpportunities = ({
  heading,
  subHeading,
  mainImage,
  link,
  btnImage,
  onClick
}) => {

  const goTo = (link) => {
    onClick();
    window.open(link, '_blank');
  };

  return (

    <div
      style={styles.card}
      onClick={() => goTo(link)}
      onKeyDown={() => goTo(link)}
      role='button'
      tabIndex={0}
    >

      <img
        style={styles.mainImage}
        src={mainImage}
        alt={heading}
      />

      <div style={styles.heading}>
        {heading}
      </div>

      <div style={styles.subHeading}>
        {subHeading}
      </div>

      <img
        src={btnImage}
        alt='Find out more'
        style={styles.findOutBtn}
      />

    </div>
  );
};

CrossSellOpportunities.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  mainImage: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  btnImage: PropTypes.string.isRequired,
  onClick:PropTypes.func
};
