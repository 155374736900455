export const stripEmpty = (o) => Object.entries(o).reduce((accum, [key, val]) => {

  if (val && typeof val === 'object' && Object.prototype === Object.getPrototypeOf(val)) {
    accum[key] = stripEmpty(val);
  } else if (Array.isArray(val)) {
    accum[key] = val.map((v) => stripEmpty(v));
  } else if (val || val === false) {
    accum[key] = val;
  }
  return accum;
}, {});

export const replaceUndefined = (obj) => {
  if (obj === null || typeof obj === 'undefined') {
    return '';
  }
  if (obj && Array.isArray(obj)) {
    return obj.map((o) => replaceUndefined(o));
  }
  if (obj && typeof obj === 'object' && Object.prototype === Object.getPrototypeOf(obj)) {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, replaceUndefined(v)]));
  }
  return obj;
};

export const mapValues = (o) => Object.entries(o).reduce((accum, [key, value]) => {
  let mappedVal;
  if (Array.isArray(value)) {
    mappedVal = value.map((v) => mapValues(v));
  } else if (value?.value) {
    mappedVal = value.value;
  } else if (value && typeof value === 'object' && Object.prototype === Object.getPrototypeOf(value)) {
    mappedVal = mapValues(value);
  } else {
    mappedVal = value;
  }
  accum[key] = mappedVal;
  return accum;
}, {});