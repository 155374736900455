import React from 'react';
import PropTypes from 'prop-types';
import styles from './table.module.css';

export const Table = ({ heading = [], data, footer }) => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr className={styles.row}>
            { Object.values(heading).map((col) => (
              <th className={styles.cell} key={ col }>{ col }</th>
            )) }
          </tr>
        </thead>

        <tbody className={styles.body}>
          { data.map((row, rowIdx) => (
            <tr className={styles.row} key={ `row-${ rowIdx }` }>
              { Object.keys(heading).map((key) => (
                <td className={styles.cell} key={ `cell-${ rowIdx }-${ key }` }>{ row[key] }</td>
              )) }
            </tr>
          )) }
        </tbody>
        
        {footer &&
          <tfoot className={styles.footer}>
            <tr className={styles.row}>
              {Object.keys(heading).map((key) => (
                <td className={styles.cell} key={ `ft-cell-${ key }` }>{ footer[key] }</td>
              ))}
            </tr>
          </tfoot>
        }
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.object.isRequired,
  footer: PropTypes.object
};
