export const creditFacility = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: capital facility',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  increaseCapitalLimit: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: increase capital facility limit'
  }),

  viewWithdrawal: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: view withdrawal'
  }),

  transactionsViewMore: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: transactions view more'
  }),

  faqSelected: (question) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: `vodapay: business advance: faq: ${question}`
  }),

  transactionPageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: credit facility: transaction history',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  })
};

export const creditFacilityWithdrawl = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: capital facility: withdrawal review',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website'
  }),

  changeAmount: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility: withdrawal review',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    link_name: 'vodapay: business advance: opf: capital facility: withdrawal review: cover amount'
  }),

  termsAndConditions: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility: withdrawal review',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    link_name: 'vodapay: business advance: opf: capital facility: withdrawal review: terms and conditions'
  }),

  continue: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility: withdrawal review',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    link_name: 'vodapay: business advance: opf: capital facility: withdrawal review: accept and continue'
  })
};

export const limitIncrease = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: capital facility: limit increase',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website'
  }),

  submit: (amount) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility: limit increase',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    link_name: 'vodapay: business advance: opf: capital facility: limit increase: submit',
    cover_amount: amount
  })
};

export const limitIncreaseSuccess = {
  pageView: () => ({
    event_name: 'page_view, journey_end',
    page_name: 'vodapay: business advance: opf: capital facility: limit increase: submitted',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    journey_name: 'business advance: opf: capital facility'
  }),

  link: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: capital facility: limit increase: submitted',
    page_url: window.location.href,
    page_section: 'business advance',
    page_channel: 'vodapay: business advance',
    page_subdomain: 'business advance',
    page_type: 'website',
    link_name: 'vodapay: business advance: opf: capital facility: limit increase: submitted: [link text]'
  })
};
