import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useIsSuperMobile } from '../../recoil';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

export const Header = ({ children, onBackClick }) => {

  const isSuperMobile = useRecoilValue(useIsSuperMobile);

  return isSuperMobile
    ? <MobileHeader onBackClick={onBackClick}>{children}</MobileHeader>
    : <DesktopHeader>{children}</DesktopHeader>;

};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onBackClick: PropTypes.func
};
