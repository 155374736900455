import { StatusCodes } from 'http-status-codes';
import {
  axiosInstance,
  getErrorStatus
} from './axiosInstance';

export const getSecurityQuestions = async () => {

  let response;
  try {

    response = await axiosInstance.get('/verification/questions');

    if (response.status !== StatusCodes.OK) {
      throw new Error('GetSecurityQuestions');
    }

  } catch (error) {

    response = {
      data: {
        traceId: error?.response?.headers?.['x-amzn-trace-id'] || '',
        error: {
          ...(error || {}),
          status: getErrorStatus(error)
        }
      }
    };
  }
  return response.data;
};

export const isCorrectAnswers = async (data) => {

  const response = await axiosInstance.post('/verification/answers', data);

  if (response.status !== StatusCodes.OK || typeof !response.data?.isVerified !== 'boolean') {
    throw new Error('SubmitSecurityQuestionAnswers');
  }

  return response.data.isVerified;
};

/**
 * Checks if user is verified through security questions.
 * @returns {Promise<boolean>}
 */
export const isUserVerified = async () => {

  const response = await axiosInstance.get('/verification');

  if (response.status !== StatusCodes.OK || typeof !response.data?.isVerified !== 'boolean') {

    throw new Error('VerifyUser');
  }

  return response.data.isVerified;
};
