export const IndustryTypes = [
  {
    'label': 'Accounting/ Bookkeeping/ Tax Preparation',
    'value': 'Accounting/ Bookkeeping/ Tax Preparation'
  },
  {
    'label': 'Advertising Agencies',
    'value': 'Advertising Agencies'
  },
  {
    'label': 'Amusement/ Recreational Services',
    'value': 'Amusement/ Recreational Services'
  },
  {
    'label': 'Auto Sales and/or Repair',
    'value': 'Auto Sales and/or Repair'
  },
  {
    'label': 'Beauty Salons/ Barbers/ Tanning Salons/ Spas',
    'value': 'Beauty Salons/ Barbers/ Tanning Salons/ Spas'
  },
  {
    'label': 'Business Services',
    'value': 'Business Services'
  },
  {
    'label': 'Catering',
    'value': 'Catering'
  },
  {
    'label': 'Child Care',
    'value': 'Child Care'
  },
  {
    'label': 'Computer Services',
    'value': 'Computer Services'
  },
  {
    'label': 'Construction',
    'value': 'Construction'
  },
  {
    'label': 'Dentist/ Orthodontist/ Dental Lab',
    'value': 'Dentist/ Orthodontist/ Dental Lab'
  },
  {
    'label': 'Educational Services',
    'value': 'Educational Services'
  },
  {
    'label': 'Equipment Sales/ Rentals',
    'value': 'Equipment Sales/ Rentals'
  },
  {
    'label': 'Freight/ Transportation',
    'value': 'Freight/ Transportation'
  },
  {
    'label': 'Grocery/ Convenience Stores',
    'value': 'Grocery/ Convenience Stores'
  },
  {
    'label': 'Health and Fitness Facilities',
    'value': 'Health and Fitness Facilities'
  },
  {
    'label': 'Hotels/ Lodges/ B&Bs',
    'value': 'Hotels/ Lodges/ BnBs'
  },
  {
    'label': 'Housekeeping/ Janitorial Services',
    'value': 'Housekeeping/ Janitorial Servies'
  },
  {
    'label': 'Insurance Agent/ Broker/ Solicitor',
    'value': 'Insurance Agent/ Broker/ Solicitor'
  },
  {
    'label': 'Interior Design and/or Decorating',
    'value': 'Interior Design and/or Decorating'
  },
  {
    'label': 'IT and Software Services',
    'value': 'IT and Software Services'
  },
  {
    'label': 'Landscape, lawn mowing services and gardeners',
    'value': 'Landscape, lawn mowing services and gardeners'
  },
  {
    'label': 'Laundry/ Dry Cleaning Services',
    'value': 'Laundry/ Dry Cleaning Services'
  },
  {
    'label': 'Manufacturer and/or Distributor',
    'value': 'Manufacturer and/or Distributor'
  },
  {
    'label': 'Optometrist',
    'value': 'Optometrist'
  },
  {
    'label': 'Other',
    'value': 'Other'
  },
  {
    'label': 'Painting/ Handyman Services/ Home Repair',
    'value': 'Painting/ Handyman Services/ Home Repair'
  },
  {
    'label': 'Physicians',
    'value': 'Physicians'
  },
  {
    'label': 'Restaurant/ Bars',
    'value': 'Restaurant/ Bars'
  },
  {
    'label': 'Retail (Off-line)',
    'value': 'Retail (Off-line)'
  },
  {
    'label': 'Retail (On-line)',
    'value': 'Retail (On-line)'
  },
  {
    'label': 'Taxis and Delivery Services',
    'value': 'Taxis and Delivery Services'
  },
  {
    'label': 'Veterinarian',
    'value': 'Veterinarian'
  }
];