import PropTypes from 'prop-types';
import React, {
  useMemo,
  useRef
} from 'react';
import {
  Colors,
  Fonts
} from '../../constants';

const styles = {
  heading: {
    fontFamily: Fonts.Roboto,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '140%',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.20)',
    color: Colors.GreyScale10,
    textAlign: 'center'
  },
  subHeading: {
    fontFamily: Fonts.Roboto,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '140%',
    color: Colors.GreyScale10,
    textAlign: 'center'
  },
  svg: {
    transform: 'rotate(0.75turn)',
  }
};

const PERCENTAGE_LIMIT = 100;
const RADIUS = 90;
const TWO = 2;

export const CircularProgressBar = ({
  percentage,
  heading,
  subHeading,
  isClockWise,
  isPercLineRed
}) => {

  const circle1 = useRef(), circle2 = useRef();

  const determinePerc = useMemo(() => {
    if (percentage >= PERCENTAGE_LIMIT) {
      return PERCENTAGE_LIMIT;
    } else if (percentage <= 0) {
      return 0;
    } else if (percentage <= 4) {
      return 4;
    } else if (percentage >= 99 && percentage < PERCENTAGE_LIMIT) {
      return 99;
    } else {
      return percentage;
    }
  }, [percentage]);

  const circumference = RADIUS * TWO * Math.PI;
  const stroke1 = circumference - (circumference * PERCENTAGE_LIMIT) / PERCENTAGE_LIMIT;
  const stroke2 = useMemo(() => circumference + (circumference * (100 - determinePerc)) / PERCENTAGE_LIMIT, [determinePerc, circumference]);
  const commonCircleProps = {
    cx: '101',
    cy: '101.744',
    r: RADIUS,
    strokeWidth: '22',
    strokeDasharray: circumference,
    strokeLinecap: 'round'
  };

  const commonTextProps = {
    x: '100',
    y: '100',
    textAnchor: 'middle',
    fill: 'black',
    dominantBaseline: 'central'
  };

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='202'
      height='203'
      viewBox='0 0 202 203'
      fill='none'
      style={styles.svg}
    >
      <circle
        {...commonCircleProps}
        stroke={!isPercLineRed ? Colors.Primary4 : Colors.AccentBlue4}
        strokeDashoffset={stroke1}
        ref={circle1}
      />
      <circle
        {...commonCircleProps}
        stroke={isPercLineRed ? Colors.Primary4 : Colors.AccentBlue4}
        strokeDashoffset={stroke2}
        ref={circle2}
      />
      {heading && (
        <text
          {...commonTextProps}
          transform='rotate(90, 105, 105)'
          style={styles.heading}
        >
          {heading}
        </text>
      )}
      {subHeading && (
        <text
          {...commonTextProps}
          transform='rotate(90, 95, 95)'
          style={styles.subHeading}
        >
          {subHeading}
        </text>
      )}
    </svg>
  );
};

CircularProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  isClockWise: PropTypes.bool,
  isPercLineRed: PropTypes.bool
};