import dayjs from 'dayjs';
import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => await vodaLend.getOffers();

const offersDefault = selector({
  key: 'offersDetailsDefault',
  get: async () => await fetchState()
});

const offersState = atom({
  key: 'offersState',
  default: offersDefault
});

// In future, when more partners are available and multiple offers can be presented to the customer
// this should be replaced with a selectorFamily, similar to applications
export const offer = selector({
  key: 'offer',
  get: async ({ get }) => {
    const offers = await get(offersState);
    if (!Array.isArray(offers.offers) || offers.offers.length < 1) {
      console.warn('No offers available', offers);
      return null;
    }
    if (offers.offers.length > 1) {
      console.warn('Multiple offers available. Selecting only first.', offers.offers);
    }
    return offers.offers[0];
  }
});

export const offerRepaymentSched = selector({
  key: 'offerRepaymentSched',
  get: async ({ get }) => {
    const data = await get(offer);
    const sched = data?.repaymentSchedule || [];
    return sched.map((s) => ({
      displayDate: dayjs(s.date).format('DD MMM YYYY'),
      capital: s.principalAmount,
      cost: s.costAmount,
      repayment: s.totalAmount
    }));
  }
});
