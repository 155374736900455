import React, { useEffect } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { findErrorState } from '../recoil/findErrorState';
import { useModal } from '../components/modalController/useModal';
import { StatusCodes } from 'http-status-codes';
import { Button } from '@vfs-digital-channels/ns-react-components';
import { ErrorModalSection } from '../components';
import { useTealium } from '../tealium/useTealium';
import { useLocation } from 'react-router-dom';

/**
 * This hook is used to handle the error state of a Recoil state.
 * @param states
 * @param modalOnCloseCallback
 * @returns {Loadable<any>}
 */
export const useFindErrorOnState = (states = [], modalOnCloseCallback = () => {}) => {
  const [openModal, closeModal] = useModal();
  const errorState = findErrorState(states);
  const loadable = useRecoilValueLoadable(errorState);
  const { track, events } = useTealium();
  const location = useLocation();

  const handleCloseModal = () => {
    closeModal();

    if (typeof modalOnCloseCallback === 'function') {
      modalOnCloseCallback(loadable);
    }
  };

  useEffect(() => {
    if (!loadable?.contents || loadable?.state !== 'hasValue') {
      return;
    }

    const errorType = loadable.contents.error.status < StatusCodes.INTERNAL_SERVER_ERROR
      ? 'user'
      : 'system';

    track(events.error(
      'event',
      location.pathname,
      loadable.contents.error.message,
      loadable.contents.error.status,
      errorType
    ));

    if (loadable.contents.error.status < StatusCodes.INTERNAL_SERVER_ERROR) {
      openModal({
        title: 'Error',
        children: <div dangerouslySetInnerHTML={ {__html: loadable.contents.error.message} }/>,
        actions: [<Button key="close" onClick={ handleCloseModal }>Close</Button>],
        type: 'error',
        onCloseModal: handleCloseModal
      });

      return;
    }

    openModal({
      title: 'Error',
      children: <ErrorModalSection
        traceId={ loadable.contents.error.traceId }
        error={ loadable.contents.error }
        onCloseErrorModal={ handleCloseModal }
      />,
      type: 'error',
      onCloseModal: handleCloseModal
    });
  }, [loadable]);

  return loadable;
};
