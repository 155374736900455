import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => {
  const response = await vodaLend.getBusinessDetails();
  return response.data;
};

const updateState = async (inputData) => {
  const response = await vodaLend.setBusinessDetails(inputData);
  return response.data;
};

const businessDetailsDefault = selector({
  key: 'businessDetailsDefault',
  get: async () => await fetchState()
});

export const businessDetailsState = atom({
  key: 'businessDetailsState',
  default: businessDetailsDefault
});

export const businessDetailsSubmit = selector({
  key: 'businessDetailsSubmit',
  get: async ({ get }) => await get(businessDetailsState),
  set: ({ get, set }, inputData) => {

    const cur = get(businessDetailsState) || {};

    if (inputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...inputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(businessDetailsState, data);
      return;
    }

    set(businessDetailsState, updateState({
      ...cur,
      ...inputData
    }));
  }
});
