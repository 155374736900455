export const Colors = {

  GreyScale1: '#FFFFFF',
  GreyScale2: '#F5F6F7',
  GreyScale3: '#EBEDEF',
  GreyScale4: '#D8DBE0',
  GreyScale5: '#C9CCD4',
  GreyScale6: '#A8ACB8',
  GreyScale7: '#8A8D9C',
  GreyScale8: '#6D6F81',
  GreyScale9: '#525365',
  GreyScale10: '#393949',
  GreyScale11: '#222739',
  GreyScale12: '#141827',
  GreyScale13: '#08081B',

  Primary1: '#FACCCC',
  Primary2: '#F59999',
  Primary3: '#F06666',
  Primary4: '#E60000',
  Primary5: '#BD0000',
  Primary6: '#990000',

  AccentBlue1: '#CCEFF4',
  AccentBlue2: '#99DFEA',
  AccentBlue3: '#66D0DF',
  AccentBlue4: '#00B0CA',
  AccentBlue5: '#007C92',
  AccentBlue6: '#005765',
  AccentBlue7: '#003D47',

  AccentPurple1: '#EBD4EC',
  AccentPurple2: '#D7AAD9',
  AccentPurple3: '#C47FC6',
  AccentPurple4: '#9C2AA0',
  AccentPurple5: '#5E2750',

  StatusGreen1: '#EEFFFC',
  StatusGreen2: '#CCFFF6',
  StatusGreen3: '#88FFEA',
  StatusGreen4: '#66FFE4',
  StatusGreen5: '#44FFDE',
  StatusGreen6: '#00AA8C',
  StatusGreen7: '#008870',
  StatusGreen8: '#006654',
  StatusGreen9: '#004438',
  StatusGreen10: '#428600',

  StatusYellow1: '#FFF5CC',
  StatusYellow2: '#FFEA99',
  StatusYellow3: '#FEE066',
  StatusYellow4: '#FECB00',
  StatusYellow5: '#EB9700'
};

export const Fonts = {
  Roboto: 'Roboto',
  RobotoBold: 'RobotoBold',
  RobotoLight: 'RobotoLight',
  RobotoMedium: 'RobotoMedium',
  RobotoBlack: 'RobotoBlack'
};

export const BorderRadius = {
  LeftRound: '30px 0px 0px 30px'
};

export const CommonHeight = {
  NavigationFooterHeightDesktop: 80,
  NavigationFooterHeightMobile: 77,
  MinPageHeightWithHeaderWithoutFooter: '91vh',
  MinPageHeightWithHeaderFooter: '83vh'
};

export const FormContainer = {
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  alignItems: 'center',
  height: 'inherit',
  fontFamily: Fonts.Roboto
};

export const FormFieldWrapper = {
  width: '85%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '6px 0 0 0'
};

export const FormField = {
  display: 'flex',
  justifyContent: 'center',
  width: '32.6rem',
  maxWidth: '100%'
};

export const CustomFormField = {
  ...FormField,
  marginBottom: 10
};

export const FormFieldError = {
  ...FormField,
  marginBottom: 30
};

export const DualToggleFormField = {
  display: 'flex',
  margin: '40px 0 10px 0',
  width: '32.6rem',
  maxWidth: '100%',
  justifyContent: 'space-between'
};

export const PagerField = {
  display: 'flex',
  flexDirection: 'row',
  width: '200px',
  margin: '45px 0 80px 0',
  justifyContent: 'space-between',
  alignContent: 'center'
};

export const AccordionStyle = {
  accordionHeaderLeft: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 16,
    alignItems: 'center',
    flexGrow: 1
  },
  accordionHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    fontSize: 14,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 10
  },
  accordionTitle: {
    fontFamily: Fonts.RobotoMedium,
    fontSize: '1.125em',
    lineHeight: '24px',
    width: 'inherit',
    marginBottom: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  accordionRightDetails: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '95%'
  },
  accordionChildren: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  titleAppIdKey: {
    fontFamily: Fonts.Roboto,
    fontSize: '1.125em',
    lineHeight: '140%',
    color: Colors.GreyScale7
  },
  titleAppIdValue: {
    fontFamily: Fonts.Roboto,
    fontSize: '1.125em',
    lineHeight: '140%',
    color: Colors.GreyScale13,
    marginLeft: '1%',
    marginRight: '5%'
  },
  pendingStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    height: '2em',
    color: Colors.GreyScale1,
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: '1.125em',
    cursor: 'pointer'
  },
  statusEllipse: {
    background: Colors.GreyScale1,
    borderRadius: '50%',
    width: 8,
    height: 8,
    marginRight: 11
  },
  statusTxt: {
    fontFamily: Fonts.Roboto,
    fontSize: '1em'
  },
  ArrowImg: {
    width: 15,
    height: 20,
    cursor: 'pointer',
    marginLeft: 15
  },
  deleteImg: {
    width: 20,
    cursor: 'pointer'
  }
};
