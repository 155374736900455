import * as yup from 'yup';
import { identityValidationSchema } from '..';
import {
  isValidSaIdGender,
  isValidSaIdDob,
  msisdnRegex,
  emailRegex,
  namesRegex,
  minimumAge
} from '../../constants';

export const personalDetailsValidationSchema = yup.object().shape({
  positionInCompany: yup.mixed().test('positionInCompany', 'Position in the company is a required field', (item) => !!item?.value),
  title: yup.mixed().test('title', 'Title is a required field', (item) => !!item?.value),
  gender: yup.mixed().test('gender', 'Gender does not matches with SAID', (item, context) => {

    const { idNumber, idType } = context?.parent || {};
    const genderValue = item?.value;

    if (idType === 'id' && genderValue && idNumber) {

      return !!genderValue && isValidSaIdGender(idNumber, genderValue);
    }
    return true;
  }).required('Gender is a required field'),
  firstName: yup.string().trim().matches(namesRegex,'Name should contain letters only').min(1, 'Name should be of minimum 1 characters length').max(50, 'Name should be of maximum 50 characters length').required('Name is a required field'),
  surname: yup.string().trim().matches(namesRegex,'Surname should contain letters only').min(1, 'Surname should be of minimum 1 characters length').max(50, 'Surname should be of maximum 50 characters length').required('Surname is a required field'),
  email: yup.string().matches(emailRegex, 'Email is not valid').required('Email is required'),
  msisdn: yup.string().matches(msisdnRegex, 'Mobile number is not valid').required('Mobile number is required'),
  dateOfBirth: yup.date().max(new Date(), 'Date of birth should not be date in future')
    .max(new Date(Date.now() - minimumAge), 'You must be at least 18 years')
    .test('dateOfBirth', 'Date of birth does not matches with SAID', (item, context) => {

      const { idNumber, idType } = context?.parent || {};

      if (idType === 'id' && item && idNumber) {

        return !!item && isValidSaIdDob(idNumber, item);
      }
      return true;
    })
    .required('Date of Birth is a required field')
}).concat(identityValidationSchema);