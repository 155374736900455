export const registration = {
  pageView: () => ({
    event_name: 'page_view, journey_start',
    page_name: 'vodapay: business advance: opf: get started',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    journey_name: 'business advance: opf: new application'
  }),

  formStart: () => ({
    event_name: 'form_start',
    page_name: 'vodapay: business advance: opf: get started',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: get started'
  }),

  alreadyRegistered: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: get started',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: get started',
    link_name: 'vodapay: business advance: opf: get started: login'
  }),

  getStarted: () => ({
    event_name: 'ui_interaction, form_end',
    page_name: 'vodapay: business advance: opf: get started',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: get started',
    link_id: 'vodapay: business advance: opf: get started'
  }),

  TermConditionPrivacyPolicy: (clickedPage) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: get started',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: get started',
    link_name: `vodapay: business advance: opf: get started: ${clickedPage}`
  })

};

export const registrationSuccess = {
  pageView: () => ({
    event_name: 'page_view, journey_end, business_app_success_opf',
    page_name: 'vodapay: business advance: opf: applications success',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    journey_name: 'business advance: opf: new application'
  }),

  alsoLike: (selectedName) => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: applications success',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: `vodapay: business advance: opf: also like: ${selectedName}`
  })
};
