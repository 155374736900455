import React from 'react';
import styles from '../../withdrawal.module.css';
import { Colors, CommonDetails, Fonts } from '../../../../../constants';
import { Anchor } from '../../../../../components/anchor/anchor';
import { creditFacilityDetailsQuery } from '../../../../../recoil';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../../../helpers';

export const ConfirmWithdrawalModal = ({
  amtToWithdraw
}) => {

  const creditFacilityDetails = useRecoilValue(creditFacilityDetailsQuery);
  const amtAfterWithdraw = formatCurrency(creditFacilityDetails?.amountAvailable - amtToWithdraw);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', fontSize: 12, fontFamily: Fonts.Roboto, color: Colors.GreyScale13 }}>Withdrawal amount</div>
        <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, fontFamily: Fonts.Roboto, color: Colors.GreyScale13 }}>{formatCurrency(amtToWithdraw)}</div>
      </div>

      <div style={{ height: 1, background: Colors.GreyScale4, marginTop: 6, marginBottom: 6, width: '100%' }} />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', fontSize: 12, fontFamily: Fonts.Roboto, color: Colors.GreyScale13 }}>Amount available after withdrawal</div>
        <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, fontFamily: Fonts.Roboto, color: Colors.GreyScale13 }}>{amtAfterWithdraw}</div>
      </div>

      <div style={{ fontSize: 14, marginTop: 15, maxWidth: 360 }}>By clicking ‘Confirm’, you agree to the Business Term Advance & Vodacom <Anchor className={styles.url} href={CommonDetails.vodalendTermsConditions} title="Business Term Advance and Vodacom Terms and Conditions">terms and conditions</Anchor>.</div>

    </div>
  );
};

ConfirmWithdrawalModal.propTypes = {
  amtToWithdraw: PropTypes.number
};