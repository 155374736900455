import React from 'react';
import { userEmail } from '../../../../../recoil';
import { useRecoilValue } from 'recoil';
import { Fonts } from '../../../../../constants';
import styles from './successWithdrawalModal.module.css'
import { Typography } from '../../../../../components/typography/typography';

export const SuccessWithdrawalModal = () => {

  const email = useRecoilValue(userEmail);

  return (
    <div className={styles.main}>
      <Typography align={'center'} weight={'medium'} size={'h3'} family={'medium'} className={styles.heading}>
        We’ve received your withdrawal request
      </Typography>
      <Typography align={'center'} size={'b2'} className={styles.confirmation}>
        A confirmation email has been sent to: {email}
      </Typography>
    </div>
  );
};
