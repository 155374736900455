import { Header, NavBar } from '../components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useIsMobile } from '../recoil';
import { Fonts } from '../constants';
import PropTypes from 'prop-types';

const styles = {
  profileMgnt: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: Fonts.Roboto
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  containerLeft: {
    width: '100%',
    maxWidth: 343,
    marginTop: 38,
    height: 'inherit'
  },
  containerRight: {
    width: '100%'
  }
};

export const DefaultLayout = ({children}) => {
  const isMobile = useRecoilValue(useIsMobile);

  return (
    <div style={ styles.profileMgnt }>
      <Header />
      <div style={ styles.contentSection }>
        { isMobile || <div style={ styles.containerLeft }>
          <NavBar/>
        </div> }
        <div style={ styles.containerRight }>
          {children}
        </div>
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node
};
