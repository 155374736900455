import * as yup from 'yup';
import {
  emailRegex,
  namesRegex,
  msisdnRegex
} from '../../constants';


export const directorValidationSchema = yup.object().shape({
  directors: yup
    .array().of(
      yup.object().shape({
        positionInCompany: yup
          .mixed()
          .test('positionInCompany', 'Position in the company is a required field', (item) => !!item?.value),
        title: yup
          .mixed()
          .test('title', 'Title is a required field', (item) => !!item?.value),
        firstName: yup
          .string()
          .trim()
          .required('Name is a required field')
          .matches(namesRegex, 'Name should contain letters only')
          .min(1, 'Name must be a valid Name consisting of at least 1 character'),
        surname: yup
          .string()
          .trim()
          .required('Surname is a required field')
          .matches(namesRegex, 'Surname should contain letters only')
          .min(1, 'Surname must be a valid Surname consisting of at least 1 character'),
        msisdn: yup
          .string()
          .required('Mobile Number is a required field')
          .matches(msisdnRegex, 'Mobile Number must be a valid mobile number'),
        email: yup
          .string()
          .required('Email Address is a required field')
          .matches(emailRegex, 'Email Address must be a valid email address')
      })
    ).test('directors', 'atleast 1 director is required', (item) => Array.isArray(item) && item.length > 0)
});

export const validateDirectorEmails = (values) => {

  const errors = {};

  if (Array.isArray(values.directors) && values.directors.length > 0) {

    const emailCounts = values.directors.reduce((acc, dir, index) => {
      if (dir.email) {
        if (!acc[dir.email]) {
          acc[dir.email] = [];
        }
        acc[dir.email].push(index);
      }
      return acc;
    }, {});

    Object.values(emailCounts).forEach(indices => {
      if (indices.length > 1) {
        indices.forEach(index => {
          if (!errors.directors) {
            errors.directors = [];
          }
          if (!errors.directors[index]) {
            errors.directors[index] = {};
          }
          errors.directors[index].email = 'A company director already has this email address';
        });
      }
    });
  }

  return errors;
};