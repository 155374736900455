import React, { useEffect, useState } from 'react';

import {
  Header,
  NavBar
} from '../../components';

import {
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  applicationStatuses,
  useIsMobile
} from '../../recoil';

import {
  useRecoilValue
} from 'recoil';

import {
  Fonts
} from '../../constants';
import { StaticRoutes } from '../../Router';

const styles = {
  pageTitle: {
    fontWeight: 700,
    fontSize: '18px',
    textAlign: 'center'
  },
  profileMgnt: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: Fonts.Roboto
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  containerLeft: {
    width: '100%',
    maxWidth: 343,
    marginTop: 38,
    height: 'inherit'
  },
  containerRight: {
    width: 'calc(100% - 343px)',
    minWidth: '70%'
  }
};

const pageTitles = {
  '/accounts/capital-facility': 'Capital Facility',
  '/accounts/advance': 'Advance'
};

export const Accounts = () => {
  const isMobile = useRecoilValue(useIsMobile);
  const appStates = useRecoilValue(applicationStatuses);
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState(null);

  useEffect(() => {
    if (location.pathname === '/accounts' || location.pathname === '/accounts/') {
      navigate(StaticRoutes.applications);
      return;
    }

    if (!appStates?.isCreditFacilityCreated && !appStates?.isAdvanceActive) {
      navigate(StaticRoutes.applications);
      return;
    }

  }, [navigate, appStates, location.pathname]);

  useEffect(() => {
    setPageTitle(pageTitles[location.pathname] || null);
  }, [location]);

  const handleBackClick = () =>{
    navigate(-1);
  };

  return (
    <div style={styles.profileMgnt}>
      <Header onBackClick={handleBackClick}>
        { isMobile &&
          <div style={styles.pageTitle}>{ pageTitle }</div>
        }
      </Header>
      <div style={styles.contentSection}>
        {isMobile || <div style={styles.containerLeft}>
          <NavBar />
        </div>}
        <div style={isMobile ? { ...styles.containerRight, width: '100%' } : styles.containerRight}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};