import { atom, selector, selectorFamily } from 'recoil';
import * as vodaLend from '../api/vodaLend';
import dayjs from 'dayjs';

const fetchStateCapitalFacility = async () => vodaLend.getCapitalFacility();

const capitalFacilityDefault = selector({
  key: 'capitalFacilityDefault',
  get: async () => await fetchStateCapitalFacility()
});

const capitalFacilityState = atom({
  key: 'capitalFacilityState',
  default: capitalFacilityDefault
});

export const creditFacilityDetailsQuery = selector({
  key: 'creditFacilityDetailsQuery',
  get: async ({ get }) => get(capitalFacilityState),
  set: ({ set }, inputData) => set(capitalFacilityState, inputData)
});

export const withdrawalAmountState = atom({
  key: 'withdrawalAmountState',
  default: 0
});

export const amountToWithdraw = selector({
  key: 'amountToWithdraw',
  get: async ({ get }) => {
    const resp = await get(capitalFacilityState)?.amountAvailable;
    if (resp) {
      return resp;
    }
    return 0;
  },
  set: ({ set }, inputData) => set(withdrawalAmountState, inputData)
});

const MIN_WITHDRAWAL_AMT = 1000;

export const getWithdrawalQuoteQuery = selectorFamily({
  key: 'getWithdrawalQuoteQuery',
  get: (amount) => async () => {
    if (!amount || typeof amount !== 'number' || amount < MIN_WITHDRAWAL_AMT) {
      return null;
    }
    const data = await vodaLend.getWithdrawalQuote(amount);
    if (!data) {
      return 'withdrawalInProgress';
    }
    const sched = (data?.repaymentSchedule || []).map((s) => ({
      displayDate: dayjs(s.date).format('DD MMM YYYY'),
      capital: s.principalAmount,
      cost: s.costAmount,
      repayment: s.totalAmount
    }));

    data.repaymentSchedule = sched;

    return data;
  }
});

export const rcfTransactionsQuery = selector({
  key: 'rcfTransactionsQuery',
  get: async () => {
    return await vodaLend.getTransactions();
  }
});
