export const otp = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: otp',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  resend: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: otp',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: resend'
  }),

  continue: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: otp',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: continue'
  })
};
