
import React, {
  useMemo
} from 'react';

import {
  Fonts,
  Colors,
  BorderRadius
} from '../../constants';

import {
  EllipseRed,
  GreyTickSquareBorder,
  HandCash,
  UserRedIcon,
  UserIcon,
  RedTickSquareBorder,
  GreyFundingDoc,
  RedFundingDoc,
  RedCoinsStack,
  GreyCoinsStack,
  RCFIconGrey,
  RCFIconRed,
  Logout
} from '../../assets';

import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import { ApplicationRoutes, StaticRoutes } from '../../Router';
import { applicationStatuses, useIsMobile, useIsMobileLandscape } from '../../recoil';
import { useRecoilValue } from 'recoil';

const styles = {
  profileNav: {
    display: 'flex',
    width: '100%',
    height: '100%',
    fontFamily: Fonts.Roboto,
    alignContent: 'flex-end',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxWidth: 343
  },
  steps: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 310,
    flexDirection: 'row',
    marginTop: 16,
    cursor: 'pointer',
    borderRadius: BorderRadius.LeftRound
  },
  iconDiv: {
    marginLeft: 17,
    marginRight: 28,
    width: 15,
    display: 'flex',
    justifyContent: 'center'
  },
  stepsWithChild: {
    flexDirection: 'column'
  },
  stepsWithChildInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 50,
    borderRadius: BorderRadius.LeftRound,
    background: Colors.GreyScale2
  },
  stepsChildInnerTitle: {
    marginTop: 16,
    cursor: 'auto',
    color: Colors.Primary4,
    fontFamily: Fonts.RobotoMedium
  },
  activeTitle: {
    color: Colors.Primary4,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '140%',
    fontFamily: Fonts.RobotoMedium
  }
};

const allItems = {
  profile: {
    title: 'profile',
    path: '/profile'
  },
  applications: {
    title: 'applications',
    path: '/applications'
  },
  quote: {
    title: 'quote',
    path: '/quote'
  },
  quoteVerify: {
    title: 'quote',
    path: '/quote/verify'
  },
  accountsBta: {
    title: 'accountsBta',
    path: '/accounts/bta'
  },
  accountsRcf: {
    title: 'accountsRcf',
    path: '/accounts/capital-facility'
  },
  accountsRcfWithdrawal: {
    title: 'accountsRcf',
    path: '/accounts/capital-facility/withdrawal'
  }
};

const ProfileSectionItems = {
  updatePersonalDetails: {
    title: 'Personal details',
    path: '/profile/personalDetails'
  },
  updateAddressDetails: {
    title: 'Address details',
    path: '/profile/personalAddress'
  },
  updateBusinessDetails: {
    title: 'Business details',
    path: '/profile/businessDetails'
  },
  updateBusinessAddressDetails: {
    title: 'Business address details',
    path: '/profile/businessAddress'
  },
  updateDirectoryDetails: {
    title: 'Director details',
    path: '/profile/directorDetails'
  },
  updateBankDetails: {
    title: 'Bank details',
    path: '/profile/bankDetails'
  },
  updateDocumentDetails: {
    title: 'Documents',
    path: '/profile/documentDetails'
  }
};

export const NavBar = () => {

  const appStates = useRecoilValue(applicationStatuses);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useRecoilValue(useIsMobile);
  const isMobileLandscape = useRecoilValue(useIsMobileLandscape);

  const profileCurrentPage = useMemo(() => Object.values(ProfileSectionItems).find((p) => p.path === location.pathname)?.title || '', [location]);

  const currentPage = useMemo(() => Object.values(allItems).find((p) => p.path === location.pathname)?.title || '', [location]);

  const navigateTo = (path) =>
    navigate(path, {
      state: {
        returnPath: location.pathname
      }
    });

  const Applications = () => (
    <div
      style={(currentPage === 'applications' || currentPage === '') && !profileCurrentPage ? { ...styles.steps, ...styles.stepsWithChildInner } : styles.steps}
      onClick={() => navigateTo(StaticRoutes.applications)}
      onKeyDown={() => navigateTo(StaticRoutes.applications)}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={(currentPage === 'applications' || currentPage === '') && !profileCurrentPage ? RedTickSquareBorder : GreyTickSquareBorder} alt='ApplicationStatus' />
      </div>
      <div style={(currentPage === 'applications' || currentPage === '') && !profileCurrentPage ? styles.activeTitle : {}}>
        My applications
      </div>
    </div>
  );

  const ApplyNow = () => (
    <div
      style={currentPage === 'applyNow' ? { ...styles.steps, ...styles.stepsWithChildInner } : styles.steps}
      onClick={() => navigateTo(ApplicationRoutes.personalDetails)}
      onKeyDown={() => navigateTo(ApplicationRoutes.personalDetails)}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={HandCash} alt='Cash' />
      </div>
      <div style={currentPage === 'applyNow' ? styles.activeTitle : {}}>
        Apply now
      </div>
    </div>
  );

  const CreditFacility = () => (
    <div
      style={currentPage === 'accountsRcf' ? { ...styles.steps, ...styles.stepsWithChildInner } : styles.steps}
      onClick={() => navigateTo('/accounts/capital-facility')}
      onKeyDown={() => navigateTo('/accounts/capital-facility')}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={currentPage === 'accountsRcf' ? RCFIconRed : RCFIconGrey} alt='Cash' />
      </div>
      <div style={currentPage === 'accountsRcf' ? styles.activeTitle : {}}>
        Capital facility
      </div>
    </div>
  );

  const FundingQuote = () => (
    <div
      style={currentPage === 'quote' ? { ...styles.steps, ...styles.stepsWithChildInner } : styles.steps}
      onClick={() => navigateTo(StaticRoutes.fundingQuote)}
      onKeyDown={() => navigateTo(StaticRoutes.fundingQuote)}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={currentPage === 'quote' ? RedFundingDoc : GreyFundingDoc} alt='Document' />
      </div>
      <div style={currentPage === 'quote' ? styles.activeTitle : {}}>
        Funding Quote
      </div>
    </div>
  );

  const BusinessTermAdvance = () => (
    <div
      style={currentPage === 'accountsBta' ? { ...styles.steps, ...styles.stepsWithChildInner } : styles.steps}
      onClick={() => navigateTo('/accounts/bta')}
      onKeyDown={() => navigateTo('/accounts/bta')}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={currentPage === 'accountsBta' ? RedCoinsStack : GreyCoinsStack} alt='CoinsStack' />
      </div>
      <div style={currentPage === 'accountsBta' ? styles.activeTitle : {}}>
        Business Term Advance
      </div>
    </div>
  );

  const Profile = () => (
    <div style={{ ...styles.steps, ...styles.stepsWithChild }}    >
      <div
        style={(currentPage === 'profile' || profileCurrentPage) ? styles.stepsWithChildInner : { ...styles.stepsWithChildInner, background: 'transparent' }}
        onClick={() => navigateTo(StaticRoutes.profile)}
        onKeyDown={() => navigateTo(StaticRoutes.profile)}
        role='presentation'
      >
        <div style={styles.iconDiv}>
          <img src={(currentPage === 'profile' || profileCurrentPage) ? UserRedIcon : UserIcon} alt='UserIcon' />
        </div>
        <div style={(currentPage === 'profile' || profileCurrentPage) ? styles.activeTitle : {}}>
          My profile
        </div>
      </div>

      {profileCurrentPage && (
        <div style={{ ...styles.stepsWithChildInner, ...styles.stepsChildInnerTitle }}>
          <div style={styles.iconDiv}>
            <img
              src={EllipseRed}
              alt='EllipseRed'
            />
          </div>
          <div style={currentPage === 'fundingQuote' ? styles.activeTitle : {}}>
            {profileCurrentPage}
          </div>
        </div>
      )}
    </div>
  );

  const LogOut = () => (
    <div
      style={styles.steps}
      onClick={() => navigateTo(StaticRoutes.logout)}
      onKeyDown={() => navigateTo(StaticRoutes.logout)}
      role='presentation'
    >
      <div style={styles.iconDiv}>
        <img src={Logout} alt='logout button' />
      </div>
      <div >
        Logout
      </div>
    </div>
  );

  return (
    <div style={styles.profileNav}>

      <Applications />

      {appStates?.canApply && (<ApplyNow />)}

      {appStates?.isQuoteAvailable && (<FundingQuote />)}

      {appStates?.isCreditFacilityCreated && (<CreditFacility />)}

      {appStates?.isAdvanceActive && (<BusinessTermAdvance />)}

      <Profile />

      {(isMobile || isMobileLandscape) && (<LogOut />)}

    </div>
  );
};