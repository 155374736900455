import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import storage from '../../helpers/storage';
import { StaticRoutes } from '../../Router';

let authorization = null;
let coreConfig = null;

const STORE_TOKEN_KEY = 'loginState:accessToken';

export const axiosInstance = axios.create();

export const getCoreConfig = async (reinitialise) => {

  if (!coreConfig || !!reinitialise) {

    try {
      const resp = await axios.get('/config.json');
      coreConfig = resp.data;
    } catch (err) {
      console.error('Error occurred while fetching core config', err);
      coreConfig = null;
      throw new Error('GetCoreConfig');
    }
  }

  return coreConfig;
};

axiosInstance.interceptors.request.use(async (cfg) => {

  const coreConfig = await getCoreConfig();

  if (!coreConfig?.opfApiBase) {
    throw new Error('AxiosNoBaseUrl');
  }

  let headers = {
    ...cfg.headers
  };

  const auth = await getAuthorization();

  if (coreConfig?.accessTokenOverride) {
    headers = {
      ...headers,
      Authorization: `Bearer ${coreConfig.accessTokenOverride}`
    };
  } else if (auth && new Date().getTime() <= (auth?.expiry || 0)) {
    headers = {
      ...headers,
      Authorization: `Bearer ${auth.token}`
    };
  }

  return {
    ...cfg,
    baseURL: coreConfig.opfApiBase,
    headers
  };
});

axiosInstance.interceptors.response.use((response) => response, async (err) => {
  const errorStatus = err.status || err.response?.status || StatusCodes.INTERNAL_SERVER_ERROR;
  if ([StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN].includes(errorStatus)) {
    await storage.clear()
    window.location.href = StaticRoutes.login;
    throw new Error('VlUnauthorized');
  }
  throw err;
});

export const getAuthorization = async () => {
  if (!authorization) {
    authorization = await storage.getItem(STORE_TOKEN_KEY).catch(() => null);
  }
  return authorization;
};

export const setAuthorization = async (auth) => {
  if (auth && auth.token && auth.ttl && auth.timestamp) {
    authorization = {
      ...auth,
      token: auth.token,
      expiry: auth.timestamp + auth.ttl
    };
  } else if (!auth) {
    authorization = null;
  }
  await storage.setItem(STORE_TOKEN_KEY, authorization, authorization?.ttl || 0).catch(() => null);
  return authorization;
};

export const getErrorStatus = (err) => err?.status || err?.response?.status || StatusCodes.INTERNAL_SERVER_ERROR;
