/**
 * Returns first validation error message if one exists
 * @param {FormikErrors<FormikValues>} errors
 * @param {FormikTouched<FormikValues>} touched
 * @returns {string|null}
 */
export const findFirstValidationError = (errors, touched) => {
  const errorKeys = Object.keys(errors);
  const touchedKeys = Object.keys(touched);

  // eslint-disable-next-line no-loops/no-loops
  for(let i = 0; i <= touchedKeys.length; i++) {
    const field = touchedKeys[i];

    if (errorKeys.find(error => error === field)) {
      return errors[field];
    }
  }

  return null;
};
