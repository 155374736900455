import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from '../../constants';
import { Minus, AddIcon, RangePickerThumb } from '../../assets';
import { formatCurrency } from '../../helpers';

// excess height to improve interactive area / accessibility
const height = '37px';
const thumbHeight = 43;
const thumbWidth = 38.3;
const trackHeight = '17px';

// colours
const upperColor = '#D3D3D3';
const lowerColor = `${Colors.Primary4}`;
// const thumbColor = 'linear-gradient(269.28deg, #C90000 0.6%, #CC0303 99.38%)';
// const thumbHoverColor = 'transparent';
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

// Webkit cannot style progress so we fake it with a long shadow on the thumb element
const makeLongShadow = (color, size) => {
  let i = 18;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < window.innerWidth; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

const RangeInput = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  max-width: 100%;
  width: 100%;
  margin: 0;
  height: ${height};
  cursor: pointer;
  border-radius: 30px;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
    border-radius: 30px;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbWidth}px;
    background: url(${RangePickerThumb});
    border-radius: 100%;
    border: 0;
    marign-left: -10;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow(upperColor, '-12.7px')};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbWidth};
    background: url(${RangePickerThumb});
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;

    background-size: cover;
    min-width: 38.3px;
    min-height: 43px;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbWidth};
    background: url(${RangePickerThumb});
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }
`;

const styles = {
  parent: {
    width: '100%'
  },
  limitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px'
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  sliderMinusButton: {
    margin: '0 20px 0 0',
    cursor: 'pointer'
  },
  sliderPlusButton: {
    margin: '0 0 0 20px',
    cursor: 'pointer'
  }

};

export const RangeSlider = ({ min, max, step, renderFun, value, onChange, displayMiddleSliderValue, ...props }) => {

  const [sliderVal, setSliderVal] = React.useState(value);
  React.useEffect(() => {
    onChange && onChange(sliderVal);
  }, [sliderVal]);
  const render = renderFun ? renderFun : (v) => `${v}`;

  const handleSliderMinusValue = () => {
    setSliderVal(sliderVal - step < min ? min : sliderVal - step);
  };

  const handleSliderPlusValue = () => {
    setSliderVal(sliderVal + step > max ? max : sliderVal + step);
  };

  const sliderChange = (e) => {
    const val = +e.target.value;
    setSliderVal(val < min ? min : val > max ? max : val);
  };

  return (
    <div style={styles.parent}>
      <div style={styles.sliderContainer}>
        <div
          style={styles.sliderMinusButton}
          onClick={handleSliderMinusValue}
          onKeyDown={handleSliderMinusValue}
          role='presentation'
        >
          <img src={Minus} alt="Slider Minus Icon" />
        </div>
        <RangeInput
          type='range'
          min={min}
          max={max}
          step={step}
          value={sliderVal}
          {...props}
          onChange={sliderChange}
        />
        <div
          style={styles.sliderPlusButton}
          onClick={handleSliderPlusValue}
          onKeyDown={handleSliderPlusValue}
          role='presentation'
        >
          <img src={AddIcon} alt="Slider Plus Icon" />
        </div>
      </div>
      <div style={styles.limitContainer}>
        <div>{render(formatCurrency(min))}</div>
        {displayMiddleSliderValue && (<div>{render(formatCurrency(sliderVal))}</div>)}
        <div>{render(formatCurrency(max))}</div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number,
  step: PropTypes.number.isRequired,
  renderFun: PropTypes.func,
  onChange: PropTypes.func,
  displayMiddleSliderValue: PropTypes.bool
};

RangeSlider.displayName = 'RangeSlider';