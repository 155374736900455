import React, {
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AccountsRoutes, ApplicationRoutes, StaticRoutes } from '../../Router';
import {
  CrossSellCarousel,
  CrossSellOpportunities,
  Footer,
  Header,
  NavBar,
  SupportPopup
} from '../../components';
import {
  CrossSellCarouselData,
  appStatuses
} from '../../constants';
import { useIsMobile, useShowToast } from '../../recoil';
import { applicationsList } from '../../recoil/applications';
import { useTealium } from '../../tealium/useTealium';
import { ApplicationAccordion } from './ApplicationAccordion';
import styles from './applications.module.css';


export const Applications = () => {

  const isMobile = useRecoilValue(useIsMobile);
  const appsList = useRecoilValue(applicationsList);
  const [openAccordion, setOpenAccordion] = useState('');
  const showToast = useShowToast();
  const { track, events } = useTealium();

  useEffect(() => {
    track(events.applications.pageView());
  }, []);

  const getLastCreatedApplication = (applications) => {
    return applications.reduce((latest, current) => {
      return new Date(latest.applicationCreated) > new Date(current.applicationCreated) ? latest : current;
    });
  };

  const getCurrentApplication = (applications) => {
    let listApplications = [];
    listApplications = Array.isArray(applications) ? applications.slice().sort(
      (a, b) => new Date(b.applicationCreatedDate || 0).getTime() - new Date(a.applicationCreatedDate || 0).getTime()
    ) : [];
    return listApplications?.slice(0,1) || [];
  };

  const navigate = useNavigate();
  const iconType = 'info';
  const timeout = 'long';

  useEffect(() => {
    if (!appsList.length) {
      navigate(ApplicationRoutes.personalDetails);
      return;
    }
    else {
      const applicationstatus = getLastCreatedApplication(appsList).applicationStatus;
      switch (applicationstatus) {
        case appStatuses.CreditFacilityCreated: {
          track(events.applications.quotationViewNow());
          showToast(
            'You have a new Capital Facility.',
            iconType,
            timeout,
            AccountsRoutes['capital-facility'],
            true
          );
          break;
        }
        case appStatuses.QuoteAvailable: {
          track(events.applications.quotationViewNow());
          showToast(
            'You have a new quotation.',
            iconType,
            timeout,
            StaticRoutes.fundingQuote,
            true
          );
          break;
        }
        default:
          break;
      }
    }
  }, [appsList]);

  return (
    <div className={styles.myApplications}>

      <Header showBackBtn={true}>
        <div className={styles.headerTitle}>
          {isMobile && 'My Applications'}
        </div>
      </Header>

      <div className={styles.contentSection}>
        {!isMobile &&
          <div className={styles.containerLeft}>
            <NavBar />
          </div>
        }
        <div className={styles.containerRight}>
          <div className={styles.rightWrapper}>

            <div className={styles.rightHeading}>
              My applications
            </div>

            <div className={styles.rightSubHeading}>
              Your application for finance has been submitted. You can see a status of all your applications below.
            </div>

            <div className={styles.accordionSection}>

              {getCurrentApplication(appsList)?.map((application) => (
                <ApplicationAccordion
                  key={`app${application.applicationId}`}
                  isOpen={openAccordion === `app${application.applicationId}`}
                  toggleOpen={() => {
                    track(events.applications.viewApplicationDetails());
                    setOpenAccordion(openAccordion === `app${application.applicationId}` ? '' : `app${application.applicationId}`);
                  }}
                  applicationId={application.applicationId}
                />
              ))}
            </div>

            {Array.isArray(CrossSellCarouselData) && CrossSellCarouselData.length > 0 && (

              <div className={styles.carouselContainer}>
                <div className={styles.carouselHeading}>
                  You might also like
                </div>

                <CrossSellCarousel>
                  {CrossSellCarouselData.map((d, idx) => (
                    <CrossSellOpportunities
                      btnImage={d.btnImage}
                      heading={d.heading}
                      link={d.link}
                      subHeading={d.subHeading}
                      mainImage={d.mainImage}
                      key={idx}
                      onClick={() => track(events.applications.alsoLike(d.heading))}
                    />
                  ))}
                </CrossSellCarousel>

              </div>
            )}

          </div>
        </div>
      </div>
      <SupportPopup />
      <Footer />
    </div>
  );
};
