export const Countries = [
  {
    'name': 'Spain',
    'alpha2Code': 'ES',
    'alpha3Code': 'ESP',
    'flag': 'https://flagcdn.com/w320/es.png'
  },
  {
    'name': 'Afghanistan',
    'alpha2Code': 'AF',
    'alpha3Code': 'AFG',
    'flag': 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png'
  },
  {
    'name': 'Sudan',
    'alpha2Code': 'SD',
    'alpha3Code': 'SDN',
    'flag': 'https://flagcdn.com/w320/sd.png'
  },
  {
    'name': 'Bulgaria',
    'alpha2Code': 'BG',
    'alpha3Code': 'BGR',
    'flag': 'https://flagcdn.com/w320/bg.png'
  },
  {
    'name': 'Germany',
    'alpha2Code': 'DE',
    'alpha3Code': 'DEU',
    'flag': 'https://flagcdn.com/w320/de.png'
  },
  {
    'name': 'Lithuania',
    'alpha2Code': 'LT',
    'alpha3Code': 'LTU',
    'flag': 'https://flagcdn.com/w320/lt.png'
  },
  {
    'name': 'Mayotte',
    'alpha2Code': 'YT',
    'alpha3Code': 'MYT',
    'flag': 'https://flagcdn.com/w320/yt.png'
  },
  {
    'name': 'Marshall Islands',
    'alpha2Code': 'MH',
    'alpha3Code': 'MHL',
    'flag': 'https://flagcdn.com/w320/mh.png'
  },
  {
    'name': 'Poland',
    'alpha2Code': 'PL',
    'alpha3Code': 'POL',
    'flag': 'https://flagcdn.com/w320/pl.png'
  },
  {
    'name': 'Tokelau',
    'alpha2Code': 'TK',
    'alpha3Code': 'TKL',
    'flag': 'https://flagcdn.com/w320/tk.png'
  },
  {
    'name': 'Tanzania',
    'alpha2Code': 'TZ',
    'alpha3Code': 'TZA',
    'flag': 'https://flagcdn.com/w320/tz.png'
  },
  {
    'name': 'Macau',
    'alpha2Code': 'MO',
    'alpha3Code': 'MAC',
    'flag': 'https://flagcdn.com/w320/mo.png'
  },
  {
    'name': 'Timor-Leste',
    'alpha2Code': 'TL',
    'alpha3Code': 'TLS',
    'flag': 'https://flagcdn.com/w320/tl.png'
  },
  {
    'name': 'Togo',
    'alpha2Code': 'TG',
    'alpha3Code': 'TGO',
    'flag': 'https://flagcdn.com/w320/tg.png'
  },
  {
    'name': 'Czechia',
    'alpha2Code': 'CZ',
    'alpha3Code': 'CZE',
    'flag': 'https://flagcdn.com/w320/cz.png'
  },
  {
    'name': 'Monaco',
    'alpha2Code': 'MC',
    'alpha3Code': 'MCO',
    'flag': 'https://flagcdn.com/w320/mc.png'
  },
  {
    'name': 'Uganda',
    'alpha2Code': 'UG',
    'alpha3Code': 'UGA',
    'flag': 'https://flagcdn.com/w320/ug.png'
  },
  {
    'name': 'Trinidad and Tobago',
    'alpha2Code': 'TT',
    'alpha3Code': 'TTO',
    'flag': 'https://flagcdn.com/w320/tt.png'
  },
  {
    'name': 'Algeria',
    'alpha2Code': 'DZ',
    'alpha3Code': 'DZA',
    'flag': 'https://flagcdn.com/w320/dz.png'
  },
  {
    'name': 'Ukraine',
    'alpha2Code': 'UA',
    'alpha3Code': 'UKR',
    'flag': 'https://flagcdn.com/w320/ua.png'
  },
  {
    'name': 'Estonia',
    'alpha2Code': 'EE',
    'alpha3Code': 'EST',
    'flag': 'https://flagcdn.com/w320/ee.png'
  },
  {
    'name': 'Greece',
    'alpha2Code': 'GR',
    'alpha3Code': 'GRC',
    'flag': 'https://flagcdn.com/w320/gr.png'
  },
  {
    'name': 'Mauritania',
    'alpha2Code': 'MR',
    'alpha3Code': 'MRT',
    'flag': 'https://flagcdn.com/w320/mr.png'
  },
  {
    'name': 'Georgia',
    'alpha2Code': 'GE',
    'alpha3Code': 'GEO',
    'flag': 'https://flagcdn.com/w320/ge.png'
  },
  {
    'name': 'Sint Maarten',
    'alpha2Code': 'SX',
    'alpha3Code': 'SXM',
    'flag': 'https://flagcdn.com/w320/sx.png'
  },
  {
    'name': 'Namibia',
    'alpha2Code': 'NA',
    'alpha3Code': 'NAM',
    'flag': 'https://flagcdn.com/w320/na.png'
  },
  {
    'name': 'North Macedonia',
    'alpha2Code': 'MK',
    'alpha3Code': 'MKD',
    'flag': 'https://flagcdn.com/w320/mk.png'
  },
  {
    'name': 'Mauritius',
    'alpha2Code': 'MU',
    'alpha3Code': 'MUS',
    'flag': 'https://flagcdn.com/w320/mu.png'
  },
  {
    'name': 'Kiribati',
    'alpha2Code': 'KI',
    'alpha3Code': 'KIR',
    'flag': 'https://flagcdn.com/w320/ki.png'
  },
  {
    'name': 'Republic of the Congo',
    'alpha2Code': 'CG',
    'alpha3Code': 'COG',
    'flag': 'https://flagcdn.com/w320/cg.png'
  },
  {
    'name': 'Equatorial Guinea',
    'alpha2Code': 'GQ',
    'alpha3Code': 'GNQ',
    'flag': 'https://flagcdn.com/w320/gq.png'
  },
  {
    'name': 'British Indian Ocean Territory',
    'alpha2Code': 'IO',
    'alpha3Code': 'IOT',
    'flag': 'https://flagcdn.com/w320/io.png'
  },
  {
    'name': 'Niue',
    'alpha2Code': 'NU',
    'alpha3Code': 'NIU',
    'flag': 'https://flagcdn.com/w320/nu.png'
  },
  {
    'name': 'Kosovo',
    'alpha2Code': 'XK',
    'alpha3Code': 'UNK',
    'flag': 'https://flagcdn.com/w320/xk.png'
  },
  {
    'name': 'Palau',
    'alpha2Code': 'PW',
    'alpha3Code': 'PLW',
    'flag': 'https://flagcdn.com/w320/pw.png'
  },
  {
    'name': 'Qatar',
    'alpha2Code': 'QA',
    'alpha3Code': 'QAT',
    'flag': 'https://flagcdn.com/w320/qa.png'
  },
  {
    'name': 'United States',
    'alpha2Code': 'US',
    'alpha3Code': 'USA',
    'flag': 'https://flagcdn.com/w320/us.png'
  },
  {
    'name': 'Cayman Islands',
    'alpha2Code': 'KY',
    'alpha3Code': 'CYM',
    'flag': 'https://flagcdn.com/w320/ky.png'
  },
  {
    'name': 'Botswana',
    'alpha2Code': 'BW',
    'alpha3Code': 'BWA',
    'flag': 'https://flagcdn.com/w320/bw.png'
  },
  {
    'name': 'Palestine',
    'alpha2Code': 'PS',
    'alpha3Code': 'PSE',
    'flag': 'https://flagcdn.com/w320/ps.png'
  },
  {
    'name': 'South Africa',
    'alpha2Code': 'ZA',
    'alpha3Code': 'ZAF',
    'flag': 'https://flagcdn.com/w320/za.png'
  },
  {
    'name': 'Vietnam',
    'alpha2Code': 'VN',
    'alpha3Code': 'VNM',
    'flag': 'https://flagcdn.com/w320/vn.png'
  },
  {
    'name': 'Jersey',
    'alpha2Code': 'JE',
    'alpha3Code': 'JEY',
    'flag': 'https://flagcdn.com/w320/je.png'
  },
  {
    'name': 'Guyana',
    'alpha2Code': 'GY',
    'alpha3Code': 'GUY',
    'flag': 'https://flagcdn.com/w320/gy.png'
  },
  {
    'name': 'Tuvalu',
    'alpha2Code': 'TV',
    'alpha3Code': 'TUV',
    'flag': 'https://flagcdn.com/w320/tv.png'
  },
  {
    'name': 'Jordan',
    'alpha2Code': 'JO',
    'alpha3Code': 'JOR',
    'flag': 'https://flagcdn.com/w320/jo.png'
  },
  {
    'name': 'Papua New Guinea',
    'alpha2Code': 'PG',
    'alpha3Code': 'PNG',
    'flag': 'https://flagcdn.com/w320/pg.png'
  },
  {
    'name': 'Bahamas',
    'alpha2Code': 'BS',
    'alpha3Code': 'BHS',
    'flag': 'https://flagcdn.com/w320/bs.png'
  },
  {
    'name': 'Cape Verde',
    'alpha2Code': 'CV',
    'alpha3Code': 'CPV',
    'flag': 'https://flagcdn.com/w320/cv.png'
  },
  {
    'name': 'Oman',
    'alpha2Code': 'OM',
    'alpha3Code': 'OMN',
    'flag': 'https://flagcdn.com/w320/om.png'
  },
  {
    'name': 'Venezuela',
    'alpha2Code': 'VE',
    'alpha3Code': 'VEN',
    'flag': 'https://flagcdn.com/w320/ve.png'
  },
  {
    'name': 'Bolivia',
    'alpha2Code': 'BO',
    'alpha3Code': 'BOL',
    'flag': 'https://flagcdn.com/w320/bo.png'
  },
  {
    'name': 'Egypt',
    'alpha2Code': 'EG',
    'alpha3Code': 'EGY',
    'flag': 'https://flagcdn.com/w320/eg.png'
  },
  {
    'name': 'Canada',
    'alpha2Code': 'CA',
    'alpha3Code': 'CAN',
    'flag': 'https://flagcdn.com/w320/ca.png'
  },
  {
    'name': 'Norway',
    'alpha2Code': 'NO',
    'alpha3Code': 'NOR',
    'flag': 'https://flagcdn.com/w320/no.png'
  },
  {
    'name': 'Kyrgyzstan',
    'alpha2Code': 'KG',
    'alpha3Code': 'KGZ',
    'flag': 'https://flagcdn.com/w320/kg.png'
  },
  {
    'name': 'Armenia',
    'alpha2Code': 'AM',
    'alpha3Code': 'ARM',
    'flag': 'https://flagcdn.com/w320/am.png'
  },
  {
    'name': 'DR Congo',
    'alpha2Code': 'CD',
    'alpha3Code': 'COD',
    'flag': 'https://flagcdn.com/w320/cd.png'
  },
  {
    'name': 'Puerto Rico',
    'alpha2Code': 'PR',
    'alpha3Code': 'PRI',
    'flag': 'https://flagcdn.com/w320/pr.png'
  },
  {
    'name': 'Liechtenstein',
    'alpha2Code': 'LI',
    'alpha3Code': 'LIE',
    'flag': 'https://flagcdn.com/w320/li.png'
  },
  {
    'name': 'Eswatini',
    'alpha2Code': 'SZ',
    'alpha3Code': 'SWZ',
    'flag': 'https://flagcdn.com/w320/sz.png'
  },
  {
    'name': 'Faroe Islands',
    'alpha2Code': 'FO',
    'alpha3Code': 'FRO',
    'flag': 'https://flagcdn.com/w320/fo.png'
  },
  {
    'name': 'Nigeria',
    'alpha2Code': 'NG',
    'alpha3Code': 'NGA',
    'flag': 'https://flagcdn.com/w320/ng.png'
  },
  {
    'name': 'Saint Martin',
    'alpha2Code': 'MF',
    'alpha3Code': 'MAF',
    'flag': 'https://flagcdn.com/w320/mf.png'
  },
  {
    'name': 'Guam',
    'alpha2Code': 'GU',
    'alpha3Code': 'GUM',
    'flag': 'https://flagcdn.com/w320/gu.png'
  },
  {
    'name': 'Malawi',
    'alpha2Code': 'MW',
    'alpha3Code': 'MWI',
    'flag': 'https://flagcdn.com/w320/mw.png'
  },
  {
    'name': 'Åland Islands',
    'alpha2Code': 'AX',
    'alpha3Code': 'ALA',
    'flag': 'https://flagcdn.com/w320/ax.png'
  },
  {
    'name': 'São Tomé and Príncipe',
    'alpha2Code': 'ST',
    'alpha3Code': 'STP',
    'flag': 'https://flagcdn.com/w320/st.png'
  },
  {
    'name': 'Falkland Islands',
    'alpha2Code': 'FK',
    'alpha3Code': 'FLK',
    'flag': 'https://flagcdn.com/w320/fk.png'
  },
  {
    'name': 'Guatemala',
    'alpha2Code': 'GT',
    'alpha3Code': 'GTM',
    'flag': 'https://flagcdn.com/w320/gt.png'
  },
  {
    'name': 'Barbados',
    'alpha2Code': 'BB',
    'alpha3Code': 'BRB',
    'flag': 'https://flagcdn.com/w320/bb.png'
  },
  {
    'name': 'Ghana',
    'alpha2Code': 'GH',
    'alpha3Code': 'GHA',
    'flag': 'https://flagcdn.com/w320/gh.png'
  },
  {
    'name': 'Nicaragua',
    'alpha2Code': 'NI',
    'alpha3Code': 'NIC',
    'flag': 'https://flagcdn.com/w320/ni.png'
  },
  {
    'name': 'Isle of Man',
    'alpha2Code': 'IM',
    'alpha3Code': 'IMN',
    'flag': 'https://flagcdn.com/w320/im.png'
  },
  {
    'name': 'Portugal',
    'alpha2Code': 'PT',
    'alpha3Code': 'PRT',
    'flag': 'https://flagcdn.com/w320/pt.png'
  },
  {
    'name': 'Angola',
    'alpha2Code': 'AO',
    'alpha3Code': 'AGO',
    'flag': 'https://flagcdn.com/w320/ao.png'
  },
  {
    'name': 'Dominican Republic',
    'alpha2Code': 'DO',
    'alpha3Code': 'DOM',
    'flag': 'https://flagcdn.com/w320/do.png'
  },
  {
    'name': 'Albania',
    'alpha2Code': 'AL',
    'alpha3Code': 'ALB',
    'flag': 'https://flagcdn.com/w320/al.png'
  },
  {
    'name': 'Guinea-Bissau',
    'alpha2Code': 'GW',
    'alpha3Code': 'GNB',
    'flag': 'https://flagcdn.com/w320/gw.png'
  },
  {
    'name': 'Libya',
    'alpha2Code': 'LY',
    'alpha3Code': 'LBY',
    'flag': 'https://flagcdn.com/w320/ly.png'
  },
  {
    'name': 'Kuwait',
    'alpha2Code': 'KW',
    'alpha3Code': 'KWT',
    'flag': 'https://flagcdn.com/w320/kw.png'
  },
  {
    'name': 'Bahrain',
    'alpha2Code': 'BH',
    'alpha3Code': 'BHR',
    'flag': 'https://flagcdn.com/w320/bh.png'
  },
  {
    'name': 'Turkmenistan',
    'alpha2Code': 'TM',
    'alpha3Code': 'TKM',
    'flag': 'https://flagcdn.com/w320/tm.png'
  },
  {
    'name': 'Liberia',
    'alpha2Code': 'LR',
    'alpha3Code': 'LBR',
    'flag': 'https://flagcdn.com/w320/lr.png'
  },
  {
    'name': 'Burkina Faso',
    'alpha2Code': 'BF',
    'alpha3Code': 'BFA',
    'flag': 'https://flagcdn.com/w320/bf.png'
  },
  {
    'name': 'Latvia',
    'alpha2Code': 'LV',
    'alpha3Code': 'LVA',
    'flag': 'https://flagcdn.com/w320/lv.png'
  },
  {
    'name': 'Russia',
    'alpha2Code': 'RU',
    'alpha3Code': 'RUS',
    'flag': 'https://flagcdn.com/w320/ru.png'
  },
  {
    'name': 'Philippines',
    'alpha2Code': 'PH',
    'alpha3Code': 'PHL',
    'flag': 'https://flagcdn.com/w320/ph.png'
  },
  {
    'name': 'New Zealand',
    'alpha2Code': 'NZ',
    'alpha3Code': 'NZL',
    'flag': 'https://flagcdn.com/w320/nz.png'
  },
  {
    'name': 'Cameroon',
    'alpha2Code': 'CM',
    'alpha3Code': 'CMR',
    'flag': 'https://flagcdn.com/w320/cm.png'
  },
  {
    'name': 'Ecuador',
    'alpha2Code': 'EC',
    'alpha3Code': 'ECU',
    'flag': 'https://flagcdn.com/w320/ec.png'
  },
  {
    'name': 'Anguilla',
    'alpha2Code': 'AI',
    'alpha3Code': 'AIA',
    'flag': 'https://flagcdn.com/w320/ai.png'
  },
  {
    'name': 'Romania',
    'alpha2Code': 'RO',
    'alpha3Code': 'ROU',
    'flag': 'https://flagcdn.com/w320/ro.png'
  },
  {
    'name': 'India',
    'alpha2Code': 'IN',
    'alpha3Code': 'IND',
    'flag': 'https://flagcdn.com/w320/in.png'
  },
  {
    'name': 'Gabon',
    'alpha2Code': 'GA',
    'alpha3Code': 'GAB',
    'flag': 'https://flagcdn.com/w320/ga.png'
  },
  {
    'name': 'Myanmar',
    'alpha2Code': 'MM',
    'alpha3Code': 'MMR',
    'flag': 'https://flagcdn.com/w320/mm.png'
  },
  {
    'name': 'Réunion',
    'alpha2Code': 'RE',
    'alpha3Code': 'REU',
    'flag': 'https://flagcdn.com/w320/re.png'
  },
  {
    'name': 'American Samoa',
    'alpha2Code': 'AS',
    'alpha3Code': 'ASM',
    'flag': 'https://flagcdn.com/w320/as.png'
  },
  {
    'name': 'Montenegro',
    'alpha2Code': 'ME',
    'alpha3Code': 'MNE',
    'flag': 'https://flagcdn.com/w320/me.png'
  },
  {
    'name': 'Belize',
    'alpha2Code': 'BZ',
    'alpha3Code': 'BLZ',
    'flag': 'https://flagcdn.com/w320/bz.png'
  },
  {
    'name': 'Mongolia',
    'alpha2Code': 'MN',
    'alpha3Code': 'MNG',
    'flag': 'https://flagcdn.com/w320/mn.png'
  },
  {
    'name': 'Austria',
    'alpha2Code': 'AT',
    'alpha3Code': 'AUT',
    'flag': 'https://flagcdn.com/w320/at.png'
  },
  {
    'name': 'Montserrat',
    'alpha2Code': 'MS',
    'alpha3Code': 'MSR',
    'flag': 'https://flagcdn.com/w320/ms.png'
  },
  {
    'name': 'Saudi Arabia',
    'alpha2Code': 'SA',
    'alpha3Code': 'SAU',
    'flag': 'https://flagcdn.com/w320/sa.png'
  },
  {
    'name': 'Hungary',
    'alpha2Code': 'HU',
    'alpha3Code': 'HUN',
    'flag': 'https://flagcdn.com/w320/hu.png'
  },
  {
    'name': 'Nauru',
    'alpha2Code': 'NR',
    'alpha3Code': 'NRU',
    'flag': 'https://flagcdn.com/w320/nr.png'
  },
  {
    'name': 'Argentina',
    'alpha2Code': 'AR',
    'alpha3Code': 'ARG',
    'flag': 'https://flagcdn.com/w320/ar.png'
  },
  {
    'name': 'Pitcairn Islands',
    'alpha2Code': 'PN',
    'alpha3Code': 'PCN',
    'flag': 'https://flagcdn.com/w320/pn.png'
  },
  {
    'name': 'Wallis and Futuna',
    'alpha2Code': 'WF',
    'alpha3Code': 'WLF',
    'flag': 'https://flagcdn.com/w320/wf.png'
  },
  {
    'name': 'Yemen',
    'alpha2Code': 'YE',
    'alpha3Code': 'YEM',
    'flag': 'https://flagcdn.com/w320/ye.png'
  },
  {
    'name': 'Slovakia',
    'alpha2Code': 'SK',
    'alpha3Code': 'SVK',
    'flag': 'https://flagcdn.com/w320/sk.png'
  },
  {
    'name': 'Turks and Caicos Islands',
    'alpha2Code': 'TC',
    'alpha3Code': 'TCA',
    'flag': 'https://flagcdn.com/w320/tc.png'
  },
  {
    'name': 'Sweden',
    'alpha2Code': 'SE',
    'alpha3Code': 'SWE',
    'flag': 'https://flagcdn.com/w320/se.png'
  },
  {
    'name': 'Saint Helena, Ascension and Tristan da Cunha',
    'alpha2Code': 'SH',
    'alpha3Code': 'SHN',
    'flag': 'https://flagcdn.com/w320/sh.png'
  },
  {
    'name': 'Italy',
    'alpha2Code': 'IT',
    'alpha3Code': 'ITA',
    'flag': 'https://flagcdn.com/w320/it.png'
  },
  {
    'name': 'Brazil',
    'alpha2Code': 'BR',
    'alpha3Code': 'BRA',
    'flag': 'https://flagcdn.com/w320/br.png'
  },
  {
    'name': 'South Sudan',
    'alpha2Code': 'SS',
    'alpha3Code': 'SSD',
    'flag': 'https://flagcdn.com/w320/ss.png'
  },
  {
    'name': 'Cyprus',
    'alpha2Code': 'CY',
    'alpha3Code': 'CYP',
    'flag': 'https://flagcdn.com/w320/cy.png'
  },
  {
    'name': 'Thailand',
    'alpha2Code': 'TH',
    'alpha3Code': 'THA',
    'flag': 'https://flagcdn.com/w320/th.png'
  },
  {
    'name': 'Turkey',
    'alpha2Code': 'TR',
    'alpha3Code': 'TUR',
    'flag': 'https://flagcdn.com/w320/tr.png'
  },
  {
    'name': 'Bermuda',
    'alpha2Code': 'BM',
    'alpha3Code': 'BMU',
    'flag': 'https://flagcdn.com/w320/bm.png'
  },
  {
    'name': 'Australia',
    'alpha2Code': 'AU',
    'alpha3Code': 'AUS',
    'flag': 'https://flagcdn.com/w320/au.png'
  },
  {
    'name': 'Bangladesh',
    'alpha2Code': 'BD',
    'alpha3Code': 'BGD',
    'flag': 'https://flagcdn.com/w320/bd.png'
  },
  {
    'name': 'Grenada',
    'alpha2Code': 'GD',
    'alpha3Code': 'GRD',
    'flag': 'https://flagcdn.com/w320/gd.png'
  },
  {
    'name': 'Singapore',
    'alpha2Code': 'SG',
    'alpha3Code': 'SGP',
    'flag': 'https://flagcdn.com/w320/sg.png'
  },
  {
    'name': 'Moldova',
    'alpha2Code': 'MD',
    'alpha3Code': 'MDA',
    'flag': 'https://flagcdn.com/w320/md.png'
  },
  {
    'name': 'Mali',
    'alpha2Code': 'ML',
    'alpha3Code': 'MLI',
    'flag': 'https://flagcdn.com/w320/ml.png'
  },
  {
    'name': 'Kenya',
    'alpha2Code': 'KE',
    'alpha3Code': 'KEN',
    'flag': 'https://flagcdn.com/w320/ke.png'
  },
  {
    'name': 'Uruguay',
    'alpha2Code': 'UY',
    'alpha3Code': 'URY',
    'flag': 'https://flagcdn.com/w320/uy.png'
  },
  {
    'name': 'Switzerland',
    'alpha2Code': 'CH',
    'alpha3Code': 'CHE',
    'flag': 'https://flagcdn.com/w320/ch.png'
  },
  {
    'name': 'El Salvador',
    'alpha2Code': 'SV',
    'alpha3Code': 'SLV',
    'flag': 'https://flagcdn.com/w320/sv.png'
  },
  {
    'name': 'Chad',
    'alpha2Code': 'TD',
    'alpha3Code': 'TCD',
    'flag': 'https://flagcdn.com/w320/td.png'
  },
  {
    'name': 'Greenland',
    'alpha2Code': 'GL',
    'alpha3Code': 'GRL',
    'flag': 'https://flagcdn.com/w320/gl.png'
  },
  {
    'name': 'Belarus',
    'alpha2Code': 'BY',
    'alpha3Code': 'BLR',
    'flag': 'https://flagcdn.com/w320/by.png'
  },
  {
    'name': 'Ivory Coast',
    'alpha2Code': 'CI',
    'alpha3Code': 'CIV',
    'flag': 'https://flagcdn.com/w320/ci.png'
  },
  {
    'name': 'Lebanon',
    'alpha2Code': 'LB',
    'alpha3Code': 'LBN',
    'flag': 'https://flagcdn.com/w320/lb.png'
  },
  {
    'name': 'Netherlands',
    'alpha2Code': 'NL',
    'alpha3Code': 'NLD',
    'flag': 'https://flagcdn.com/w320/nl.png'
  },
  {
    'name': 'San Marino',
    'alpha2Code': 'SM',
    'alpha3Code': 'SMR',
    'flag': 'https://flagcdn.com/w320/sm.png'
  },
  {
    'name': 'Bhutan',
    'alpha2Code': 'BT',
    'alpha3Code': 'BTN',
    'flag': 'https://flagcdn.com/w320/bt.png'
  },
  {
    'name': 'Malaysia',
    'alpha2Code': 'MY',
    'alpha3Code': 'MYS',
    'flag': 'https://flagcdn.com/w320/my.png'
  },
  {
    'name': 'Kazakhstan',
    'alpha2Code': 'KZ',
    'alpha3Code': 'KAZ',
    'flag': 'https://flagcdn.com/w320/kz.png'
  },
  {
    'name': 'Finland',
    'alpha2Code': 'FI',
    'alpha3Code': 'FIN',
    'flag': 'https://flagcdn.com/w320/fi.png'
  },
  {
    'name': 'Tunisia',
    'alpha2Code': 'TN',
    'alpha3Code': 'TUN',
    'flag': 'https://flagcdn.com/w320/tn.png'
  },
  {
    'name': 'Gambia',
    'alpha2Code': 'GM',
    'alpha3Code': 'GMB',
    'flag': 'https://flagcdn.com/w320/gm.png'
  },
  {
    'name': 'Syria',
    'alpha2Code': 'SY',
    'alpha3Code': 'SYR',
    'flag': 'https://flagcdn.com/w320/sy.png'
  },
  {
    'name': 'French Southern and Antarctic Lands',
    'alpha2Code': 'TF',
    'alpha3Code': 'ATF',
    'flag': 'https://flagcdn.com/w320/tf.png'
  },
  {
    'name': 'Norfolk Island',
    'alpha2Code': 'NF',
    'alpha3Code': 'NFK',
    'flag': 'https://flagcdn.com/w320/nf.png'
  },
  {
    'name': 'Guinea',
    'alpha2Code': 'GN',
    'alpha3Code': 'GIN',
    'flag': 'https://flagcdn.com/w320/gn.png'
  },
  {
    'name': 'Caribbean Netherlands',
    'alpha2Code': 'BQ',
    'alpha3Code': 'BES',
    'flag': 'https://flagcdn.com/w320/bq.png'
  },
  {
    'name': 'Mozambique',
    'alpha2Code': 'MZ',
    'alpha3Code': 'MOZ',
    'flag': 'https://flagcdn.com/w320/mz.png'
  },
  {
    'name': 'Samoa',
    'alpha2Code': 'WS',
    'alpha3Code': 'WSM',
    'flag': 'https://flagcdn.com/w320/ws.png'
  },
  {
    'name': 'Taiwan',
    'alpha2Code': 'TW',
    'alpha3Code': 'TWN',
    'flag': 'https://flagcdn.com/w320/tw.png'
  },
  {
    'name': 'South Georgia',
    'alpha2Code': 'GS',
    'alpha3Code': 'SGS',
    'flag': 'https://flagcdn.com/w320/gs.png'
  },
  {
    'name': 'North Korea',
    'alpha2Code': 'KP',
    'alpha3Code': 'PRK',
    'flag': 'https://flagcdn.com/w320/kp.png'
  },
  {
    'name': 'Djibouti',
    'alpha2Code': 'DJ',
    'alpha3Code': 'DJI',
    'flag': 'https://flagcdn.com/w320/dj.png'
  },
  {
    'name': 'Suriname',
    'alpha2Code': 'SR',
    'alpha3Code': 'SUR',
    'flag': 'https://flagcdn.com/w320/sr.png'
  },
  {
    'name': 'France',
    'alpha2Code': 'FR',
    'alpha3Code': 'FRA',
    'flag': 'https://flagcdn.com/w320/fr.png'
  },
  {
    'name': 'Rwanda',
    'alpha2Code': 'RW',
    'alpha3Code': 'RWA',
    'flag': 'https://flagcdn.com/w320/rw.png'
  },
  {
    'name': 'South Korea',
    'alpha2Code': 'KR',
    'alpha3Code': 'KOR',
    'flag': 'https://flagcdn.com/w320/kr.png'
  },
  {
    'name': 'Antarctica',
    'alpha2Code': 'AQ',
    'alpha3Code': 'ATA',
    'flag': 'https://flagcdn.com/w320/aq.png'
  },
  {
    'name': 'Curaçao',
    'alpha2Code': 'CW',
    'alpha3Code': 'CUW',
    'flag': 'https://flagcdn.com/w320/cw.png'
  },
  {
    'name': 'Jamaica',
    'alpha2Code': 'JM',
    'alpha3Code': 'JAM',
    'flag': 'https://flagcdn.com/w320/jm.png'
  },
  {
    'name': 'Maldives',
    'alpha2Code': 'MV',
    'alpha3Code': 'MDV',
    'flag': 'https://flagcdn.com/w320/mv.png'
  },
  {
    'name': 'Slovenia',
    'alpha2Code': 'SI',
    'alpha3Code': 'SVN',
    'flag': 'https://flagcdn.com/w320/si.png'
  },
  {
    'name': 'Christmas Island',
    'alpha2Code': 'CX',
    'alpha3Code': 'CXR',
    'flag': 'https://flagcdn.com/w320/cx.png'
  },
  {
    'name': 'United States Virgin Islands',
    'alpha2Code': 'VI',
    'alpha3Code': 'VIR',
    'flag': 'https://flagcdn.com/w320/vi.png'
  },
  {
    'name': 'Paraguay',
    'alpha2Code': 'PY',
    'alpha3Code': 'PRY',
    'flag': 'https://flagcdn.com/w320/py.png'
  },
  {
    'name': 'Indonesia',
    'alpha2Code': 'ID',
    'alpha3Code': 'IDN',
    'flag': 'https://flagcdn.com/w320/id.png'
  },
  {
    'name': 'Madagascar',
    'alpha2Code': 'MG',
    'alpha3Code': 'MDG',
    'flag': 'https://flagcdn.com/w320/mg.png'
  },
  {
    'name': 'Iraq',
    'alpha2Code': 'IQ',
    'alpha3Code': 'IRQ',
    'flag': 'https://flagcdn.com/w320/iq.png'
  },
  {
    'name': 'Honduras',
    'alpha2Code': 'HN',
    'alpha3Code': 'HND',
    'flag': 'https://flagcdn.com/w320/hn.png'
  },
  {
    'name': 'Morocco',
    'alpha2Code': 'MA',
    'alpha3Code': 'MAR',
    'flag': 'https://flagcdn.com/w320/ma.png'
  },
  {
    'name': 'Hong Kong',
    'alpha2Code': 'HK',
    'alpha3Code': 'HKG',
    'flag': 'https://flagcdn.com/w320/hk.png'
  },
  {
    'name': 'Antigua and Barbuda',
    'alpha2Code': 'AG',
    'alpha3Code': 'ATG',
    'flag': 'https://flagcdn.com/w320/ag.png'
  },
  {
    'name': 'Sri Lanka',
    'alpha2Code': 'LK',
    'alpha3Code': 'LKA',
    'flag': 'https://flagcdn.com/w320/lk.png'
  },
  {
    'name': 'Haiti',
    'alpha2Code': 'HT',
    'alpha3Code': 'HTI',
    'flag': 'https://flagcdn.com/w320/ht.png'
  },
  {
    'name': 'Cuba',
    'alpha2Code': 'CU',
    'alpha3Code': 'CUB',
    'flag': 'https://flagcdn.com/w320/cu.png'
  },
  {
    'name': 'Tajikistan',
    'alpha2Code': 'TJ',
    'alpha3Code': 'TJK',
    'flag': 'https://flagcdn.com/w320/tj.png'
  },
  {
    'name': 'Cook Islands',
    'alpha2Code': 'CK',
    'alpha3Code': 'COK',
    'flag': 'https://flagcdn.com/w320/ck.png'
  },
  {
    'name': 'Colombia',
    'alpha2Code': 'CO',
    'alpha3Code': 'COL',
    'flag': 'https://flagcdn.com/w320/co.png'
  },
  {
    'name': 'Micronesia',
    'alpha2Code': 'FM',
    'alpha3Code': 'FSM',
    'flag': 'https://flagcdn.com/w320/fm.png'
  },
  {
    'name': 'Northern Mariana Islands',
    'alpha2Code': 'MP',
    'alpha3Code': 'MNP',
    'flag': 'https://flagcdn.com/w320/mp.png'
  },
  {
    'name': 'Saint Kitts and Nevis',
    'alpha2Code': 'KN',
    'alpha3Code': 'KNA',
    'flag': 'https://flagcdn.com/w320/kn.png'
  },
  {
    'name': 'Uzbekistan',
    'alpha2Code': 'UZ',
    'alpha3Code': 'UZB',
    'flag': 'https://flagcdn.com/w320/uz.png'
  },
  {
    'name': 'Gibraltar',
    'alpha2Code': 'GI',
    'alpha3Code': 'GIB',
    'flag': 'https://flagcdn.com/w320/gi.png'
  },
  {
    'name': 'Ireland',
    'alpha2Code': 'IE',
    'alpha3Code': 'IRL',
    'flag': 'https://flagcdn.com/w320/ie.png'
  },
  {
    'name': 'Peru',
    'alpha2Code': 'PE',
    'alpha3Code': 'PER',
    'flag': 'https://flagcdn.com/w320/pe.png'
  },
  {
    'name': 'Pakistan',
    'alpha2Code': 'PK',
    'alpha3Code': 'PAK',
    'flag': 'https://flagcdn.com/w320/pk.png'
  },
  {
    'name': 'Chile',
    'alpha2Code': 'CL',
    'alpha3Code': 'CHL',
    'flag': 'https://flagcdn.com/w320/cl.png'
  },
  {
    'name': 'Zambia',
    'alpha2Code': 'ZM',
    'alpha3Code': 'ZMB',
    'flag': 'https://flagcdn.com/w320/zm.png'
  },
  {
    'name': 'Saint Lucia',
    'alpha2Code': 'LC',
    'alpha3Code': 'LCA',
    'flag': 'https://flagcdn.com/w320/lc.png'
  },
  {
    'name': 'Seychelles',
    'alpha2Code': 'SC',
    'alpha3Code': 'SYC',
    'flag': 'https://flagcdn.com/w320/sc.png'
  },
  {
    'name': 'Benin',
    'alpha2Code': 'BJ',
    'alpha3Code': 'BEN',
    'flag': 'https://flagcdn.com/w320/bj.png'
  },
  {
    'name': 'United Kingdom',
    'alpha2Code': 'GB',
    'alpha3Code': 'GBR',
    'flag': 'https://flagcdn.com/w320/gb.png'
  },
  {
    'name': 'Bouvet Island',
    'alpha2Code': 'BV',
    'alpha3Code': 'BVT',
    'flag': 'https://flagcdn.com/w320/bv.png'
  },
  {
    'name': 'Costa Rica',
    'alpha2Code': 'CR',
    'alpha3Code': 'CRI',
    'flag': 'https://flagcdn.com/w320/cr.png'
  },
  {
    'name': 'Comoros',
    'alpha2Code': 'KM',
    'alpha3Code': 'COM',
    'flag': 'https://flagcdn.com/w320/km.png'
  },
  {
    'name': 'Iceland',
    'alpha2Code': 'IS',
    'alpha3Code': 'ISL',
    'flag': 'https://flagcdn.com/w320/is.png'
  },
  {
    'name': 'Japan',
    'alpha2Code': 'JP',
    'alpha3Code': 'JPN',
    'flag': 'https://flagcdn.com/w320/jp.png'
  },
  {
    'name': 'Vatican City',
    'alpha2Code': 'VA',
    'alpha3Code': 'VAT',
    'flag': 'https://flagcdn.com/w320/va.png'
  },
  {
    'name': 'British Virgin Islands',
    'alpha2Code': 'VG',
    'alpha3Code': 'VGB',
    'flag': 'https://flagcdn.com/w320/vg.png'
  },
  {
    'name': 'New Caledonia',
    'alpha2Code': 'NC',
    'alpha3Code': 'NCL',
    'flag': 'https://flagcdn.com/w320/nc.png'
  },
  {
    'name': 'Fiji',
    'alpha2Code': 'FJ',
    'alpha3Code': 'FJI',
    'flag': 'https://flagcdn.com/w320/fj.png'
  },
  {
    'name': 'Bosnia and Herzegovina',
    'alpha2Code': 'BA',
    'alpha3Code': 'BIH',
    'flag': 'https://flagcdn.com/w320/ba.png'
  },
  {
    'name': 'Cocos (Keeling) Islands',
    'alpha2Code': 'CC',
    'alpha3Code': 'CCK',
    'flag': 'https://flagcdn.com/w320/cc.png'
  },
  {
    'name': 'Western Sahara',
    'alpha2Code': 'EH',
    'alpha3Code': 'ESH',
    'flag': 'https://flagcdn.com/w320/eh.png'
  },
  {
    'name': 'Mexico',
    'alpha2Code': 'MX',
    'alpha3Code': 'MEX',
    'flag': 'https://flagcdn.com/w320/mx.png'
  },
  {
    'name': 'Cambodia',
    'alpha2Code': 'KH',
    'alpha3Code': 'KHM',
    'flag': 'https://flagcdn.com/w320/kh.png'
  },
  {
    'name': 'Saint Vincent and the Grenadines',
    'alpha2Code': 'VC',
    'alpha3Code': 'VCT',
    'flag': 'https://flagcdn.com/w320/vc.png'
  },
  {
    'name': 'Iran',
    'alpha2Code': 'IR',
    'alpha3Code': 'IRN',
    'flag': 'https://flagcdn.com/w320/ir.png'
  },
  {
    'name': 'Malta',
    'alpha2Code': 'MT',
    'alpha3Code': 'MLT',
    'flag': 'https://flagcdn.com/w320/mt.png'
  },
  {
    'name': 'Serbia',
    'alpha2Code': 'RS',
    'alpha3Code': 'SRB',
    'flag': 'https://flagcdn.com/w320/rs.png'
  },
  {
    'name': 'United Arab Emirates',
    'alpha2Code': 'AE',
    'alpha3Code': 'ARE',
    'flag': 'https://flagcdn.com/w320/ae.png'
  },
  {
    'name': 'Azerbaijan',
    'alpha2Code': 'AZ',
    'alpha3Code': 'AZE',
    'flag': 'https://flagcdn.com/w320/az.png'
  },
  {
    'name': 'Heard Island and McDonald Islands',
    'alpha2Code': 'HM',
    'alpha3Code': 'HMD',
    'flag': 'https://flagcdn.com/w320/hm.png'
  },
  {
    'name': 'French Polynesia',
    'alpha2Code': 'PF',
    'alpha3Code': 'PYF',
    'flag': 'https://flagcdn.com/w320/pf.png'
  },
  {
    'name': 'Vanuatu',
    'alpha2Code': 'VU',
    'alpha3Code': 'VUT',
    'flag': 'https://flagcdn.com/w320/vu.png'
  },
  {
    'name': 'Niger',
    'alpha2Code': 'NE',
    'alpha3Code': 'NER',
    'flag': 'https://flagcdn.com/w320/ne.png'
  },
  {
    'name': 'Eritrea',
    'alpha2Code': 'ER',
    'alpha3Code': 'ERI',
    'flag': 'https://flagcdn.com/w320/er.png'
  },
  {
    'name': 'Central African Republic',
    'alpha2Code': 'CF',
    'alpha3Code': 'CAF',
    'flag': 'https://flagcdn.com/w320/cf.png'
  },
  {
    'name': 'Israel',
    'alpha2Code': 'IL',
    'alpha3Code': 'ISR',
    'flag': 'https://flagcdn.com/w320/il.png'
  },
  {
    'name': 'Nepal',
    'alpha2Code': 'NP',
    'alpha3Code': 'NPL',
    'flag': 'https://flagcdn.com/w320/np.png'
  },
  {
    'name': 'Panama',
    'alpha2Code': 'PA',
    'alpha3Code': 'PAN',
    'flag': 'https://flagcdn.com/w320/pa.png'
  },
  {
    'name': 'Guernsey',
    'alpha2Code': 'GG',
    'alpha3Code': 'GGY',
    'flag': 'https://flagcdn.com/w320/gg.png'
  },
  {
    'name': 'Solomon Islands',
    'alpha2Code': 'SB',
    'alpha3Code': 'SLB',
    'flag': 'https://flagcdn.com/w320/sb.png'
  },
  {
    'name': 'Zimbabwe',
    'alpha2Code': 'ZW',
    'alpha3Code': 'ZWE',
    'flag': 'https://flagcdn.com/w320/zw.png'
  },
  {
    'name': 'Aruba',
    'alpha2Code': 'AW',
    'alpha3Code': 'ABW',
    'flag': 'https://flagcdn.com/w320/aw.png'
  },
  {
    'name': 'Brunei',
    'alpha2Code': 'BN',
    'alpha3Code': 'BRN',
    'flag': 'https://flagcdn.com/w320/bn.png'
  },
  {
    'name': 'Denmark',
    'alpha2Code': 'DK',
    'alpha3Code': 'DNK',
    'flag': 'https://flagcdn.com/w320/dk.png'
  },
  {
    'name': 'Belgium',
    'alpha2Code': 'BE',
    'alpha3Code': 'BEL',
    'flag': 'https://flagcdn.com/w320/be.png'
  },
  {
    'name': 'French Guiana',
    'alpha2Code': 'GF',
    'alpha3Code': 'GUF',
    'flag': 'https://flagcdn.com/w320/gf.png'
  },
  {
    'name': 'Laos',
    'alpha2Code': 'LA',
    'alpha3Code': 'LAO',
    'flag': 'https://flagcdn.com/w320/la.png'
  },
  {
    'name': 'Tonga',
    'alpha2Code': 'TO',
    'alpha3Code': 'TON',
    'flag': 'https://flagcdn.com/w320/to.png'
  },
  {
    'name': 'Andorra',
    'alpha2Code': 'AD',
    'alpha3Code': 'AND',
    'flag': 'https://flagcdn.com/w320/ad.png'
  },
  {
    'name': 'Guadeloupe',
    'alpha2Code': 'GP',
    'alpha3Code': 'GLP',
    'flag': 'https://flagcdn.com/w320/gp.png'
  },
  {
    'name': 'Martinique',
    'alpha2Code': 'MQ',
    'alpha3Code': 'MTQ',
    'flag': 'https://flagcdn.com/w320/mq.png'
  },
  {
    'name': 'Lesotho',
    'alpha2Code': 'LS',
    'alpha3Code': 'LSO',
    'flag': 'https://flagcdn.com/w320/ls.png'
  },
  {
    'name': 'Dominica',
    'alpha2Code': 'DM',
    'alpha3Code': 'DMA',
    'flag': 'https://flagcdn.com/w320/dm.png'
  },
  {
    'name': 'Somalia',
    'alpha2Code': 'SO',
    'alpha3Code': 'SOM',
    'flag': 'https://flagcdn.com/w320/so.png'
  },
  {
    'name': 'Croatia',
    'alpha2Code': 'HR',
    'alpha3Code': 'HRV',
    'flag': 'https://flagcdn.com/w320/hr.png'
  },
  {
    'name': 'Sierra Leone',
    'alpha2Code': 'SL',
    'alpha3Code': 'SLE',
    'flag': 'https://flagcdn.com/w320/sl.png'
  },
  {
    'name': 'Saint Barthélemy',
    'alpha2Code': 'BL',
    'alpha3Code': 'BLM',
    'flag': 'https://flagcdn.com/w320/bl.png'
  },
  {
    'name': 'Svalbard and Jan Mayen',
    'alpha2Code': 'SJ',
    'alpha3Code': 'SJM',
    'flag': 'https://flagcdn.com/w320/sj.png'
  },
  {
    'name': 'Saint Pierre and Miquelon',
    'alpha2Code': 'PM',
    'alpha3Code': 'SPM',
    'flag': 'https://flagcdn.com/w320/pm.png'
  },
  {
    'name': 'United States Minor Outlying Islands',
    'alpha2Code': 'UM',
    'alpha3Code': 'UMI',
    'flag': 'https://flagcdn.com/w320/um.png'
  },
  {
    'name': 'Burundi',
    'alpha2Code': 'BI',
    'alpha3Code': 'BDI',
    'flag': 'https://flagcdn.com/w320/bi.png'
  },
  {
    'name': 'Senegal',
    'alpha2Code': 'SN',
    'alpha3Code': 'SEN',
    'flag': 'https://flagcdn.com/w320/sn.png'
  },
  {
    'name': 'Ethiopia',
    'alpha2Code': 'ET',
    'alpha3Code': 'ETH',
    'flag': 'https://flagcdn.com/w320/et.png'
  },
  {
    'name': 'Luxembourg',
    'alpha2Code': 'LU',
    'alpha3Code': 'LUX',
    'flag': 'https://flagcdn.com/w320/lu.png'
  },
  {
    'name': 'China',
    'alpha2Code': 'CN',
    'alpha3Code': 'CHN',
    'flag': 'https://flagcdn.com/w320/cn.png'
  }
];
