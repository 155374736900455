import { axiosInstance, getErrorStatus } from './axiosInstance';
import { StatusCodes } from 'http-status-codes';

const POSTFIX = '/applications';

export const submitApplication = async (data) => {

  let response;

  try {

    response = await axiosInstance.post(POSTFIX, data);

    if (response.status !== StatusCodes.CREATED || !response.data) {
      throw new Error('SubmitApplication');
    }

  } catch (error) {

    response = {
      data: {
        ...data,
        error: {
          ...(error?.response?.data || {}),
          status: getErrorStatus(error)
        }
      }
    };
  }

  return response.data;
};

export const getApplications = async () => {

  const response = await axiosInstance.get(POSTFIX);

  if (response.status !== StatusCodes.OK || !response.data) {
    throw new Error('GetApplications');
  }

  return response.data;
};