import { Button } from '@vfs-digital-channels/ns-react-components';
import moment from 'moment';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StaticRoutes } from '../../Router';
import { getTransactionDetails } from '../../api/vodaLend';
import { ArrowDown } from '../../assets';
import { Accordion, CircularProgressBar, Footer, RangeSlider } from '../../components';
import { useLoader } from '../../components/Loader/useLoader';
import { useModal } from '../../components/modalController/useModal';
import { Section } from '../../components/section/section';
import { StatusChip } from '../../components/statusChip/statusChip';
import { Table } from '../../components/table/table';
import { AccordionStyle } from '../../constants';
import { FaqRcf } from '../../constants/FaqRcf';
import { formatCurrency } from '../../helpers';
import {
  amountToWithdraw,
  applicationStatuses,
  creditFacilityDetailsQuery,
  rcfTransactionsQuery,
  useShowToast
} from '../../recoil';
import { useTealium } from '../../tealium/useTealium';
import styles from './capitalFacility.module.css';
import { FaqList } from './components/faqList/faqList';
import { IncreaseLimitModal } from './components/increaseLimitModal/increaseLimitModal';

const MIN_WITHDRAWAL_AMT = 10000;

export const CapitalFacility = () => {
  const [openSection, setOpenSection] = useState('summary');
  const [sliderWithdrawalAmount, setSliderWithdrawalAmount] = useState(0);
  const [openModal, closeModal] = useModal();
  const navigate = useNavigate();
  const questions = FaqRcf;
  const transactions = useRecoilValue(rcfTransactionsQuery);
  const creditFacilityDetails = useRecoilValue(creditFacilityDetailsQuery);
  const [amtToWithdraw, setAmtToWithdraw] = useRecoilState(amountToWithdraw);
  const formattedWithdrawAmount = formatCurrency(sliderWithdrawalAmount);
  const formattedAmountAvailable = formatCurrency(creditFacilityDetails.amountAvailable);
  const formattedAmountOwed = formatCurrency(creditFacilityDetails.amountOwed);
  const formattedCapitalOwed = formatCurrency(creditFacilityDetails.capitalOwed);
  const formattedAmountLimit = formatCurrency(creditFacilityDetails.amountLimit);
  const percentageOwed = creditFacilityDetails.amountOwed / creditFacilityDetails.amountLimit * 100;
  const appStates = useRecoilValue(applicationStatuses);
  const [openLoader, closeLoader] = useLoader();
  const showToast = useShowToast();
  const { track, events } = useTealium();


  useEffect(() => {
    track(events.creditFacility.pageView());
  }, []);

  const isValidExposure = (selectedAmt, owedAmt) => {
    const totalAmt = owedAmt + selectedAmt;
    return totalAmt < MIN_WITHDRAWAL_AMT;
  };

  const isBtnEnabled = useMemo(() => !!(
    creditFacilityDetails.withdrawalEligibility
  ), [creditFacilityDetails]);

  const rcfSummaryDetails = [
    { label: 'Credit Facility Limit:', value: formattedAmountLimit },
    { label: 'Capital Owed:', value: formattedCapitalOwed },
    { label: 'Total Owed:', value: formattedAmountOwed },
    { label: 'Total Available:', value: formattedAmountAvailable },
  ];

  useEffect(() => {
    if (!appStates?.isCreditFacilityCreated) {
      navigate(StaticRoutes.applications);
    }
  }, [navigate, appStates]);

  const handleTransactionClick = async (id) => {
    track(events.creditFacility.transactionsViewMore());
    try {
      openLoader();
      const transactionHistoryDetails = await getTransactionDetails(id);
      track(events.creditFacility.transactionPageView());
      openModal({
        title: `Transaction history: ${id}`,
        children: (
          <>
            {transactionHistoryDetails?.records.length ?
              <Table
                data={
                  transactionHistoryDetails.records.map((d) => ({
                    ...d,
                    paymentDate: moment(d.paymentDate).format('DD-MMM-YY'),
                    capitalAmount: formatCurrency(d.capitalAmount),
                    costAmount: formatCurrency(d.costAmount),
                    paymentAmount: formatCurrency(d.paymentAmount),
                    paidAmount: formatCurrency(d.paidAmount),
                    arrears: formatCurrency(d.arrears),
                    earlySettlement: formatCurrency(d.earlySettlement)
                  }))
                }
                heading={{
                  paymentDate: 'Date',
                  capitalAmount: 'Capital',
                  costAmount: 'Cost',
                  paymentAmount: 'Amount',
                  paidAmount: 'Paid Amount',
                  arrears: 'Arrears',
                  earlySettlement: 'Early Settlement',
                  status: 'Status'
                }}
                footer={{
                  costAmount: formatCurrency(transactionHistoryDetails.total.costAmount),
                  capitalAmount: formatCurrency(transactionHistoryDetails.total.capitalAmount),
                  paymentAmount: formatCurrency(transactionHistoryDetails.total.paymentAmount),
                  paidAmount: formatCurrency(transactionHistoryDetails.total.paidAmount),
                  arrears: formatCurrency(transactionHistoryDetails.total.arrears),
                  earlySettlement: formatCurrency(transactionHistoryDetails.total.earlySettlement),
                  paymentDate: 'TOTAL',
                }}
              /> : null
            }

            {!transactionHistoryDetails?.records.length
              ? 'No transaction history found'
              : null
            }
          </>
        )
      });
    } catch (error) {
      openModal({
        title: 'Error',
        children: <div>There was an error fetching your transaction details.</div>,
        type: 'error',
        actions: [
          <Button
            style={{ display: 'flex', maxWidth: 230, width: '100%', justifyContent: 'center' }}
            key="ViewTransactionDetailsFailed"
            onClick={() => closeModal()}>
            Close
          </Button>
        ]
      });
    } finally {
      closeLoader();
    }
  };

  const handleIncreaseLimitClick = () => {
    track(events.creditFacility.increaseCapitalLimit());
    if (!creditFacilityDetails.limitIncreaseEligibility) {
      showToast('You have already requested a limit increase. Please try again after the existing request has been processed.', 'info');
      return;
    }
    openModal({
      title: 'Get a Capital Facility Limit Increase',
      children:
        <IncreaseLimitModal
          capitalFacilityId={creditFacilityDetails.id}
          currentLimit={creditFacilityDetails.amountLimit}
          onView={() => track(events.limitIncrease.pageView())}
          onSubmit={() => track(events.limitIncrease.submit())}
          onSuccess={() => track(events.limitIncreaseSuccess.pageView())}
          onClick={() => track(events.limitIncreaseSuccess.link())}
        />
    });
  };

  const handleRangeSliderChange = (value) => {
    const val = +value;
    if (val !== sliderWithdrawalAmount) {
      setSliderWithdrawalAmount(val);
    }
  };

  const navigateToWithdrawal = () => {
    track(events.creditFacility.viewWithdrawal());
    if (isValidExposure(sliderWithdrawalAmount, creditFacilityDetails.amountOwed)) {
      openModal({
        children: <div>Exposure upon withdrawal must be higher or equal to R10k. Please select higher amount such that the minimum exposure is R10k or more.</div>,
        actions: [<Button key="close" onClick={closeModal}>Close</Button>],
      });
    }
    else {
      setAmtToWithdraw(+sliderWithdrawalAmount);
      navigate('withdrawal');
    }
  };

  return (
    <main className={styles.main}>
      <Section>
        <div className={styles.header}>
          <div className={styles.title}>Capital facility</div>
          <StatusChip status={creditFacilityDetails.status} />
        </div>
        <Accordion
          title="Summary"
          isOpen={openSection === 'summary'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={AccordionStyle.accordionTitle}>
                  {'Account Summary'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'summary' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt="DropdownOpen"
                  role="presentation"
                  onClick={() => setOpenSection(openSection === 'summary' ? '' : 'summary')}
                  onKeyDown={() => setOpenSection(openSection === 'summary' ? '' : 'summary')}
                />
              </div>
            </>
          }
        >
          <div className={styles.summaryContainer}>
            <ul className={styles.summaryList}>
              <li>You can currently withdraw up to {formattedAmountAvailable}</li>
              <li>Your repayment schedule will be adjusted.</li>
              {creditFacilityDetails.limitIncreaseEligibility
                ?
                <li><div onClick={handleIncreaseLimitClick} className={styles.url}>Increase Capital Facility limit</div> </li>
                :
                <li><div onClick={handleIncreaseLimitClick} className={styles.urlDisabled}>Increase Capital Facility limit [in progress]</div></li>
              }
            </ul>

            <div className={styles.rangeSlider}>
              <div className={styles.sliderHeader}>Selected amount: <span
                className={styles.rangeSliderAmount}>{formattedWithdrawAmount}</span></div>
              <RangeSlider
                onChange={handleRangeSliderChange}
                max={creditFacilityDetails.amountAvailable}
                value={+amtToWithdraw}
                min={0}
                step={1000}
                displayMiddleSliderValue={false}
              />
            </div>

            <div className={styles.summaryDetails}>
              <div className={styles.graph}>
                <CircularProgressBar
                  percentage={percentageOwed}
                  heading={formattedAmountOwed}
                  subHeading={'Total Owed'}
                  isClockWise={false}
                  isPercLineRed={false}
                />
                <div className={styles.graphData}>
                  <div className={styles.summaryProgressBarInfo}>
                    <div className={styles.progressIndicator}>
                      <div className={styles.blueEllipse} />
                      <div className={styles.progressLabel}>
                        Total Available
                        <div
                          className={styles.progressLabelAmt}>{formattedAmountAvailable}</div>
                      </div>
                    </div>
                    <div className={styles.progressIndicator}>
                      <div className={styles.redEllipse} />
                      <div className={styles.progressLabel}>
                        Total Owed
                        <div
                          className={styles.progressLabelAmt}>{formattedAmountOwed}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.data}>
                {rcfSummaryDetails.map((d, i) => (
                  <div key={i} className={styles.row}>
                    <div>{d.label}</div>
                    <div><b>{d.value}</b></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Accordion>

        <Accordion
          title="Transaction History"
          isOpen={openSection === 'transactionHistory'}
          headerChildren={
            <>
              <div style={AccordionStyle.accordionHeaderLeft}>
                <div style={AccordionStyle.accordionTitle}>
                  {'Transaction History'}
                </div>
              </div>

              <div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: openSection === 'transactionHistory' ? '180deg' : '' }}
                  src={ArrowDown}
                  alt="DropdownOpen"
                  role="presentation"
                  onClick={() => setOpenSection(openSection === 'transactionHistory' ? '' : 'transactionHistory')}
                  onKeyDown={() => setOpenSection(openSection === 'transactionHistory' ? '' : 'transactionHistory')}
                />
              </div>
            </>
          }
        >
          <div className='px-5 py-2'>
            {transactions.length > 0 &&
              <Table
                data={
                  transactions.map((d) => ({
                    date: moment(d.date).format('DD MMMM YYYY'),
                    description: 'Drawdown',
                    id: <button className={styles.url} onClick={() => handleTransactionClick(d.id)}>{d.id}</button>,
                    amount: formatCurrency(d.amount),
                    capital: formatCurrency(d.capital)
                  }))
                }
                heading={{
                  date: 'Date',
                  description: 'Description',
                  id: 'Reference',
                  amount: 'Amount',
                  capital: 'Capital'
                }}
              />
            }
            {transactions.length < 1 && (
              <div className='px-5'>
                No transactions found
              </div>
            )}
          </div>
        </Accordion>
        <FaqList questions={questions} />
      </Section>

      <Footer
        rightTitle='View withdrawal'
        rightCallback={isBtnEnabled ? navigateToWithdrawal : null}
        rightBtnStyle={{ backgroundColor: '#393949', border: 'none', color: '#A8ACB8' }}
      />
    </main>
  );
};
