import { useRecoilState, useResetRecoilState } from 'recoil';
import { modalState } from './modalState';

export const useModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const resetModal = useResetRecoilState(modalState);

  const openModal = (props) => {
    resetModal();
    setModal({
      ...modal,
      ...props,
      isOpen: true,
    });
  };

  const closeModal = () => {
    resetModal();
  };

  return [openModal, closeModal, modal];
};
