export const ResidentialStatus = [
  {
    'label': 'Owner Occupier (No Mortgage)',
    'value': 'Owner Occupier (No Mortgage)'
  },
  {
    'label': 'Owner Occupier (With Mortgage)',
    'value': 'Owner Occupier (With Mortgage)'
  },
  {
    'label': 'Tenant', 'value': 'Tenant'
  },
  {
    'label': 'Living with Parents', 'value': 'Living with Parents'
  }
];