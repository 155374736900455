import React, { useMemo, useState } from 'react';
import { GetInTouch, QuestionMarkRed } from '../../assets';
import { Colors, CommonDetails, Fonts } from '../../constants';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';
import { useTealium } from '../../tealium/useTealium';
import { useLocation } from 'react-router-dom';


const styles = {
  floatingPanel: {
    minWidth: '28%',
    maxWidth: '90%',
    maxHeight: '50%',
    height: 350,
    width: 'auto',
    background: Colors.GreyScale2,
    position: 'fixed',
    bottom: 175,
    right: '3%',
    display: 'flex',
    zIndex: 999,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)'

  },

  panelLabel: {
    marginTop: '10%',
    fontFamily: Fonts.RobotoBold,
    fontSize: '1.543em',
    fontStyle: 'normal',
    lineHeight: '120%',
    latterSpacing: -0.75,
    textAlign: 'center',
    letterSpacing: -0.75,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
  },

  panelContactLabel: {
    cursor: 'pointer',
    marginTop: 13,
    fontFamily: Fonts.RobotoBold,
    fontSize: '1.125em',
    fontStyle: 'normal',
    lineHeight: '120%',
    latterSpacing: -0.66,
    color: Colors.Primary4,
    textAlign: 'center',
    marginBottom: '4%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },

  contactLabel: {
    display: 'flex',
    justifyContent: 'center'
  },

  textSeparator: {
    color: Colors.GreyScale13,
    fontSize: 22
  },

  floatingHelp: {
    cursor: 'pointer',
    width: 50,
    backgroundColor: Colors.GreyScale3,
    borderRadius: 50,
    zIndex: 999,
    position: 'fixed',
    bottom: 108,
    right: '3%',
    display: 'flex',
    textAlign: 'center',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },

  closeLineForward: {
    width: 22,
    height: 3,
    background: Colors.Primary4,
    borderRadius: 50,
    transform: 'rotate(45deg)'
  },

  closeLineBackward: {
    width: 22,
    height: 3,
    background: Colors.Primary4,
    borderRadius: 50,
    transform: 'rotate(135deg)',
    position: 'absolute'
  },

  imageLabel: {
    width: '38%',
    height: '40%',
    marginTop: '8%'
  },

  contactEmailLabel: {
    wordBreak: 'break-all',
    width: 'fit-content'
  }

};

export const SupportPopup = () => {

  const contactUsEmail = CommonDetails.contactUsEmail;
  const contactUsPhone = CommonDetails.contactUsMsisdn;

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useRecoilValue(useIsMobile);

  const { track, events } = useTealium();
  const location = useLocation();

  const panelLabel = useMemo(() => isMobile ? { ...styles.panelLabel, flexDirection: 'column' } : styles.panelLabel, [isMobile]);
  const panelContactLabel = useMemo(() => isMobile ? { ...styles.panelContactLabel, flexDirection: 'column' } : styles.panelContactLabel, [isMobile]);

  const triggerEmailClient = () => {
    track(events.help.emailClick(location.pathname));
    window.location.href = `mailto:${contactUsEmail}`;
  };
  const triggerPhoneDialer = () => {
    track(events.help.phoneClick(location.pathname));
    if(isMobile) {
      document.location.href = `tel:${contactUsPhone.replace(/\s/g,'')}`;
    }
  };



  const onClick = () => {
    track(events.help.click(location.pathname));
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {isOpen &&
        <div style={styles.floatingPanel}>
          <img src={GetInTouch} alt='GetInTouch' style={styles.imageLabel} />
          <div style={panelLabel}>Need Help?<div>&nbsp;Get in touch with us:</div></div>
          <div style={panelContactLabel}>
            <div style={styles.contactLabel}>
              <div
                onClick={() => triggerPhoneDialer()}
                onKeyDown={() => triggerPhoneDialer()}
                role='presentation'>
                {contactUsPhone}
              </div>
              <div style={styles.textSeparator}>&nbsp;|&nbsp;</div>
            </div>
            <div style={styles.contactEmailLabel}
              onClick={() => triggerEmailClient()}
              onKeyDown={() => triggerEmailClient()}
              role='presentation'>
              {contactUsEmail}
            </div>
          </div>
        </div>
      }
      <div
        style={styles.floatingHelp}
        onClick={onClick}
        onKeyDown={onClick}
        role='presentation'
      >
        {isOpen
          ? <>
            <div style={styles.closeLineForward} />
            <div style={styles.closeLineBackward} /></>
          : <img src={QuestionMarkRed} alt='QuestionMarkRed' />
        }
      </div>
    </div>
  );

};
