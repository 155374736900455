import {
  BusinessCashAdvance,
  GreenCarouselButton,
  RedCarouselButton,
  VodapayKwika,
  VodapayMax
} from '../assets';

export const CrossSellCarouselData = [
  {
    mainImage: BusinessCashAdvance,
    heading: 'Business Cash Advance',
    subHeading: 'Access funding from the VodaPay Card Machine',
    btnImage: GreenCarouselButton,
    link: 'https://www.vodacombusiness.co.za/business/solutions/lending/business-cash-advance'
  },
  {
    mainImage: VodapayMax,
    heading: 'VodaPay Max',
    subHeading: 'Build your business your way. Accept all payment types.',
    btnImage: RedCarouselButton,
    link: 'https://www.vodacombusiness.co.za/business/solutions/financial-services/max'
  },
  {
    mainImage: VodapayKwika,
    heading: 'VodaPay Kwika',
    subHeading: 'Let\'s grow your business together.',
    btnImage: RedCarouselButton,
    link: 'https://www.vodacombusiness.co.za/business/solutions/financial-services/kwika'
  }
];
