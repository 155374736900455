import React, {
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Colors, Fonts } from '../../constants';
import { useRecoilValue } from 'recoil';
import { useIsMobile } from '../../recoil';
import classes from './TableComponent.module.css';

const styles = {
  transactionTableContainer: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    maxHeight: 595,
    height: '100%'
  },
  transactionTable: {
    display: 'table',
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse'
  },
  tableHeader: {
    backgroundColor: Colors.GreyScale4,
    paddingRight: 10,
    paddingLeft: 10,
    borderTop: `1px solid ${Colors.GreyScale6}`,
    borderBottom: `1px solid ${Colors.GreyScale6}`,
    fontSize: 16,
    fontFamily: Fonts.RobotoMedium,
    lineHeight: '24px',
    maxHeight: '100%',
    height: 45,
    position: 'sticky',
    top: 0
  },
  tableRow: {
    display: 'table-row'
  },
  tableRowEven: {
    display: 'table-row',
    backgroundColor: Colors.GreyScale2
  },
  tableData: {
    borderBottom: `1px solid ${Colors.GreyScale5}`,
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 16,
    fontFamily: Fonts.Roboto,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    height: 44
  }
};

// eslint-disable-next-line no-magic-numbers
const isEven = (val) => val % 2 === 0;

export const TableComponent = ({ heading, data }) => {

  const isMobile = useRecoilValue(useIsMobile);

  const tableHeader = useMemo(() => ({
    ...(
      isMobile ? {
        ...styles.tableHeader,
        fontSize: 14,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 7,
        maxHeight: '100%',
        height: '100%'
      }
        : styles.tableHeader
    )
  }), [isMobile]);

  const tableData = useMemo(() => ({
    ...(
      isMobile ? {
        ...styles.tableData,
        fontSize: 14,
        paddingRight: 0,
        paddingLeft: 5,
        height: 32
      }
        : styles.tableData
    )
  }), [isMobile]);

  const transactionTableContainer = useMemo(() => ({
    ...(
      isMobile
        ? {
          ...styles.transactionTableContainer,
          maxHeight: 320
        }
        : styles.transactionTableContainer
    )
  }), [isMobile]);

  return (
    <div style={transactionTableContainer} className={classes.tableScrollBarStyle}>
      <table style={styles.transactionTable}>
        <thead>
          <tr>
            {Object.values(heading).map((col) => (
              <td key={col} style={tableHeader}>{col}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIdx) => (
            <tr key={`row-${rowIdx}`} style={isEven(rowIdx) ? styles.tableRow : styles.tableRowEven}>
              {Object.keys(heading).map((key) => (
                <td key={`cell-${rowIdx}-${key}`} style={tableData}>{row[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.object.isRequired
};