import React, {
  useMemo
} from 'react';

import {
  Header,
  NavBar
} from '../../components';

import {
  Outlet,
  useNavigate
} from 'react-router-dom';

import {
  useIsMobile,
  useIsSuperMobile
} from '../../recoil';

import {
  useRecoilValue
} from 'recoil';

import {
  Fonts
} from '../../constants';

const styles = {
  profileMgnt: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: Fonts.Roboto
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  containerLeft: {
    width: '100%',
    maxWidth: 343,
    marginTop: 38,
    height: 'inherit'
  },
  containerLeftMobile: {
    display: 'none'
  },
  containerRight: {
    width: '100%',
    height: 'inherit'
  },
  containerRightMobile: {
    width: '100%'
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: 18
  }
};

export const Profile = () => {

  const navigate = useNavigate();
  const isMobile = useRecoilValue(useIsMobile);
  const isSuperMobile = useRecoilValue(useIsSuperMobile);
  const containerRightStyle = useMemo(() => ({ ...styles.containerRight, ...(isMobile ? styles.containerRightMobile : {}) }), [isMobile]);
  const containerLeftStyle = useMemo(() => ({ ...styles.containerLeft, ...(isSuperMobile ? styles.containerLeftMobile : {}) }), [isSuperMobile]);

  return (
    <div style={styles.profileMgnt}>
      <Header onBackClick={() => navigate('/profile')}>
        <div style={styles.headerTitle}>
          {isSuperMobile && 'My profile'}
        </div>
      </Header>
      <div style={styles.contentSection}>
        <div style={containerLeftStyle}>
          <NavBar />
        </div>
        <div style={containerRightStyle}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};