import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  AccountsRoutes,
  StaticRoutes
} from '../../Router';
import { ArrowDown } from '../../assets';
import { Accordion } from '../../components';
import {
  AccordionStyle,
  Colors,
  Fonts,
  appStatusSubtext,
  appStatuses,
  appStatusesText,
  applicationProduct,
  applicationStatusColors
} from '../../constants';
import {
  applicationById,
  useIsMobile
} from '../../recoil';

const styles = {
  accordionRightDiv: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '95%'
  },
  subTitle: {
    marginTop: 22,
    width: '90%',
    fontFamily: Fonts.Roboto,
    fontSize: '1em',
    lineHeight: '140%',
    color: Colors.GreyScale13
  },
  bodyWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%'
  },
  applicationDetailsContainer: {
    marginTop: 22,
    display: 'flex',
    flexGrow: 0.35,
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  applicationDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10
  },
  applicationDetailsKey: {
    display: 'flex',
    color: Colors.GreyScale7,
    marginBottom: 10,
    fontSize: '1em',
    fontFamily: Fonts.Roboto,
    minWidth: 150
  },
  applicationDetailsValue: {
    fontSize: '1.125em',
    fontFamily: Fonts.Roboto,
    color: Colors.GreyScale13,
    marginRight: 10
  }
};

export const ApplicationAccordion = ({
  applicationId,
  isOpen,
  toggleOpen,
  onClick = () => { }
}) => {

  const isMobile = useRecoilValue(useIsMobile);
  const navigate = useNavigate();
  const applicationDetailsStyle = useMemo(() => isMobile ? { ...styles.applicationDetails, flexDirection: 'row' } : styles.applicationDetails, [isMobile]);
  const appDetail = useRecoilValue(applicationById(applicationId));
  const pendingStatusStyle = useMemo(() => ({ ...AccordionStyle.pendingStatus, background: applicationStatusColors[appDetail?.applicationStatus] || Colors.StatusYellow4 }), [appDetail]);

  const gotoRelatedStatusPage = () => {
    onClick();

    if (appDetail?.applicationStatus === appStatuses.QuoteAvailable) {
      navigate(StaticRoutes.fundingQuote);
    }

    if (appDetail?.applicationStatus === appStatuses.Disbursed) {
      if (appDetail?.applicationProduct === applicationProduct.BTA) {
        navigate(AccountsRoutes['bta']);
        return;
      }
      if (appDetail?.applicationProduct === applicationProduct.RCF) {
        navigate(AccountsRoutes['capital-facility']);
        return;
      }
    }

    if (appDetail?.applicationStatus === appStatuses.CreditFacilityCreated) {
      navigate(AccountsRoutes['capital-facility']);
    }
  };

  return (
    <>
      {appDetail && (
        <Accordion
          containerStyle={{ borderRadius: 16 }}
          isOpen={isOpen}
          headerChildren={
            <>
              <div style={{ ...AccordionStyle.accordionHeaderLeft, maxWidth: '100%' }}>
                <div style={AccordionStyle.accordionTitle}>
                  {appDetail.applicationProduct === applicationProduct.RCF ? 'Capital Facility' : 'Business Term Advance'}
                </div>
              </div>

              <div style={AccordionStyle.accordionHeaderRight}>
                <div style={styles.accordionRightDiv}>
                  {appDetail.externalApplicationId && <>
                    <div style={AccordionStyle.titleAppIdKey}>
                      App&nbsp;ID
                    </div>
                    <div style={AccordionStyle.titleAppIdValue}>
                      {appDetail.externalApplicationId}
                    </div>
                  </>}
                  <div style={pendingStatusStyle} onClick={gotoRelatedStatusPage}>
                    <div style={AccordionStyle.statusEllipse} />
                    <div style={AccordionStyle.statusTxt}>
                      {appStatusesText[appDetail.applicationStatus] || 'Pending'}
                    </div>
                  </div>
                </div>
                <img
                  style={{ ...AccordionStyle.ArrowImg, rotate: isOpen ? '180deg' : '' }}
                  src={ArrowDown}
                  alt='DropdownOpen'
                  role='presentation'
                  onClick={() => toggleOpen?.()}
                  onKeyDown={() => toggleOpen?.()}
                />
              </div>
            </>
          }
        >
          <div style={AccordionStyle.accordionChildren}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

              <div style={styles.subTitle}>
                {appStatusSubtext[appDetail?.applicationStatus] || 'Our team is currently reviewing our application and you should receive feedback withing 48 hours.'}
              </div>

              <div style={styles.bodyWrapper}>

                <div style={styles.applicationDetailsContainer}>
                  <div style={applicationDetailsStyle}>
                    <div style={styles.applicationDetailsKey}>
                      Date
                    </div>
                    <div style={styles.applicationDetailsValue}>
                      {dayjs(appDetail.applicationSubmitDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div style={applicationDetailsStyle}>
                    <div style={styles.applicationDetailsKey}>
                      Amount
                    </div>
                    <div style={styles.applicationDetailsValue}>
                      R {appDetail.applicationAmount}
                    </div>
                  </div>
                  <div style={applicationDetailsStyle}>
                    <div style={styles.applicationDetailsKey}>
                      Term (months)
                    </div>
                    <div style={styles.applicationDetailsValue}>
                      {appDetail.applicationTerm}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Accordion>
      )}
    </>
  );
};

ApplicationAccordion.propTypes = {
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  applicationId: PropTypes.string,
  onClick: PropTypes.func
};