import { axiosInstance, getErrorStatus } from './axiosInstance';
import { StatusCodes } from 'http-status-codes';

export const getCapitalFacility = async () => {
  let response;

  response = await axiosInstance.get('/capital-facility');

  // eslint-disable-next-line no-unreachable
  if (response.status !== StatusCodes.OK || !response.data) {
    throw new Error('GetCapitalFacilityDetailsError');
  }

  return response.data;
};

export const getTransactionDetails = async (id) => {
  let response;

  try {

    response = await axiosInstance.get(`/capital-facility/transactions/${id}`);

    // eslint-disable-next-line no-unreachable
    if (response.status !== StatusCodes.OK || !response.data) {
      throw new Error('GetCapitalFacilityTransactionHistoryError');
    }
  } catch (error) {
    const status = getErrorStatus(error);
    if (status === StatusCodes.NOT_FOUND) {
      return [];
    }
    throw error;
  }

  return response.data;
};

export const getTransactions = async () => {
  let response;

  try {

    response = await axiosInstance.get('/capital-facility/transactions');

    // eslint-disable-next-line no-unreachable
    if (response.status !== StatusCodes.OK || !response.data) {
      throw new Error('GetCapitalFacilityTransactionHistoryError');
    }
  } catch (error) {
    const status = getErrorStatus(error);
    if (status === StatusCodes.NOT_FOUND) {
      return [];
    }
    throw error;
  }

  return response.data;
};

export const getWithdrawalQuote = async (amount) => {
  let response;

  try {

    response = await axiosInstance.get(`/capital-facility/withdrawal-quote?amount=${amount}`);

    // eslint-disable-next-line no-unreachable
    if (response.status !== StatusCodes.OK || !response.data) {
      throw new Error('GetCapitalFacilityTransactionHistoryError');
    }
  } catch (error) {
    const status = getErrorStatus(error);
    if (status === StatusCodes.BAD_REQUEST) {
      return null;
    }

    throw error;
  }


  return response.data;
};

export const increaseCapitalFacilityLimit = async (id, amount) => {
  let response;
  response = await axiosInstance.post('/capital-facility/limit-increase', { id, amount });

  // eslint-disable-next-line no-unreachable
  if (response.status !== StatusCodes.ACCEPTED || !response.data) {
    throw new Error('IncreaseCapitalFacilityLimitError');
  }

  return response.data;
};

export const postCapitalFacilityWithdrawalQuote = async (amount) => {
  let response;
  response = await axiosInstance.post('capital-facility/withdrawal-quote', { amount });

  if (response.status !== StatusCodes.OK || !response.data) {
    throw new Error('IncreaseCapitalFacilityLimitError');
  }

  return response.data;
};
