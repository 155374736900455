import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import redLoader from '../../assets/loader-red.json';
import { Colors } from '../../constants';

const styles = {
  spinnerBackground: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 100000,
    backgroundColor: Colors.GreyScale1
  },
  spinner: {
    overflow: 'hidden',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center'
  }
};

export const Loader = (props) => {
  const element = useRef(null);
  const lottieInstance = useRef();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const styleNew = { ...styles.spinner };

    if (props.height) {
      styleNew.height = props.height;
    }

    const params = {
      loop: true,
      autoplay: true,
      animationData: redLoader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      },
      ...styleNew
    };

    setStyle(styleNew);

    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        ...params,
        container: element.current
      });
    }
  }, []);

  return (
    <div style={styles.spinnerBackground}>
      <div style={style} ref={element} />
    </div>
  );
};

Loader.propTypes = {
  height: PropTypes.string
};

Loader.defaultProps = {
  height: '20%'
};