import {
  FormikInput,
  theme
} from '@vfs-digital-channels/ns-react-components';
import { Field, Form, Formik } from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { ErrorModalSection, FormSubmitHelper, SupportPopup } from '../../../components';
import { DateSelector } from '../../../components/DateSelector/DateSelector';
import {
  FormContainer,
  FormField,
  FormFieldError,
  FormFieldWrapper,
  landlineRegex,
  submissionStates,
  websiteRegex
} from '../../../constants';
import { mapValues, replaceUndefined, stripEmpty } from '../../../helpers';
import {
  businessDetailsSubmit,
  useIsMobile
} from '../../../recoil';

import { useModal } from '../../../components/modalController/useModal';
import { findFirstValidationError } from '../../../helpers/formik';
import { isValidDate, isValidRevenue } from '../../../helpers/strings';
import { useTealium } from '../../../tealium/useTealium';
import styles from './BusinessFinancialDetails.module.css';

export const businessFinancialValidationSchema = yup.object({
  revenueBracket: yup
    .number('Annual Revenue only accepts numbers')
    .integer('Annual Revenue only accepts numbers')
    .required('Annual Revenue is a required field')
    .positive('Annual Revenue only accepts positive numbers'),
  timeInBusiness: yup
    .date()
    .required('Time in business is a required field')
    .max(new Date(), 'Date should be in past'),
  landline: yup
    .string()
    .required('Landline is a required field')
    .matches(landlineRegex, 'Landline must be a valid landline number with no spaces or special characters'),
  website: yup
    .string()
    .matches(websiteRegex, 'Website must be a website URL')
});

export const BusinessFinancialDetails = () => {

  const [openModal, closeModal] = useModal();
  const [details, setDetails] = useRecoilState(businessDetailsSubmit);
  const { submitState, setSubmitState } = useOutletContext();

  const formRef = React.useRef();
  const isMobile = useRecoilValue(useIsMobile);
  const { track, events } = useTealium();
  const location = useLocation();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.businessDetails2.formStart());
  };

  useEffect(() => {
    track(events.businessDetails2.pageView());
  }, []);

  const formDetails = useMemo(() => replaceUndefined({
    ...details,
    timeInBusiness: isValidDate(details.timeInBusiness) ? details.timeInBusiness : '',
    revenueBracket: isValidRevenue(details.revenueBracket) ? details.revenueBracket : ''
  }), [details]);

  const submitHandler = async (formData) => {
    track(events.businessDetails2.formEnd());
    setDetails(mapValues(stripEmpty({...formData, isBusinessVerified: false})));
  };

  const closeErrorModal = () => {
    submitHandler(formDetails);
    setSubmitState(submissionStates.default);
    closeModal();
  };

  useEffect(() => {
    if (details.error) {

      openModal({
        title: 'Error',
        children: <ErrorModalSection
          traceId={details?.traceId}
          error={details?.error}
          onCloseErrorModal={closeErrorModal}
        />,
        type: 'error',
        onCloseModal: closeErrorModal
      });
      return;
    }
    if (submitState === submissionStates.submitting && !details.error) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };

  return (
    <div className={styles.container}>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={submitHandler}
          validationSchema={businessFinancialValidationSchema}
          validateOnMount={true}
          innerRef={formRef}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);
            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>

                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <div className={isMobile ? styles.mobileTitleContainer : styles.titleContainer}>
                  <div className={isMobile ? styles.mobileTitleHeading : styles.titleHeading}>
                    Tell us about your business
                  </div>
                  <div className={styles.titleSubHeading}>
                    Please provide the below details about your business
                  </div>
                </div>

                <div style={FormFieldWrapper}>
                  <div style={
                    errors?.timeInBusiness && touched?.timeInBusiness
                      ? FormFieldError
                      : { ...FormField, ...(isMobile ? {} : { marginBottom: '10px' }) }
                  }>
                    <Field
                      label='Time in business (in years)'
                      name='timeInBusiness'
                      showOnlyMonthYear = {true}
                      component={DateSelector}
                    />
                  </div>
                  <div style={FormField}>
                    <FormikInput label='Annual revenue (in Rands)' name='revenueBracket' />
                  </div>

                  <div style={FormField}>

                    <FormikInput
                      label='Landline number'
                      name='landline' />
                  </div>

                  <div style={FormField}>

                    <FormikInput
                      label='Website (Optional)'
                      name='website' />
                  </div>
                </div>

              </Form>
            )
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
