export const ValidationErrorMessageMap = {
  'InvalidDateOfBirthField': 'Invalid DOB',
  'InvalidWhenMovedInField': 'Invalid When did you move to this address? field',
  'SAIDNUMBERALREADYEXISTS': 'SA ID number already exists',
  'PASSPORTNUMBERALREADYEXISTS': 'Passport number already exists',
  'InvalidFirstName': 'Invalid Name',
  'InvalidSurname': 'Invalid Surname',
  'InvalidMsisdn': 'Mobile number is not a valid South African mobile number',
  'InvalidEmail': 'Email is not a valid email address',
  'CompanyRegistrationNumberAlreadyExists': 'Company registration number already exists',
  'VatNumberAlreadyExists': 'Vat number already exists',
  'MsisdnNotFound': 'Mobile number not registered. Please proceed to <a href="/registration">registration</a>.',
  'EmailNotFound': 'Email address not registered. Please proceed to <a href="/registration">registration</a>.',
  'MsisdnExists': 'User already exists with the same email and/or MSISDN. Please <a href="/login">login</a>.',
  'EmailExists': 'User already exists with the same email and/or MSISDN. Please <a href="/login">login</a>.',
  'The request was sent but no response was received': 'Oops! Something\'s gone wrong. Please try again',
  'ForbiddenContact': 'User not allowed to send or receive OTPs',
  'InvalidLoginRef': 'InvalidLoginRef',
  'ExistingSessionFound': 'There is currently an active ongoing session. Please close the current session, before attempting to log in on another device/browser'
};
