import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Documents } from '../../assets';
import { Button } from '@vfs-digital-channels/ns-react-components';
import {
  Colors,
  CommonDetails,
  Fonts
} from '../../constants';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';
import { StatusCodes } from 'http-status-codes';
import { ValidationErrorMessageMap } from '../../constants/validationErrorMessageMap';
import { getErrorStatus } from '../../api/vodaLend/axiosInstance';

const styles = {
  status: {
    color: Colors.GreyScale10,
    fontFamily: Fonts.Roboto,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '140%'
  },
  supportArea: {
    color: Colors.GreyScale10,
    fontFamily: Fonts.Roboto,
    fontSize: 18,
    lineHeight: '140%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20
  },
  supportEmail: {
    color: Colors.Primary4,
    wordBreak: 'break-all',
    width: '100%'
  },
  traceIdSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 25
  },
  traceIdText: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5
  },
  traceIdHeading: {
    fontSize: 'inherit',
    fontFamily: Fonts.Roboto,
    fontWeight: 700,
    lineHeight: '140%'
  },
  traceId: {
    fontSize: 'inherit',
    fontFamily: Fonts.Roboto,
    wordBreak: 'break-all'
  },
  copyBtn: {
    borderRadius: '50%',
    border: `1px solid ${Colors.GreyScale13}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    cursor: 'pointer'
  },
  docImg: {
    width: 25,
    height: 25
  },
  internalFault: {
    fontSize: 18,
    fontFamily: Fonts.Roboto,
    lineHeight: '140%',
    marginTop: 25,
    textAlign: 'center'
  },
  divider: {
    width: 20,
    height: 3,
    background: Colors.Primary4,
    marginTop: 30
  },
  closeBtn: {
    marginTop: 30,
    fontSize: 16,
    width: '60%',
    textAlign: 'center'
  },
  infoParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column'
  }
};

export const ErrorModalSection = ({
  traceId,
  onCloseErrorModal,
  error
}) => {

  const isMobile = useRecoilValue(useIsMobile);

  const isInternalError = useMemo(() => {
    if (error?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      return true;
    }
    return false;
  }, [error]);

  const status = useMemo(() => getErrorStatus(error), [error]);

  const errorMessage = useMemo(() => {

    const msg = ValidationErrorMessageMap[error?.message] || error?.message || '';

    if (msg) {
      return msg;
    }

    if (isInternalError) {
      return 'An internal fault occurred';
    }

    return 'Oops! Somethings gone wrong. Please try again';

  }, [error, isInternalError]);

  const copyText = () => navigator.clipboard.writeText(traceId);

  const closePopup = () => {
    if (typeof onCloseErrorModal === 'function') {
      onCloseErrorModal();
    }
  };

  const traceIdSectionStyle = useMemo(() => ({
    ...(
      isMobile ? {
        ...styles.traceIdSection,
        fontSize: 14
      }
        : styles.traceIdSection
    )
  }), [isMobile]);

  const onClose = () => {

    if (!isInternalError) {
      closePopup();
      return;
    }
    window.location.reload();
  };

  return (
    <div style={styles.infoParent}>

      <div style={styles.status}>
        Status: {status}
      </div>

      <div style={styles.supportArea}>
        An error occurred. Please contact <div style={styles.supportEmail}> {CommonDetails.contactUsEmail} </div> |
        {CommonDetails.contactUsMsisdn}
      </div>

      {traceId && (
        <div style={traceIdSectionStyle}>
          <div style={styles.traceIdText}>
            <div style={styles.traceIdHeading}>
              Trace id:&nbsp;
            </div>
            <div style={styles.traceId}>
              {traceId}
            </div>
          </div>
          <div
            style={styles.copyBtn}
            onClick={copyText}
            onKeyDown={copyText}
            role='button'
            tabIndex={0}
          >
            <img src={Documents} alt="copy" style={styles.docImg} />
          </div>
        </div>
      )}

      <div style={styles.internalFault}>
        {errorMessage}
      </div>

      <div style={styles.divider} />

      <Button
        style={styles.closeBtn}
        onClick={onClose}
      >
        {isInternalError ? 'Close' : 'Try again'}
      </Button>

    </div>
  );
};

ErrorModalSection.propTypes = {
  traceId: PropTypes.string,
  error: PropTypes.object.isRequired,
  onCloseErrorModal: PropTypes.func
};