import { Button } from '@vfs-digital-channels/ns-react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StaticRoutes } from '../../Router';
import { Header } from '../../components';
import { Colors, Fonts } from '../../constants';

const styles = {
  main: {
    textAlign: 'center'
  },
  header: {
    backgroundColor: Colors.GreyScale1,
    height: '100%',
    display: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.GreyScale13
  },
  h1: {
    fontFamily: Fonts.RobotoBold
  },
  h2: {
    fontFamily: Fonts.Roboto
  },
  btn_userInfo: {
    fontSize: '14px',
    fontFamily: Fonts.Roboto,
    cursor: 'pointer'
  }
};

export const Home = () => {

  const navigate = useNavigate();

  const gotoApplyNow = () => {
    navigate(StaticRoutes.loanApplication);
  };

  const gotoProfileEdit = () => {
    navigate(StaticRoutes.profile);
  };

  const gotoApplications = () => {
    navigate(StaticRoutes.applications);
  };

  return (
    <>
      <Header />
      <div style={styles.main}>
        <header style={styles.header}>
          <h1 style={styles.h1}>{'Originations Platform Portal'}</h1>
          <h2 style={styles.h2}>{'Welcome to Lending home'}</h2>
          <Button style={{ ...styles.btn_userInfo, marginLeft: '10px' }} onClick={gotoApplyNow} as={'button'}>{'Apply Now'}</Button>
          <Button style={{ ...styles.btn_userInfo, marginLeft: '10px' }} onClick={gotoProfileEdit} as={'button'}>{'Profile Edit'}</Button>
          <Button style={{ ...styles.btn_userInfo, marginLeft: '10px' }} onClick={gotoApplications} as={'button'}>{'Applications'}</Button>
        </header>
      </div>
    </>
  );
};
