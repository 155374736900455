import {
  DefaultValue,
  atom,
  selector
} from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import * as vodaLend from '../api/vodaLend';
import { authDetailState, userDetails } from './authDetails';

export const requestRegisterOtpState = atom({
  key: 'requestRegisterOtpState',
  default: null
});

const requestRegisterOtp = async (input) => {
  const response = await vodaLend.requestRegisterOtp(input);
  return response?.data || {};
};

const requestRegisterOtpResend = async (storedUserDetails) => {
  const response = await vodaLend.requestRegisterOtpResend(storedUserDetails);
  return {
    ...response?.data || {},
    isResend: true
  };
};

export const requestOtpRegister = selector({
  key: 'requestOtp',
  set: ({ get, set, reset }, userDetailsInput) => {
    
    if (userDetailsInput instanceof DefaultValue) {
      reset(authDetailState);
      return;
    }

    const storedUserDetails = get(userDetails);
    if(typeof (userDetailsInput?.marketingConsent) === 'undefined'){
      userDetailsInput.marketingConsent = true
    }
    const isRequiredDetailsPresent = userDetailsInput?.email && userDetailsInput?.msisdn && userDetailsInput?.firstName && userDetailsInput?.lastName && (typeof (userDetailsInput?.marketingConsent) === 'boolean');
    if (!isRequiredDetailsPresent && !storedUserDetails.ref) {
      set(requestRegisterOtpState, null);
      return;
    }

    set(verifyRegisterOtpState, null);


    if (isRequiredDetailsPresent) {
      const input = {
        ...userDetailsInput,
        ref: uuidv4()
      };
      set(userDetails, input);
      set(requestRegisterOtpState, requestRegisterOtp(input));
    } else {
      set(requestRegisterOtpState, requestRegisterOtpResend(storedUserDetails));
    }

  },
  get: async ({ get }) => {
    const requestOtpPromise = get(requestRegisterOtpState);
    if (!requestOtpPromise) {
      return null;
    }
    const response = await requestOtpPromise;
    return response || {};
  }
});

export const otpRegisterContact = selector({
  key: 'otpRegisterContact',
  get: ({ get }) => {
    return get(requestOtpRegister)?.maskedContact;
  }
});

export const verifyRegisterOtpState = atom({
  key: 'verifyRegisterOtpState',
  default: null
});

export const verifyRegisterOtp = selector({
  key: 'verifyRegisterOtp',
  set: ({ get, set }, code) => {

    if (!code) {
      set(verifyRegisterOtpState, null);
      return;
    }

    const details = get(userDetails);

    if (!details?.ref) {
      throw new Error('NoLoginRef');
    }
    set(verifyRegisterOtpState, vodaLend.submitRegisterOtpResponse(details.ref, code));
  },
  get: async ({ get }) => {
    const verifyOtpPromise = get(verifyRegisterOtpState);
    if (!verifyOtpPromise) {
      return null;
    }
    return await verifyOtpPromise;
  }
});
