import * as yup from 'yup';
import { postalCodeRegex } from '../../constants';

export const addressValidationSchema = yup.object({
  street: yup
    .string()
    .required('Street is a required field')
    .trim()
    .min(1, 'Street must be a valid Street consisting of at least 1 characters'),
  building: yup.string(),
  suburb: yup
    .string()
    .required('Suburb is a required field')
    .trim()
    .min(1, 'Suburb must be a valid 1 consisting of at least 1 characters'),
  city: yup
    .string()
    .required('City/Town is a required field')
    .trim()
    .min(1, 'City/Town must be a valid City/Town consisting of at least 1 characters'),
  province: yup
    .mixed()
    .test('address.province', 'Province is a required field', (item) => !!item?.value),
  postalCode: yup
    .string()
    .required('Postal Code is a required field')
    .matches(postalCodeRegex, 'Postal Code must be a valid numerical value between 0001 to 9999')
});
