export const login = {
  pageView: () => ({
    event_name: 'page_view',
    page_name: 'vodapay: business advance: opf: login',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  notRegistered: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: login',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: not registered'
  }),

  termsAndConditions: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: login',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: terms & conditions'
  }),

  continue: () => ({
    event_name: 'ui_interaction',
    page_name: 'vodapay: business advance: opf: login',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: continue'
  }),

  loginComplete: () => ({
    event_name: 'visitor_login_complete',
    page_name: 'vodapay: business advance: opf: login',
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  })
};
