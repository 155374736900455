import React, {
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  useFormikContext
} from 'formik';
import {
  FormField,
  FormFieldWrapper,
  Position,
  Titles,
  Gender,
  FormFieldError,
  Colors
} from '../../constants';
import { DateSelector } from '../DateSelector/DateSelector';
import { Identity } from '../Identity/Identity';
import {
  FormikInput,
  FormikSelect
} from '@vfs-digital-channels/ns-react-components';
import { useIsMobile } from '../../recoil';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { InfoIconBlueBackground } from '../../assets';


export const PersonalDetailsComponent = ({
  namePrefix,
  showDob,
  showGender,
  showIdentity,
  phoneEmailEditable = true,
  disableAllFields = false
}) => {

  const { errors, touched } = useFormikContext();
  const pref = useMemo(() => namePrefix ? `${namePrefix}.` : '', [namePrefix]);

  const isDirectorPage = useMemo(() => namePrefix?.startsWith?.('directors'), [namePrefix]);

  const emailPhoneStyle = useMemo(() => phoneEmailEditable ? FormField : { ...FormField, pointerEvents: 'none' }, [phoneEmailEditable]);
  const formFieldWrapperStyle = useMemo(() => disableAllFields ? { ...FormFieldWrapper, pointerEvents: 'none' } : FormFieldWrapper, [phoneEmailEditable]);

  const isMobile = useRecoilValue(useIsMobile);

  const SubMessage = styled.div`
  font-size: 0.80rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.80rem;
  display: flex;
  column-gap: 0.25rem;
  color: ${Colors.GreyScale13};
  width: 95%;
`;

  return (
    <div style={formFieldWrapperStyle}>

      {showIdentity ?
        <Identity namePrefix={namePrefix} />
        : null
      }

      <div style={FormField}>
        <FormikSelect
          label='Position in the company'
          name={`${pref}positionInCompany`}
          options={Position}
          scrollToTop={false}
        />
      </div>
      <div style={FormField}>
        <FormikSelect
          label='Title'
          name={`${pref}title`}
          options={Titles}
          scrollToTop={false}
        />
      </div>
      <div style={FormField}>
        <FormikInput label='Name' name={`${pref}firstName`} />
      </div>
      <div style={FormField}>
        <FormikInput label='Surname' name={`${pref}surname`} />
      </div>
      <div style={emailPhoneStyle}>
        <FormikInput label='Email address' name={`${pref}email`} disabled={!isDirectorPage} />
      </div>
      {!isDirectorPage && (
        <div style={FormField}>
          <SubMessage>
            <img
              src={InfoIconBlueBackground}
              alt="info"
              style={{ height: 13, width: 13 }}
            />
            {'Please contact us to update your email addresss'}
          </SubMessage>
        </div>
      )}
      <div style={emailPhoneStyle}>
        <FormikInput label='Mobile number' name={`${pref}msisdn`} disabled={!isDirectorPage} />
      </div>
      {!isDirectorPage && (
        <div style={FormField}>
          <SubMessage>
            <img
              src={InfoIconBlueBackground}
              alt="info"
              style={{ height: 13, width: 13 }}
            />
            {'Please contact us to update your mobile number'}
          </SubMessage>
        </div>
      )}
      {showDob ?
        <>
          <div style={errors?.dateOfBirth && touched?.dateOfBirth ? FormFieldError : { ...FormField, ...(isMobile ? {} : { marginBottom: '10px' }) }}>
            <Field
              name={`${pref}dateOfBirth`}
              component={DateSelector}
              label='Date of birth'
            />
          </div>
        </> : null}
      {showGender ?
        <>
          <div style={FormField}>
            <FormikSelect
              label='Gender'
              name={`${pref}gender`}
              options={Gender}
              scrollToTop={false}
            />
          </div>
        </> : null}
    </div>
  );
};

PersonalDetailsComponent.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  showDob: PropTypes.bool,
  showGender: PropTypes.bool,
  showIdentity: PropTypes.bool,
  phoneEmailEditable: PropTypes.bool,
  disableAllFields: PropTypes.bool
};