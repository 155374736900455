import React, {useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { submissionStates } from '../../constants';

export const FormSubmitHelper = ({submitState, setSubmitState}) => {

  const { submitForm, isValid } = useFormikContext();

  const handler = async () => {

    try {
      await submitForm();
      setSubmitState(submissionStates.submitting);
    } catch (err) {
      console.error('Error submitting form', err);
      setSubmitState(submissionStates.submitError);
    }
  };

  useEffect(() => {
    setSubmitState(submissionStates.default);
  }, []);

  useEffect(() => {

    if (isValid && submitState === submissionStates.default) {
      setSubmitState(submissionStates.canSubmit);
    } else if (!isValid && submitState === submissionStates.canSubmit) {
      setSubmitState(submissionStates.default);
    } else if (isValid && submitState === submissionStates.startSubmit) {
      handler();
    }
  }, [isValid, submitState]);

  return (<> </>);
};

FormSubmitHelper.propTypes = {
  submitState: PropTypes.number.isRequired,
  setSubmitState: PropTypes.func.isRequired
};
