import { journeyName, pageName } from '../constants';

export const securityQuestions = {
  journeyStart: (urlPath) => ({
    event_name: 'page_view, journey_start',
    journey_name: `business advance: opf: ${journeyName[urlPath] || 'untracked'}`,
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}: security questions`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website'
  }),

  formStart: (urlPath) => ({
    event_name: 'form_start',
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}: security questions`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    form_name: 'business advance: opf: security questions'
  }),

  continue: (urlPath) => ({
    event_name: 'ui_interaction',
    page_name: `vodapay: business advance: opf: ${pageName[urlPath] || 'untracked'}: security questions`,
    page_url: window.location.href,
    page_section: 'business advance: opf',
    page_channel: 'vodapay: business advance: opf',
    page_subdomain: 'business advance: opf',
    page_type: 'website',
    link_id: 'vodapay: business advance: opf: continue'
  })
};
