import {
  FormikInput,
  FormikSelect
} from '@vfs-digital-channels/ns-react-components';
import React from 'react';
import {
  FormField,
  Provinces
} from '../../constants';

export const AddressDetailsComponent = () => {

  return (
    <>
      <div style={FormField}>

        <FormikInput
          label='Street address'
          name='address.street'
          subtext='Eg. 12 Ridge Street'
        />
      </div>
      <div style={FormField}>

        <FormikInput
          label='Complex/Building (Optional)'
          name='address.building'
          subtext='Complex, building or mall name, unit or shop number'
        />
      </div>
      <div style={FormField}>

        <FormikInput
          label='Suburb'
          name='address.suburb'
        />
      </div>
      <div style={FormField}>

        <FormikInput
          label='City/Town'
          name='address.city'
        />
      </div>
      <div style={FormField}>

        <FormikSelect
          label='Province'
          name='address.province'
          options={Provinces}
          scrollToTop={false}
        />
      </div>
      <div style={FormField}>

        <FormikInput
          label='Postal code'
          name='address.postalCode'
          subtext='Eg. 1234'
        />
      </div>
    </>
  );
};
