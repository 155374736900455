import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo, useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState
} from 'recoil';
import { ThemeProvider } from 'styled-components';
import { StaticRoutes } from '../../../Router';
import {
  BankDetailsComponent,
  Footer,
  bankDetailsValidationSchema
} from '../../../components';
import {
  AccountType,
  BankNames,
  Colors,
  Fonts
} from '../../../constants';
import { mapValues, stripEmpty } from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import {
  financialDetails,
  financialDetailsState,
  toasts,
  useIsMobile,
  useShowToast
} from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';

const styles = {
  financialDetail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    marginBottom: 60
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: Fonts.RobotoMedium,
    fontStyle: 'normal',
    fontSize: '1.500em',
    lineHeight: '30px',
    color: Colors.GreyScale12,
    margin: '40px 0 20px 0',
    width: 'inherit'
  }
};

export const ProfileBankDetails = () => {

  const [details, setFinancialDetails] = useRecoilState(financialDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();
  const showToast = useShowToast();
  const isMobile = useRecoilValue(useIsMobile);
  const resetToast = useResetRecoilState(toasts);
  const reset = useResetRecoilState(financialDetailsState);
  useFindErrorOnState([financialDetails], () => {
    reset();
  });
  const { track, events } = useTealium();

  const formDetails = useMemo(() => ({
    ...details,
    bankName: BankNames.find((b) => b.value === details.bankName) || '',
    bankAccountType: AccountType.find((a) => a.value === details.bankAccountType) || ''
  }), [details]);

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = async (formValues) => {
    const submission = formatFormData(formValues);
    await setFinancialDetails(submission);
    showToast('Updated successfully');
  };

  const goToMainPage = () => {
    navigate(StaticRoutes.profile);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (details.error) {

      resetToast();
      return;
    }
  }, [details]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };
  return (
    <div style={styles.financialDetail}>
      <div style={styles.pageHeading}>
        Banking details
      </div>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          validationSchema={bankDetailsValidationSchema}
          validateOnMount={true}
          onSubmit={formSubmit}
          innerRef={formRef}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);

            return (
              <Form style={styles.financialDetail}>
                <BankDetailsComponent />
                <Footer
                  leftCallback={goToMainPage}
                  rightCallback={Object.values(errors).length ? null : handleSubmit}
                  leftTitle={isMobile ? '' : 'Cancel'}
                  rightTitle={'Save'}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
    </div>
  );
};