import React from 'react';

export const FaqRcf = [
  {
    question: 'What is a Revolving Capital Facility?',
    answer: 'You can tap into this pre-approved line of capital whenever you need business funding, without having to reapply. And because we don’t charge any hidden monthly admin or initiation fees, you’ll only ever pay for the funds you use.'
  },
  {
    question: 'How do I apply for a Revolving Capital Facility?',
    answer: 'Our online application process takes only minutes and is paperless. Simply supply us with your basic personal and business details. We’ll also ask for 3 months’ recent transactional data, which can be provided quickly and securely by giving your business bank account permission to send us read-only data.'
  },
  {
    question: 'How does a Lulalend Revolving Capital Facility work?',
    answer: 'It’s pretty easy! Our Revolving Capital Facility lets SMEs access capital up to an agreed limit whenever they want, without having to reapply each time. We’ll agree to make a line of funding available to you, and you repay the funding over an agreed term. When you make a repayment, the capital amount becomes available to withdraw again.'
  },
  {
    question: 'What is the benefit of a Revolving Capital Facility?',
    answer: <>It offers easy access to working capital whenever you need it, allowing you to take advantage of growth opportunities or cover any cash flow gaps.
      <ul>
        <li>No need to reapply when you want to withdraw.</li>
        <li>No hidden monthly account or admin fees.</li>
        <li>Only pay for the funds you use.</li>
        <li>Flexible repayment terms, including the option to repay early with no early repayment penalties.</li>
      </ul>
    </>
  },
  {
    question: 'Where can I find funding for my business?',
    answer: 'Right here! No need to look anywhere else, we’re ready to help you on your funding journey, fast. Let’s make cash flow!'
  }
];
