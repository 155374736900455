import {
  atom,
  selector
} from 'recoil';
import * as vodaLend from '../api/vodaLend';

const fetchState = async () => {
  const response = await vodaLend.getPersonalDetails();
  return response.data;
};

const updateState = async (inputData) => {
  const response = await vodaLend.setPersonalDetails(inputData);
  return response.data;
};

const personalDetailsDefault = selector({
  key: 'personalDetailsDefault',
  get: async () => await fetchState()
});

export const personalDetailsState = atom({
  key: 'personalDetailsState',
  default: personalDetailsDefault
});

export const personalDetailsSubmit = selector({
  key: 'personalDetailsSubmit',
  get: async ({ get }) => await get(personalDetailsState),
  set: ({ get, set, reset }, inputData) => {

    if (!inputData) {
      reset(personalDetailsState);
      return;
    }

    const cur = get(personalDetailsState) || {};

    if (inputData.error) {
      const data = Object.fromEntries(
        Object
          .entries({ ...cur, ...inputData })
          .filter(([k, _]) => k !== 'error' && k !== 'traceId')
      );
      set(personalDetailsState, data);
      return;
    }

    set(personalDetailsState, updateState({
      ...cur,
      ...inputData
    }));
  }
});
