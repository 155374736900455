import React from 'react';
import styles from './chip.module.css';
import PropTypes from 'prop-types';

export function Chip({ color, children }) {
  return (
    <div className={`${styles.chip} ${styles[color]}`}>
      { children }
    </div>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'purple',
    'orange',
    'blue',
    'green',
    'black',
    'yellow',
    'red'
  ])
};
