import React from 'react';
import PropTypes from 'prop-types';

export const ShowIf = ({ condition, children }) => {
  return (
    <>{condition && children}</>
  );
};

ShowIf.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  condition: PropTypes.bool
};