import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Modal } from '../../components/modal/modal';
import { StaticRoutes } from '../../Router';
import { Button } from '@vfs-digital-channels/ns-react-components';

export const Error = () => {
  const { error } = useRouteError();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Modal title={'Error'}
      onClose={navigateBack}
      type={'error'}
      actions={[
        <Button key={'back'} onClick={navigateBack}>Back</Button>
      ]}
    >
      <p>Oops! Something has gone wrong.</p>
      <p>{ error?.statusText || error?.message }</p>
    </Modal>
  );
};
