import React from 'react';
import PropTypes from 'prop-types';
import styles from './section.module.css';

export function Section({ children }) {
  return (
    <section className={styles.section}>
      { children }
    </section>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
};